import { incidentType } from "../constants/incidentType";

export const getIncidentData = () => ({
  type: incidentType.GET_INCIDENT,
});

export const getIncidentDataSuccess = (data) => ({
  type: incidentType.GET_INCIDENT_SUCCESS,
  data,
});

export const getIncidentDataFailure = (error) => ({
  type: incidentType.GET_INCIDENT_FAILURE,
  error,
});

// delete incidents

export const deleteIncidents = (payload) => ({
  type: incidentType.DELETE_INCIDENT,
  payload: payload,
});

export const deleteIncidentsSuccess = (payload) => ({
  type: incidentType.DELETE_INCIDENT_SUCCESS,
  payload: payload,
});

export const deleteIncidentsFailure = (payload) => ({
  type: incidentType.DELETE_INCIDENT_FAILED,
  payload: payload,
});

export const getIncidentList = (data) => ({
  type: incidentType.GET_INCIDENT_LIST,
  data,
});

export const getIncidentListSuccess = (data) => ({
  type: incidentType.GET_INCIDENT_LIST_SUCCESS,
  data,
});

export const getIncidentListEmailSuccess = () => ({
  type: incidentType.GET_INCIDENT_LIST_EMAIL_SUCCESS,
});

export const getIncidentListFailure = (error) => ({
  type: incidentType.GET_INCIDENT_LIST_FAILURE,
  error,
});

export const getActionList = (data) => ({
  type: incidentType.GET_ACTION_LIST,
  data,
});

export const getActionListSuccess = (data) => ({
  type: incidentType.GET_ACTION_LIST_SUCCESS,
  data,
});

export const getActionListFailure = (error) => ({
  type: incidentType.GET_ACTION_LIST_FAILURE,
  error,
});

export const closeActionItem = (data) => ({
  type: incidentType.CLOSE_ACTION_ITEM,
  data,
});

export const closeActionItemSuccess = (data) => ({
  type: incidentType.CLOSE_ACTION_ITEM_SUCCESS,
  data,
});

export const closeActionItemFailure = (error) => ({
  type: incidentType.CLOSE_ACTION_ITEM_FAILURE,
  error,
});

export const getCasesList = (data) => ({
  type: incidentType.GET_CASES_LIST,
  data,
});

export const getCasesListSuccess = (data) => ({
  type: incidentType.GET_CASES_LIST_SUCCESS,
  data,
});

export const getCasesListEmailSuccess = () => ({
  type: incidentType.GET_CASES_LIST_EMAIL_SUCCESS,
});

export const getCasesListFailure = (error) => ({
  type: incidentType.GET_CASES_LIST_FAILURE,
  error,
});

export const getCaseDetails = (caseId) => ({
  type: incidentType.GET_CASE_DETAILS_PENDING,
  caseId,
});

export const getCaseDetailsSuccess = (data) => ({
  type: incidentType.GET_CASE_DETAILS_SUCCESS,
  data,
});

export const getCaseDetailsFailure = (error) => ({
  type: incidentType.GET_CASE_DETAILS_FAILED,
  error,
});

export const closeIncident = (data) => ({
  type: incidentType.CLOSE_INCIDENT,
  data,
});

export const closeIncidentSuccess = (data) => ({
  type: incidentType.CLOSE_INCIDENT_SUCCESS,
  data,
});

export const closeIncidentFailure = (error) => ({
  type: incidentType.CLOSE_INCIDENT_FAILURE,
  error,
});

export const deleteCaseList = (payload) => ({
  type: incidentType.DELETE_CASE,
  payload: payload,
});

export const deleteCaseSuccess = (payload) => ({
  type: incidentType.DELETE_CASE_SUCCESS,
  payload: payload,
});

export const deleteCaseFailure = (payload) => ({
  type: incidentType.DELETE_CASE_FAILED,
  payload: payload,
});
// incident reports
export const getIncidentReport = (data) => ({
  type: incidentType.GET_INCIDENT_REPORT,
  data,
});

export const getIncidentReportSuccess = (data) => ({
  type: incidentType.GET_INCIDENT_REPORT_SUCCESS,
  data,
});

export const getIncidentReportFailure = (error) => ({
  type: incidentType.GET_INCIDENT_REPORT_FAILURE,
  error,
});
// Injured Body Part
export const getIncidentReportInjuredBodyPart = (data) => ({
  type: incidentType.GET_INCIDENT_REPORT_INJURED_BODYPART,
  data,
});

export const getIncidentReportInjuredBodyPartSuccess = (data) => ({
  type: incidentType.GET_INCIDENT_REPORT_INJURED_BODYPART_SUCCESS,
  data,
});

export const getIncidentReportInjuredBodyPartFailure = (error) => ({
  type: incidentType.GET_INCIDENT_REPORT_INJURED_BODYPART_FAILURE,
  error,
});
// Injured Body Part List
export const getIncidentReportInjuredBodyPartList = (data) => ({
  type: incidentType.GET_INCIDENT_REPORT_INJURED_BODYPART_LIST,
  data,
});

export const getIncidentReportInjuredBodyPartListSuccess = (data) => ({
  type: incidentType.GET_INCIDENT_REPORT_INJURED_BODYPART_LIST_SUCCESS,
  data,
});

export const getIncidentReportInjuredBodyPartListFailure = (error) => ({
  type: incidentType.GET_INCIDENT_REPORT_INJURED_BODYPART_LIST_FAILURE,
  error,
});
// Most Common Injury
export const getIncidentReportMostCommonInjury = (data) => ({
  type: incidentType.GET_INCIDENT_REPORT_MOST_COMMON_INJURY,
  data,
});

export const getIncidentReportMostCommonInjurySuccess = (data) => ({
  type: incidentType.GET_INCIDENT_REPORT_MOST_COMMON_INJURY_SUCCESS,
  data,
});

export const getIncidentReportMostCommonInjuryFailure = (error) => ({
  type: incidentType.GET_INCIDENT_REPORT_MOST_COMMON_INJURY_FAILURE,
  error,
});
// Most Common Injury List
export const getIncidentReportMostCommonInjuryList = (data) => ({
  type: incidentType.GET_INCIDENT_REPORT_MOST_COMMON_INJURY_LIST,
  data,
});

export const getIncidentReportMostCommonInjuryListSuccess = (data) => ({
  type: incidentType.GET_INCIDENT_REPORT_MOST_COMMON_INJURY_LIST_SUCCESS,
  data,
});

export const getIncidentReportMostCommonInjuryListFailure = (error) => ({
  type: incidentType.GET_INCIDENT_REPORT_MOST_COMMON_INJURY_LIST_FAILURE,
  error,
});

// Most Common Day of injury
export const getIncMostCommonDayOfInjury = (data) => ({
  type: incidentType.GET_INCIDENT_REPORT_MOST_COMMON_DAY_OF_INJURY,
  data,
});

export const getIncMostCommonDayOfInjurySuccess = (data) => ({
  type: incidentType.GET_INCIDENT_REPORT_MOST_COMMON_DAY_OF_INJURY_SUCCESS,
  data,
});

export const getIncMostCommonDayOfInjuryFailure = (error) => ({
  type: incidentType.GET_INCIDENT_REPORT_MOST_COMMON_DAY_OF_INJURY_FAILURE,
  error,
});

// iNCIDENT/action items  WITH USER

export const getIncidentUserWithMostNoOfIncident = (data) => ({
  type: incidentType.GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS,
  data,
});

export const getIncidentUserWithMostNoOfIncidentSuccess = (data) => ({
  type: incidentType.GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS_SUCCESS,
  data,
});

export const getIncidentUserWithMostNoOfIncidentFailure = (error) => ({
  type: incidentType.GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS_FAILURE,
  error,
});
export const getIncidentUserWithMostNoOfIncidentList = (data) => ({
  type: incidentType.GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS_LIST,
  data,
});

export const getIncidentUserWithMostNoOfIncidentListSuccess = (data) => ({
  type: incidentType.GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS_LIST_SUCCESS,
  data,
});

export const getIncidentUserWithMostNoOfIncidentListFailure = (error) => ({
  type: incidentType.GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS_LIST_FAILURE,
  error,
});

export const getIncidentUserWithMostNoOfActionItem = (data) => ({
  type: incidentType.GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS,
  data,
});

export const getIncidentUserWithMostNoOfActionItemSuccess = (data) => ({
  type: incidentType.GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS_SUCCESS,
  data,
});

export const getIncidentUserWithMostNoOfActionItemFailure = (error) => ({
  type: incidentType.GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS_FAILURE,
  error,
});
export const getIncidentUserWithMostNoOfActionItemList = (data) => ({
  type: incidentType.GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS_LIST,
  data,
});

export const getIncidentUserWithMostNoOfActionItemListSuccess = (data) => ({
  type: incidentType.GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS_LIST_SUCCESS,
  data,
});

export const getIncidentUserWithMostNoOfActionItemListFailure = (error) => ({
  type: incidentType.GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS_LIST_FAILURE,
  error,
});
// project with most incidents

export const getProjectWithmostIncidents = (data) => ({
  type: incidentType.PROJECT_WITH_MOST_INCIDENT,
  data,
});

export const getProjectWithmostIncidentsSuccess = (data) => ({
  type: incidentType.PROJECT_WITH_MOST_INCIDENT_SUCCESS,
  data,
});

export const getProjectWithmostIncidentsFailure = (error) => ({
  type: incidentType.PROJECT_WITH_MOST_INCIDENT_FAILURE,
  error,
});

export const getProjectWithmostIncidentsList = (data) => ({
  type: incidentType.PROJECT_WITH_MOST_INCIDENT_LIST,
  data,
});

export const getProjectWithmostIncidentsListSuccess = (data) => ({
  type: incidentType.PROJECT_WITH_MOST_INCIDENT_LIST_SUCCESS,
  data,
});

export const getProjectWithmostIncidentsListFailure = (error) => ({
  type: incidentType.PROJECT_WITH_MOST_INCIDENT_LIST_FAILURE,
  error,
});

// vehicle with most incidents
export const getVehicleWithmostIncidents = (data) => ({
  type: incidentType.VEHICLE_WITH_MOST_INCIDENT,
  data,
});

export const getVehicleWithmostIncidentsSuccess = (data) => ({
  type: incidentType.VEHICLE_WITH_MOST_INCIDENT_SUCCESS,
  data,
});

export const getVehicleWithmostIncidentsFailure = (error) => ({
  type: incidentType.VEHICLE_WITH_MOST_INCIDENT_FAILURE,
  error,
});

export const getVehicleWithmostIncidentsList = (data) => ({
  type: incidentType.VEHICLE_WITH_MOST_INCIDENT_LIST,
  data,
});

export const getVehicleWithmostIncidentsListSuccess = (data) => ({
  type: incidentType.VEHICLE_WITH_MOST_INCIDENT_LIST_SUCCESS,
  data,
});

export const getVehicleWithmostIncidentsListFailure = (error) => ({
  type: incidentType.VEHICLE_WITH_MOST_INCIDENT_LIST_FAILURE,
  error,
});

export const getOSHAEstablishmentList = (data) => ({
  type: incidentType.OSHA_ESTABLISHMENT_LIST,
  data,
});

export const getOSHAEstablishmentListSuccess = (data) => ({
  type: incidentType.OSHA_ESTABLISHMENT_LIST_SUCCESS,
  data,
});

export const getOSHAEstablishmentListFailure = (error) => ({
  type: incidentType.OSHA_ESTABLISHMENT_LIST_FAILURE,
  error,
});

export const addEstablishment = (data) => ({
  type: incidentType.POST_OSHA_ESTABLISHMENT_ADD_PENDING,
  data,
});
export const addEstablishmentSuccess = (data) => ({
  type: incidentType.POST_OSHA_ESTABLISHMENT_ADD_SUCCESS,
  data,
});
export const addEstablishmentFailure = (error) => ({
  type: incidentType.POST_OSHA_ESTABLISHMENT_ADD_FAILURE,
  error,
});
export const clearAddEstablishment = () => ({
  type: incidentType.CLEAR_OSHA_ESTABLISHMENT,
  data: "",
});

export const updateEstablishment = (data) => ({
  type: incidentType.POST_OSHA_ESTABLISHMENT_UPDATE_PENDING,
  data,
});
export const updateEstablishmentSuccess = (data) => ({
  type: incidentType.POST_OSHA_ESTABLISHMENT_UPDATE_SUCCESS,
  data,
});
export const updateEstablishmentFailure = (error) => ({
  type: incidentType.POST_OSHA_ESTABLISHMENT_UPDATE_FAILURE,
  error,
});
export const clearUPdateEstablishment = () => ({
  type: incidentType.CLEAR_OSHA_ESTABLISHMENT,
  data: "",
});

export const updateEstablishmentStatus = (data) => ({
  type: incidentType.POST_OSHA_ESTABLISHMENT_STATUS_UPDATE_PENDING,
  data,
});
export const updateEstablishmentStatusSuccess = (data) => ({
  type: incidentType.POST_OSHA_ESTABLISHMENT_STATUS_UPDATE_SUCCESS,
  data,
});
export const updateEstablishmentStatusFailure = (error) => ({
  type: incidentType.POST_OSHA_ESTABLISHMENT_STATUS_UPDATE_FAILURE,
  error,
});
export const clearUpdateEstablishmentStatus = () => ({
  type: incidentType.CLEAR_OSHA_ESTABLISHMENT,
  data: "",
});

export const getEstablishmentDetail = (data) => ({
  type: incidentType.GET_OSHA_ESTABLISHMENT_DETAIL_PENDING,
  data,
});

export const getEstablishmentDetailSuccess = (data) => ({
  type: incidentType.GET_OSHA_ESTABLISHMENT_DETAIL_SUCCESS,
  data,
});

export const getEstablishmentDetailFailure = (error) => ({
  type: incidentType.GET_OSHA_ESTABLISHMENT_DETAIL_FAILURE,
  error,
});

export const getOshaLog = (data) => ({
  type: incidentType.GET_OSHA_LOG_PENDING,
  data,
});

export const getOshaLogSuccess = (data) => ({
  type: incidentType.GET_OSHA_LOG_SUCCESS,
  data,
});

export const getOshaLogFailure = (error) => ({
  type: incidentType.GET_OSHA_LOG_FAILURE,
  error,
});

export const getOshaDetails = (data) => ({
  type: incidentType.GET_OSHA_DETAILS_PENDING,
  data,
});

export const getOshaDetailsSuccess = (data) => ({
  type: incidentType.GET_OSHA_DETAILS_SUCCESS,
  data,
});

export const getOshaDetailsFailure = (error) => ({
  type: incidentType.GET_OSHA_DETAILS_FAILURE,
  error,
});

export const addOshaDetails = (data) => ({
  type: incidentType.POST_OSHA_DETAILS_PENDING,
  data,
});
export const addOshaDetailsSuccess = (data) => ({
  type: incidentType.POST_OSHA_DETAILS_SUCCESS,
  data,
});
export const addOshaDetailsFailure = (error) => ({
  type: incidentType.POST_OSHA_DETAILS_FAILURE,
  error,
});
export const clearAddOshaDetails = () => ({
  type: incidentType.CLEAR_Add_OSHA_DETAILS,
  data: "",
});

export const getListAddedInOSHA = (data) => ({
  type: incidentType.GET_LIST_ADDED_IN_OSHA_PENDING,
  data,
});

export const getListAddedInOSHASuccess = (data) => ({
  type: incidentType.GET_LIST_ADDED_IN_OSHA_SUCCESS,
  data,
});

export const getListAddedInOSHAFailure = (error) => ({
  type: incidentType.GET_LIST_ADDED_IN_OSHA_FAILURE,
  error,
});

export const getSubmitDetailsToOsha = (data) => ({
  type: incidentType.GET_SUBMIT_DETAILS_TO_OSHA_PENDING,
  data,
});

export const getSubmitDetailsToOshaSuccess = (data) => ({
  type: incidentType.GET_SUBMIT_DETAILS_TO_OSHA_SUCCESS,
  data,
});

export const getSubmitDetailsToOshaFailure = (error) => ({
  type: incidentType.GET_SUBMIT_DETAILS_TO_OSHA_FAILURE,
  error,
});

export const addGetOSHALog = (data) => ({
  type: incidentType.POST_SUBMIT_GET_OSHA_LOG,
  data,
});
export const addGetOSHALogSuccess = (data) => ({
  type: incidentType.POST_SUBMIT_GET_OSHA_LOG_SUCCESS,
  data,
});
export const addGetOSHALogFailure = (error) => ({
  type: incidentType.POST_SUBMIT_GET_OSHA_LOG_FAILURE,
  error,
});
export const clearAddGetOSHALog = () => ({
  type: incidentType.CLEAR_SUBMIT_GET_OSHA_LOG,
  data: "",
});
