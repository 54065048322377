import { getApiUrl } from "../config";
import { ORG_ID } from "../constant/constant";
import { post, get, deletes } from "./axiosRequest";
export const createCredential = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/Credential/AddCredential`;
  console.log("createCredential" + url);
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateCredential = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/Credential/UpdateCredential`;
  console.log("updateCredential" + url);
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getCredentialList = async ({ data }) => {
  const { pageSize, page, search, credentialTypeId, isSendMail } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/Credential/GetCredentialList?organisationId=${ORG_ID()}&credentialTypeId=${credentialTypeId}&page=${page}&pageSize=${pageSize}&search=${search}&isSendMail=${
    isSendMail || false
  }`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getCredentialDetails = async (credentialName) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/Credential/GetCredentialDetails?credentialNameId=${
    credentialName.data
  }&orgId=${ORG_ID()}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getCredentialListByUser = async ({ data }) => {
  const { pageSize, page, search, userId, isSendMail } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/Credential/GetAdminConfigureCredentialListByUsers?organisationId=${ORG_ID()}&userId=${userId}&search=${search}&page=${page}&pageSize=${pageSize}&isSendMail=${
    isSendMail || false
  }`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getCredentialListByCredential = async ({ data }) => {
  const { pageSize, page, credentialNameId, search, isSendMail } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/Credential/GetAdminConfigureCredentialListByCredential?organisationId=${ORG_ID()}&credentialNameId=${credentialNameId}&search=${search}&page=${page}&pageSize=${pageSize}&isSendMail=${
    isSendMail || false
  }`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
export const getAssignUserList = async ({ data }) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/Credential/AssignedCredentialUserList?organisationId=1&credentialNameId=2&issuingBodyId=1'`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getUserCredentialList = async ({ data }) => {
  const {
    pageSize,
    page,
    userId,
    orgId,
    search,
    credentialType,
    expirationType,
    userType,
    isSendMail,
  } = data;
  var url = `${
    getApiUrl().adminApiUrl
  }/Credential/GetConfigureCredentialList?organisationId=${orgId}&userId=${userId}&search=${search}&isSendMail=${isSendMail}&page=${page}&pageSize=${pageSize}&userType=${userType}`;
  url =
    url +
    `&expirationType=${
      expirationType ? expirationType : 0
    }&credentialType=${credentialType}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: orgId,
      },
    });
  } catch (err) {
    return err;
  }
};

export const createUserCredential = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/Credential/ConfigureCredential`;
  console.log("createCredential" + url);
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getSearchCredential = async ({ data }) => {
  const { search, orgId } = data;
  if (search?.trim()) {
    const url = `${
      getApiUrl().searchApiUrl
    }/v1/getcredentialnamebysearch?orgId=${orgId ? orgId : ORG_ID()}&search=${
      search ? search?.trim()?.toLowerCase() : ""
    }`;
    try {
      return await get(
        url,
        {
          headers: {
            Authorization: localStorage.getItem("id_token"),
          },
        },
        true
      );
    } catch (err) {
      return err;
    }
  }
};

export const assignCredential = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/Credential/AssignCredential`;
  console.log("createCredential" + url);
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getCredentialViewDetails = async (credentialId) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/Credential/GetConfigureCredentialDetails?credentialId=${
    credentialId.credentialId
  }&orgId=${ORG_ID()}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateUserCredential = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/Credential/ConfigureUpdateCredential`;
  console.log("updateUserCredential" + url);
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const createAdminCredential = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/Credential/ConfigureCredentialAdmin`;
  console.log("createCredential" + url);
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const deleteCredential = async (payload) => {
  const url = `${getApiUrl().adminApiUrl}/Credential/DeleteCredential/${
    payload?.credentialId
  }`;
  try {
    return await deletes(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
