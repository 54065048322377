import React from "react";
import "./topSection.scss";
import { getFormatedDate } from "../../utils/helper";

const TopSection = (props) => {
  // console.log(props);
  return (
    <div className="topSection">
      <div>
        <div className="title">{props?.title}</div>
      </div>
      <div>
        <div className="topColumn">
          {props?.column?.map((item, columnIndex) => {
            return (
              <>
                <div className="heading" key={columnIndex}>
                  {item?.value}
                </div>
              </>
            );
          })}
        </div>
        <div className="topRow">
          {props?.rows?.map((row, rowIndex) => {
            return (
              <div className="topRowList" key={rowIndex}>
                {props?.topsection === "credential" && (
                  <div className="heading">{row.userName}</div>
                )}
                <div className="heading">
                  {props?.topsection === "INJURED"
                    ? row?.bpiType
                    : props?.topsection === "CONT"
                    ? row?.type
                    : props?.topsection === "OPENCASE"
                    ? row?.caseId
                    : props?.topsection === "assetname"
                    ? row?.assetName
                    : props?.topsection === "USER_WITH_MOSTACTION" ||
                      props?.topsection === "USER_WITH_MOST_INC"
                    ? row?.user
                    : props?.topsection === "credential"
                    ? row?.credentialName
                    : props?.topsection === "NAME"
                    ? row?.name
                    : row?.userName}
                </div>
                <div className="heading">
                  {props?.topsection === "OPENCASE"
                    ? row?.createdOn
                    : props?.topsection === "credential"
                    ? getFormatedDate(row?.expirationDate)
                    : props?.topsection === "USER_WITH_MOSTACTION"
                    ? row?.actionItem
                    : props?.topsection === "USER_WITH_MOST_INC"
                    ? row?.incidents
                    : props?.topsection === "credential"
                    ? row?.expirationDate
                    : row?.count}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TopSection;
