import React, { useState } from "react";
import Buttons from "../../../../../library/custom/button/Button";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import UserFilterList from "../../../shared/UserFilterList ";
import {
  PAGE_SIZE,
  ORG_ID,
  ROW_PER_PAGE_SIZE,
} from "../../../../../constant/constant";
import GridTable from "../../../../../library/custom/gridTable/GridTable";
import SettingTable from "../../../../incidents/incidentList/SettingTable";
import ReportRowManipulte from "../../../../incidents/incidentList/ReportRowManipulte";
import NORow from "../../../../../library/custom/noRow/noRow";
import { TableLoader } from "../../../../../library/common/Loader";
import {
  ADMIN_CONTRACTOR_CONFIGURATION_EDIT,
  ADMIN_CONTRACTOR_CONFIGURATION_VIEW,
} from "../../../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import { checkPrivileges } from "../../../../../utils/rolesHelper";

function ContractorConfigurationList({
  OnboardedContractorListData,
  getOnboardedContractorLists,
  loading,
  updateOnboardedContractorList,
  recreateUsersList,
  onboardContractorStatus,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElAdd, setAnchorElAdd] = useState(null);
  const [search, setSearch] = useState("");
  const [contractorStatus, setContractorStatus] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [contractorConfigurationApiData, setContractorConfigurationApiData] =
    useState({
      page: 1,
      pageSize: pageSize,
      search: "",
      Status: "",
    });

  const [isTableLoading, setisTableLoading] = useState(false);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const openAdd = Boolean(anchorElAdd);
  const idAdd = openAdd ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      page: 1,
      pageSize: pageSize,
      search: searchText ? searchText : "",
      orgId: ORG_ID(),
      Status: contractorStatus,
    };
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    getOnboardedContractorLists(data);
  };

  const handleSearch = (data) => {
    setAnchorEl(null);
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    getOnboardedContractorLists(data);
  };
  const handleClear = () => {
    setContractorStatus("");
    const data = {
      page: 1,
      pageSize: pageSize,
      search: "",
      Status: "",
    };
    setSearch("");
    setContractorStatus("");
    setContractorConfigurationApiData({
      ...contractorConfigurationApiData,
      search: "",
      Status: "",
    });
    handleSearch(data);
  };
  const handleApply = (state) => {
    setContractorConfigurationApiData({
      ...contractorConfigurationApiData,
      Status: contractorStatus,
    });
    setAnchorEl(null);
    const data = {
      ...contractorConfigurationApiData,
      page: 1,
      pageSize: pageSize,
      Status: contractorStatus,
      search: search,
    };
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    getOnboardedContractorLists(data);
  };

  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    const data = {
      ...contractorConfigurationApiData,
      page: 1,
      pageSize: newPazeSize,
      search: search,
      Status: contractorStatus ? contractorStatus : "",
    };
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    getOnboardedContractorLists(data);
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      ...contractorConfigurationApiData,
      page: newPage + 1,
      pageSize: pageSize,
      search: search,
      Status: contractorStatus ? contractorStatus : "",
    };
    setContractorConfigurationApiData({
      ...contractorConfigurationApiData,
      page: newPage + 1,
      pageSize: pageSize,
      search: search,
      Status: contractorStatus ? contractorStatus : "",
    });
    getOnboardedContractorLists(data);
  };

  const handleOnclick = (rows) => {
    if (
      rows.row.status == 1 ||
      rows.row.status == 4 ||
      rows.row.status == 6 ||
      rows.row.status == 7
    ) {
      navigate(ADMIN_CONTRACTOR_CONFIGURATION_VIEW, {
        state: { onboardUserId: rows.row.onboardUserId, editMode: true },
      });
    } else if (rows.row.status == 2 || rows.row.status == 5) {
      navigate(ADMIN_CONTRACTOR_CONFIGURATION_EDIT, {
        state: { onboardUserId: rows.row.onboardUserId, editMode: true },
      });
    }
  };

  const handleResendOnClick = (rows) => {
    const data = {
      userid: rows.row.onboardUserId,
    };
    recreateUsersList(data);
  };

  const contractorStatusOnChanges = (e) => {
    setContractorStatus(e.target.value?.toString());
  };

  function getColorCode(status) {
    let colors;
    if (status === 2 || status === 5) {
      colors = "Crimson";
    }
    return colors;
  }

  const columns = [
    { field: "onboardUserId", headerName: "Contractor ID", flex: 3 },
    { field: "contractorName", headerName: "Contractor Name", flex: 3 },
    {
      field: "statusText",
      headerName: "Status",
      flex: 4,
      renderCell: (rows) => {

        return (
          <div style={{ color: getColorCode(rows?.row?.status) }}>
            {rows?.row?.status === 7
              ? "Contractor Login Successfully"
              : rows?.row?.status === 6
                ? "Disable Contractor in Cognito"
                : rows?.row?.status === 0
                  ? "Contractor Onboarded"
                  : rows?.row?.statusText}
          </div>
        );
      },
    },
    {
      field: "icon",
      renderHeader: () => "",
      flex: 1,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            editOnClick={() => handleOnclick(rows)}
            edit={checkPrivileges([12, 43, 44])}
            editLabel="Edit Contractor"
            resendEmail={rows.row.status == 4}
            resendEmailLable={"Resend welcome email"}
            resendEmailOnClick={() => handleResendOnClick(rows)}
          />
        );
      },
    },
  ];

  const [accessContractorId, setAccessContractorId] = useState([]);
  const handleClickGrantAccess = () => {
    const accessData = {
      contractorIds: accessContractorId,
    };
    updateOnboardedContractorList(accessData);
  };

  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">Contractor Configuration</div>
          <div>
            <div className="filter_btn">
              {checkPrivileges([12, 43, 44]) && (
                <Buttons
                  id="btnAddUsers"
                  aria-describedby={idAdd}
                  varientContained={true}
                  label={"Grant Access"}
                  onClick={(e) => handleClickGrantAccess()}
                />
              )}
            </div>
          </div>
        </div>
        <div className="grid_main_body">
          <div className="filter_of_caselist">
            <div className="searchcaseid">
              <label htmlFor="search">
                <span>
                  <SearchIcon />
                </span>
                <div>
                  <input
                    id="search"
                    placeholder="Search"
                    type="text"
                    value={search}
                    className=""
                    onChange={handleautoSearch}
                    autoComplete="off"
                  />
                </div>
              </label>
            </div>
            <div className="filter_btn">
              <button
                aria-describedby={id}
                variant="contained"
                type="button"
                name="btn"
                onClick={(e) => handleClick(e)}
              >
                <FilterListIcon id="icn" /> Filters
              </button>
              <UserFilterList
                secondLabel="Status"
                id={id}
                open={open}
                anchorEl={anchorEl}
                handleClose={handleClose}
                secondLebelValue={contractorStatus}
                handleSearch={handleSearch}
                handleApply={handleApply}
                handleClear={handleClear}
                secondLebelFilter={onboardContractorStatus}
                resetBtn={true}
                secondLebelOnChanges={contractorStatusOnChanges}
              />
            </div>
          </div>
          {isTableLoading ? (
            ""
          ) : (
            <div className="desc_box row_uniq">
              <div className="table_style_ui">
                <GridTable
                  getRowId={(r) => r.onboardUserId}
                  rows={OnboardedContractorListData?.data?.contractorData ?? []}
                  rowCount={OnboardedContractorListData?.data?.itemsCount ?? 0}
                  columns={columns}
                  loading={loading}
                  components={{
                    LoadingOverlay: TableLoader,
                    NoRowsOverlay: NORow,
                  }}
                  checkboxSelection
                  page={page}
                  pagination
                  pageSize={pageSize}
                  rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                  onPageSizeChange={handlePageSizeChange}
                  onPageChange={handlePagignation}
                  backIconButtonProps={{ disabled: false }}
                  disableSelectionOnClick
                  onSelectionModelChange={setAccessContractorId}
                  isRowSelectable={(rows) =>
                    rows.row.status == 1 || rows.row.status == 5
                  }
                />
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default ContractorConfigurationList;
