import React, { useState } from "react";
import * as vehiclesAction from "../../../redux/actions/vehiclesAction";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import {
  API_STATUS,
  LOGIN_ORG_ID,
  ORG_ID,
  PAGE_SIZE,
} from "../../../constant/constant";
import { ACCESS_DENIED, ADMIN_ADD_VEHICLES } from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import VehicleList from "../component/vehicles/vehicleList";
import * as vehicleAction from "../../../redux/actions/vehiclesAction";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import { PRODUCT_ID } from "../../../constant/storm-water/constant";
import { checkPrivileges } from "../../../utils/rolesHelper";

export default function VehiclesContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const vehicleList = useSelector((state) => state.vehicleList);
  const [sendEmail, setSendEmail] = useState(false);
  const isLoading = useSelector(
    (state) => state.vehicleList?.isLoading || state.deleteVehicle?.isLoading
  );

  const vehicleListDelete = useSelector((state) => state.deleteVehicle.status);

  const clientDivision = useSelector((state) => state.getDropdownDataKeyValue);

  const vehicleCategory = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.vehicleCategories
  );
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const divisions = userInfo?.divisions?.filter(
    (i) => i?.serviceType == PRODUCT_ID()
  );
  const isClient = userInfo?.isClient;

  const [clientDivisions, setClientDivisions] = useState();
  const valueCheck = clientDivisions || divisions;
  const clientSwitch = isClient || ORG_ID() !== LOGIN_ORG_ID();

  useEffect(() => {
    if (clientSwitch) {
      if (clientDivision?.status === API_STATUS.SUCCESS) {
        const divisions = clientDivision?.data?.divisons && [
          ...clientDivision?.data?.divisons,
        ];
        divisions?.unshift({ key: 1, value: "All Divisions" });
        setClientDivisions(divisions);
      }
    }
  }, [clientDivision, clientSwitch]);

  const defaultData = {
    page: 1,
    pageSize: PAGE_SIZE,
    statusIds: "",
    compnayName: "",
    search: "",
    divisionId: clientSwitch && valueCheck ? 0 : divisions[0]?.key,
    userId: 0,
    organisationId: ORG_ID(),
    isSendMail: false,
  };

  useEffect(() => {
    getVehicleList(defaultData);
  }, []);

  const getVehicleList = (data) => {
    if (data?.isSendMail) {
      setSendEmail(true);
    } else {
      setSendEmail(false);
    }
    dispatch(vehiclesAction.getVehicleList(data));
  };

  useEffect(() => {
    if (vehicleListDelete === "SUCCESS") getVehicleList(defaultData);
  }, [vehicleListDelete, dispatch]);

  const addVehicle = () => {
    navigate(ADMIN_ADD_VEHICLES);
  };

  function deleteVehicle(data) {
    dispatch(vehicleAction.deleteVehicle(data));
  }

  useEffect(() => {
    const getDropdownDataKeyValueData = {
      orgId: ORG_ID(),
      flags: [6, 11],
      productId: PRODUCT_ID(),
    };
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);

  useEffect(() => {
    if (vehicleList?.status === API_STATUS.SUCCESS && sendEmail) {
      toast("Vehicles has been sent to registered email ID");
    }
  }, [vehicleList]);

  return (
    <>
      {checkPrivileges([12, 78, 79, 80]) ? (
        <>
          <ToastContainer />
          <VehicleList
            vehicles={isLoading ? [] : vehicleList?.data}
            getVehicleList={getVehicleList}
            addVehicle={addVehicle}
            isLoading={isLoading}
            deleteVehicle={deleteVehicle}
            divisionDropdown={
              clientSwitch && valueCheck ? clientDivisions : divisions
            }
            vehicleCategory={vehicleCategory ?? []}
          />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}
