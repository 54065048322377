import React, { useState } from "react";

import "../../../incidents/cases/addCaseList.scss";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import { TableLoader } from "../../../../library/common/Loader";
import { PAGE_SIZE, ORG_ID, USER_ID } from "../../../../constant/constant";
import { COURSES } from "../../../../constant/routeContant";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import NORow from "../../../../library/custom/noRow/noRow";
import Button from "../../../../library/custom/button/Button";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
import TextEditor from "../../../../library/custom/textEditor/TextEditor";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";
import { getFormatedDate } from "../../../../utils/helper";

export default function AssignedUser({
  getCoursesAssignUserListData,
  coursesUserAssignListData,
  isLoading,
  coursename,
  courseId,
  fieldid,
  onSubmit,
  postunAssignCourse,
  setisunAssignbtn,
  setisdisable,
  isdisable,
}) {
  let titleCourse;
  if (fieldid === "true") {
    titleCourse = "COMPLETED USERS";
  } else if (fieldid === "false") {
    titleCourse = "NOT COMPLETED USERS";
  } else {
    titleCourse = "ASSIGNED USERS";
  }

  const dataBack = {
    title: "Back to LMS Dashboard",
    route: COURSES,
  };
  const [selectedUserId, setselectedUserId] = useState([]);
  const [customCourseMessage, setCustomCourseMessage] = useState(
    "This is a reminder that you have training that is incomplete."
  );
  const [open, setOpen] = React.useState(false);
  const [editInsName, setInsName] = useState(false);

  const handleclickAssign = (userId) => {
    setOpen(true);
    setInsName(true);
    const useridArr = [];
    useridArr.push(userId);
    setselectedUserId(useridArr);
  };
  const onchangeCustomMessage = (e) => {
    setCustomCourseMessage(e);
  };
  const handleAlertMail = () => {
    const data = {
      userId: selectedUserId,
      lessonCourseId: courseId,
      lessonCoursetype: 2,
      dynamicText: customCourseMessage,
    };

    onSubmit(data);
    setOpen(false);
    setCustomCourseMessage(
      "This is a reminder that you have training that is incomplete."
    );
  };
  const handleClose = () => {
    setCustomCourseMessage(
      "This is a reminder that you have training that is incomplete."
    );
    setOpen(false);
    setselectedUserId([]);
  };

  // column for Course assigned and Not Completed pages
  const handleunAssignCourseOnClick = (data) => {
    setisunAssignbtn(true);
    setisdisable(true);
    const dataResult = {
      orgId: parseInt(ORG_ID()),
      courseId: courseId,
      userId: data?.row?.assignedToId,
      modifiedBy: parseInt(USER_ID()),
    };

    postunAssignCourse(dataResult);
  };

  // function for get lesson versions detail data
  const getcompletedcoursedetail = (val) => {
    const dataArr = val?.map((data) => {
      if (data?.prevCourseDates) {
        return getFormatedDate(data?.prevCourseDates);
      }
    });

    return dataArr;
  };
  const columns_assign = [
    { field: "assignedTo", headerName: "Assigned TO", width: 120, flex: 1 },

    { field: "dateAssigned", headerName: "Date Assigned", flex: 1 },
    {
      field: "timeEstimatedMins",
      headerName: "Time Estimated",
      type: "text",
      flex: 1,
    },
    {
      field: "timeElapsedMins",
      headerName: "Time Elapsed",
      type: "text",
      flex: 1,
    },
    {
      field: "timeElapsedMins12",
      headerName: fieldid === "false" ? "Completed Times" : "Assigned Times",
      type: "text",
      flex: 1,
      minWidth: 100,
      renderCell: (cellValues) => {
        const datacheckVal = getcompletedcoursedetail(
          cellValues?.row?.prevCourseDetailList
        );
        return datacheckVal[0] === "undefined" ? (
          ""
        ) : (
          <div>{datacheckVal?.length} times</div>
        );
      },
    },
    {
      field: "prevCourseDetailList",
      headerName: "Assigned On",
      type: "text",
      flex: 1,
      minWidth: 100,
      renderCell: (cellValues) => {
        const dtacheck = getcompletedcoursedetail(
          cellValues?.row?.prevCourseDetailList
        );
        var tempvAL1 = [...dtacheck];
        tempvAL1.pop();
        var datacheck2 = tempvAL1?.join(",");

        return (
          <>
            <div>
              {dtacheck[dtacheck?.length - 1]
                ? dtacheck[dtacheck?.length - 1]
                : null}
            </div>{" "}
            <div title={datacheck2} className="morelessdetail">
              {" "}
              {dtacheck?.length > 1
                ? ` +${dtacheck ? dtacheck?.length - 1 : ""}`
                : ""}
            </div>
          </>
        );
      },
    },

    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={coursesUserAssignListData?.noOfRecords ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={
            coursesUserAssignListData?.assignedUserAdminResponse ?? []
          }
          fileNameXL={
            fieldid === "false" ? "CourseAssigned" : "CourseCompleted"
          }
        />
      ),
      width: fieldid === "false" ? 150 : 70,
      sortable: false,
      disableClickEventBubbling: true,

      renderCell: (cellValues) => {
        if (fieldid === "false") {
          return (
            <>
              {" "}
              <div className="alertSet">
                <div className="alertbtn">
                  <Button
                    primaryalert={true}
                    onClick={(row) => handleclickAssign(cellValues?.id)}
                    label="Alert"
                  />
                </div>
                <ReportRowManipulte
                  rows={cellValues}
                  unAssignLesson={true}
                  unAssignLessonLabel={
                    <span className={isdisable ? "disableclss" : ""}>
                      {" "}
                      Unassign Course
                    </span>
                  }
                  unAssignLessonOnclick={() =>
                    !isdisable && handleunAssignCourseOnClick(cellValues)
                  }
                />
              </div>
            </>
          );
        }
      },
    },
  ];
  if (fieldid === "false") {
    columns_assign.splice(4, 2);
  }

  const columns_comp = [
    { field: "assignedTo", headerName: "Completed By", width: 120, flex: 1 },

    { field: "dateAssigned", headerName: "Date Completed", flex: 1 },
    {
      field: "timeEstimatedMins",
      headerName: "Time Estimated",
      type: "text",
      flex: 1,
    },
    {
      field: "timeElapsedMins",
      headerName: "Time Elapsed",
      type: "text",
      flex: 1,
    },
    {
      field: "timeElapsedMins12",
      headerName: "Completed Times",
      type: "text",
      flex: 1,
      minWidth: 120,
      renderCell: (cellValues) => {
        const datacheckVal = getcompletedcoursedetail(
          cellValues?.row?.prevCourseDetailList
        );

        return <div>{datacheckVal?.length} times</div>;
      },
    },
    {
      field: "prevCourseDetailList",
      headerName: "Completed On",
      type: "text",
      flex: 1,
      minWidth: 100,
      renderCell: (cellValues) => {
        const dtacheck = getcompletedcoursedetail(
          cellValues?.row?.prevCourseDetailList
        );

        var tempvAL1 = [...dtacheck];
        tempvAL1.pop();

        var datacheck2 = tempvAL1?.join(",");
        return (
          <>
            <div>{dtacheck[dtacheck?.length - 1]}</div>{" "}
            <div title={datacheck2} className="morelessdetail">
              {dtacheck.length > 1
                ? ` +${dtacheck ? dtacheck?.length - 1 : ""}`
                : ""}
            </div>
          </>
        );
      },
    },

    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={coursesUserAssignListData?.noOfRecords ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={
            coursesUserAssignListData?.assignedUserAdminResponse ?? []
          }
          fileNameXL={
            fieldid === "false" ? "CourseAssigned" : "CourseCompleted"
          }
        />
      ),
      width: 100,
      sortable: false,
      disableClickEventBubbling: true,

      renderCell: (cellValues) => {
        if (fieldid === "false") {
          return (
            <div className="alertbtn">
              <Button
                primaryalert={true}
                onClick={(row) => handleclickAssign(cellValues?.id)}
                label="Alert"
              />
            </div>
          );
        }
      },
    },
  ];
  const handlePagignation = (newPage) => {
    getCoursesAssignUserListData({
      pageNumber: newPage + 1,
      pageSize: PAGE_SIZE,
      courseId: courseId,
      isCompleted: fieldid,
    });
  };
  const handleclickAssignAll = () => {
    setOpen(true);
    setInsName(true);
    setselectedUserId([]);
  };
  const handleAlertAll = () => {
    const UsersIds = coursesUserAssignListData?.assignedUserAdminResponse?.map(
      (val) => {
        return val?.assignedToId;
      }
    );
    const data = {
      userId: UsersIds,
      lessonCourseId: courseId,
      lessonCoursetype: 2,
      dynamicText: customCourseMessage,
    };

    onSubmit(data);
    setOpen(false);
    setCustomCourseMessage(
      "This is a reminder that you have training that is incomplete."
    );
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid className="modalContainer">
          {editInsName && (
            <Box className="modalBox">
              <div className="modalTitle">Send Custom Message</div>
              {/* <div className="modalInputBox"> */}
              <Grid container mt={2} lg={12} className="lesson-alert-model">
                <TextEditor
                  value={customCourseMessage}
                  placeholderText={"Enter Here..."}
                  onChange={(e) => {
                    onchangeCustomMessage(e);
                  }}
                />
              </Grid>

              {/* </div> */}
              <Grid container className="modalFooterBox" mt={2}>
                <Grid item lg={2} xs={12} sm={3} md={3}>
                  <Button
                    varientText={true}
                    label="Cancel"
                    onClick={handleClose}
                  />
                </Grid>
                <Grid item lg={3} xs={12} sm={3} md={3}>
                  <Button
                    redButton={true}
                    label={"Send Alert"}
                    onClick={
                      selectedUserId.length > 0
                        ? handleAlertMail
                        : handleAlertAll
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
      </Modal>
      <BackBtn dataBack={dataBack} />
      <div className="assignDivtitle">
        <div className="assigntitle">
          <div className="lessonnme">{coursename}</div>

          <div className="lessonnmeT">{titleCourse}</div>
        </div>
        {fieldid == "false" && (
          <div className="allertall">
            {coursesUserAssignListData?.assignedUserAdminResponse?.length >
              1 && (
              <Button
                primaryalertAll={true}
                onClick={(row) => handleclickAssignAll()}
                label="Alert All"
              />
            )}
          </div>
        )}
      </div>
      <div className="tableData">
        <GridTable
          getRowId={(r) => r.assignedToId}
          rows={
            coursesUserAssignListData?.assignedUserAdminResponse
              ? coursesUserAssignListData?.assignedUserAdminResponse
              : []
          }
          columns={
            fieldid === "false" || fieldid === ""
              ? columns_assign
              : columns_comp
          }
          pageSize={PAGE_SIZE}
          rowsPerPageOptions={[10]}
          rowCount={coursesUserAssignListData?.noOfRecords}
          paginationMode="server"
          loading={isLoading}
          onPageChange={handlePagignation}
          disableColumnSelector
          disableColumnFilter
          disableColumnMenu
          CanUserAddRows="False"
          IsReadOnly="True"
          components={{
            LoadingOverlay: TableLoader,
            NoRowsOverlay: NORow,
          }}
        />
      </div>
    </>
  );
}
