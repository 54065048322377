import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UserConfigurationView from "../component/users/userConfigurationView";
import { ADMIN_USER_CONFIGURATION } from "../../../constant/routeContant";
import * as userAction from "../../../redux/actions/userAction";
import { API_STATUS } from "../../../constant/constant";
import Loader from "../../../library/common/Loader";
import SuccessScreen from "../../../library/custom/success/successScreen";
import { useState } from "react";
import { checkPrivileges } from "../../../utils/rolesHelper";

function UserConfigurationViewContainer() {
  const [successScreens, setIsSuccess] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const onboardUserId = location?.state?.onboardUserId;
  const [openGrandAccess, setOpenGrandAccess] = useState(false);
  const [openGrandResetAccess, setOpenGrandResetAccess] = useState(false);
  const getUserConfigurationDetsilsData = useSelector(
    (state) => state?.getUserConfigurationDetsilsData?.data
  );

  const updateUserOnboardStatusData = useSelector(
    (state) => state.updateUserOnboardStatusData
  );

  const updateUserOnboardStatusDataStatus = useSelector(
    (state) => state.updateUserOnboardStatusData.status
  );

  const updateUserConfigurationData = useSelector(
    (state) => state.updateUserConfigurationData
  );
  const updateUserConfigurationDataStatus = useSelector(
    (state) => state.updateUserConfigurationData.status
  );
  const showLoader = useSelector(
    (state) =>
      state?.getUserConfigurationDetsilsData?.isLoading ||
      state?.updateUserConfigurationData.isLoading ||
      state?.updateUserOnboardStatusData.isLoading
  );
  const isSuccess = useSelector(
    (state) =>
      state.getUserConfigurationDetsilsData.status === API_STATUS.SUCCESS
  );
  const userConfigurationListData = useSelector(
    (state) => state.userConfigurationData
  );

  const updateUserConfiguration = (data) => {
    setIsSuccess(true);
    dispatch(userAction.updateUserConfigurationList(data));
  };

  const updateUserOnboardStatus = (data) => {
    setIsSuccess(true);
    dispatch(userAction.updateUserOnboardStatus(data));
  };

  useEffect(() => {
    dispatch(userAction.getUserConfigurationDetsils(onboardUserId));
  }, [dispatch]);

  const dataBack = {
    title: "Back to User Configuration List",
    route: ADMIN_USER_CONFIGURATION,
  };

  useEffect(() => {
    if (updateUserOnboardStatusData.status === API_STATUS.SUCCESS) {
      setOpenGrandAccess(false);
      setOpenGrandResetAccess(false);
      dispatch(userAction.getUserConfigurationDetsils(onboardUserId));
    }
  }, [updateUserOnboardStatusData]);
  const successScreen = (id) => {
    return (
      <SuccessScreen
        id={id}
        isUpdate={"User Configured Successfully"}
        route={ADMIN_USER_CONFIGURATION}
        label1=" "
        label2=" "
        label4="User Configuration List"
      ></SuccessScreen>
    );
  };

  return (
    <>
      {checkPrivileges([12, 64, 65]) ? (
        <>
          {showLoader && <Loader />}
          {isSuccess &&
          updateUserConfigurationDataStatus === API_STATUS.SUCCESS &&
          updateUserConfigurationData?.data &&
          successScreens ? (
            successScreen(updateUserConfigurationData?.data?.id)
          ) : (
            <UserConfigurationView
              dataBack={dataBack}
              getUserConfigurationDetsilsData={getUserConfigurationDetsilsData}
              onboardUserId={onboardUserId}
              updateUserConfiguration={updateUserConfiguration}
              userConfigurationListData={userConfigurationListData?.data}
              updateUserOnboardStatus={updateUserOnboardStatus}
              openGrandAccess={openGrandAccess}
              setOpenGrandAccess={setOpenGrandAccess}
              openGrandResetAccess={openGrandResetAccess}
              setOpenGrandResetAccess={setOpenGrandResetAccess}
            />
          )}
        </>
      ) : (
        <UserConfigurationView
          dataBack={dataBack}
          getUserConfigurationDetsilsData={getUserConfigurationDetsilsData}
          onboardUserId={onboardUserId}
          updateUserConfiguration={updateUserConfiguration}
          userConfigurationListData={userConfigurationListData?.data}
          updateUserOnboardStatus={updateUserOnboardStatus}
        />
      )}
    </>
  );
}

export default UserConfigurationViewContainer;
