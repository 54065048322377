import * as userAction from "../actions/userAction";
import { userType } from "../constants/userType";
import {
  getUserDetails,
  searchUsers,
  getUserLists,
  addLists,
  getGroupList,
  addUserGroups,
  getUserListDetails,
  updateUserListDetails,
  deleteUserList,
  getAllUsers,
  deleteUserGroupService,
  updateUserGroupService,
  getUserGroupDetailsService,
  leaveGroups,
  searchAllGroup,
  getUserGroupList,
  getUserConfigurationLists,
  updateUserConfigurationList,
  getUserConfigurationDetails,
  updateUserOnboardDetails,
  updateUserOnboardStatus,
  getNonPrivilegeList,
  deleteNonPrivilegeUserList,
  addNonPrivilegeUserList,
  updateNonPrivilegeUserList,
  getNonPrivilegeUserDetails,
  convertNonPrivilegeUserList,
  recreateUsers,
  getAssetOnUser,
  getSupervisorOnAsset,
  checkUserInfos,
  getUserByRoleList,
  getSyncWithProcoreUserList,
} from "../../services/userService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

export function* userDetails({ mobile }) {
  console.debug("userDetailsSaga");
  try {
    const { data, status, error } = yield call(getUserDetails, mobile);
    if (!data && status !== 200) throw apiError(error);
    const userPage = userType.USER_PAGE;
    yield put(
      userAction.getUserDetailsSuccess({
        userPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get User Details Failed" };
    yield put(userAction.getUserDetailsFailure(data));
  }
}

export function* userListSaga(paramsData) {
  console.debug("userListSaga");
  try {
    const { data, status, error } = yield call(getUserLists, paramsData);
    if (!data && status !== 200) throw apiError(error);
    const userPage = userType.USER_PAGE;
    if (!paramsData.data.isSendMail) {
      yield put(
        userAction.getUserListSuccess({
          userPage,
          data,
        })
      );
    } else {
      yield put(userAction.getUserListEmailSuccess());
    }
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get User List Failed" };
    yield put(userAction.getUserListFailure(data));
  }
}

export function* searchUsersSaga({ searchType, searchText }) {
  console.debug("searchUsersSaga");
  try {
    const {
      data: result,
      status,
      error,
    } = yield call(searchUsers, searchType, searchText);
    if (!result && status !== 200) throw apiError(error);
    const data = {
      responseMessages: {
        responseCode: "HBNG001",
      },
      users: result,
    };
    const userPage = userType.USER_PAGE;
    yield put(
      userAction.searchUsersSuccess({
        userPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Search User Failed" };
    yield put(userAction.searchUsersFailure(data));
  }
}

export function* allUsersSaga({
  searchText,
  searchType,
  divisionId,
  assetType,
  assetId,
  priviledgeId,
  productId,
  orgId,
  withSpace,
}) {
  try {
    const {
      data: result,
      status,
      error,
    } = yield call(
      getAllUsers,
      searchText,
      searchType,
      divisionId,
      assetType,
      assetId,
      priviledgeId,
      productId,
      orgId,
      withSpace
    );
    if (!result && status !== 200) throw apiError(error);
    const data = {
      responseMessages: {
        responseCode: "HBNG001",
      },
      users: result,
    };
    const userPage = userType.USER_PAGE;
    yield put(
      userAction.searchAllUsersSuccess({
        userPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Search all User Failed" };
    yield put(userAction.searchAllUsersFailure(data));
  }
}

export function* addUser(payload) {
  console.debug("add user");
  try {
    const { data, status, error } = yield call(addLists, payload);
    if (!data && status !== 200) throw apiError(error);
    const userPage = userType.USER_PAGE;
    yield put(
      userAction.addUserListSuccess({
        userPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get User Details Failed" };
    yield put(userAction.addUserListFailure(data));
  }
}

export function* userGroupListSaga(paramsData) {
  console.debug("userGroupListSaga");
  try {
    const { data, status, error } = yield call(getGroupList, paramsData);
    if (!data && status !== 200) throw apiError(error);
    const userPage = userType.USER_PAGE_GROUP;
    yield put(
      userAction.getListGroupListsSuccess({
        userPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get User group list Failed" };
    yield put(userAction.getListGroupListsFailure(data));
  }
}

export function* addGroupUser(payload) {
  console.debug("AddGroupUser");
  try {
    const { data, status, error } = yield call(addUserGroups, payload);
    if (!data && status !== 200) throw apiError(error);
    const userPage = userType.USER_PAGE_GROUP;
    yield put(
      userAction.addUserGroupSuccess({
        userPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get User Details Failed" };
    yield put(userAction.addUserGroupFailure(data));
  }
}

export function* userListDetailsSaga(paramsData) {
  console.debug("userListDetailsSaga");
  try {
    const { data, status, error } = yield call(getUserListDetails, paramsData);
    if (!data && status !== 200) throw apiError(error);
    const userPage = userType.USER_PAGE;
    yield put(
      userAction.getUserListDetsilsSuccess({
        userPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get User List Details Failed" };
    yield put(userAction.getUserListDetsilsFailure(data));
  }
}

export function* updateUserListSaga(params) {
  console.debug("updateUserListSaga");
  try {
    const { data, status, error } = yield call(updateUserListDetails, params);
    if (!data && status !== 200) throw apiError(error);
    const userPage = userType.USER_PAGE;
    yield put(
      userAction.updateUserListSuccess({
        userPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "User list updation Failed" };
    yield put(userAction.updateUserListFailure(data));
  }
}

function* deleteListSaga(payload) {
  console.debug("deleteListSaga");
  try {
    const { data, status, error } = yield call(deleteUserList, payload);
    if (!data && status !== 200) throw apiError(error);
    const userPage = userType.USER_PAGE;
    yield put(
      userAction.deleteListSuccess({
        userPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message ? error : { message: error || "Delete user list" };
    yield put(userAction.deleteListFailure(data));
  }
}

export function* getUserGroupDetailsSaga(paramsData) {
  console.debug("getUserGroupDetailsSaga");
  try {
    const { data, status, error } = yield call(
      getUserGroupDetailsService,
      paramsData
    );
    if (!data && status !== 200) throw apiError(error);
    const userPage = userType.USER_PAGE;
    yield put(
      userAction.getUserGroupDetailsSuccess({
        userPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get User Group Details Failed" };
    yield put(userAction.getUserGroupDetailsFailure(data));
  }
}

export function* updateUserGroupSaga(params) {
  console.debug("updateUserGroupSaga");
  try {
    const { data, status, error } = yield call(updateUserGroupService, params);
    if (!data && status !== 200) throw apiError(error);
    const userPage = userType.USER_PAGE;
    yield put(
      userAction.updateUserGroupSuccess({
        userPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "User list updation Failed" };
    yield put(userAction.updateUserGroupFailure(data));
  }
}

function* deleteUserGroupSaga(payload) {
  console.debug("deleteUserGroupSaga");
  try {
    const { data, status, error } = yield call(deleteUserGroupService, payload);
    if (!data && status !== 200) throw apiError(error);
    const userPage = userType.USER_PAGE;
    yield put(
      userAction.deleteUserGroupuccess({
        userPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message ? error : { message: error || "Delete user list" };
    yield put(userAction.deleteUserGroupFailure(data));
  }
}

export function* leaveGroupSaga(paramsData) {
  console.debug("leaveGroupSaga");
  try {
    const { data, status, error } = yield call(leaveGroups, paramsData);
    if (!data && status !== 200) throw apiError(error);
    const userPage = userType.USER_PAGE_GROUP;
    yield put(
      userAction.leaveGroupuccess({
        userPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Leave Group Failed" };
    yield put(userAction.leaveGroupFailure(data));
  }
}

export function* searchAllGroupSaga({
  searchText,
  divisionId,
  alldivision,
  orgId,
  assetType,
  assetId,
}) {
  try {
    const {
      data: result,
      status,
      error,
    } = yield call(
      searchAllGroup,
      searchText,
      divisionId,
      alldivision,
      orgId,
      assetType,
      assetId
    );

    if (!result && status !== 200) throw apiError(error);
    const data = {
      responseMessages: {
        responseCode: "HBNG001",
      },
      groupList: result,
    };
    const userPage = userType.USER_PAGE;
    yield put(
      userAction.searchAllGroupSuccess({
        userPage,
        data,
        divisionId,
        alldivision,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Search Group Failed" };
    yield put(userAction.searchAllGroupFailure(data));
  }
}

export function* individualUserGroupListSaga(paramsData) {
  console.debug("individualUserGroupListSaga");
  try {
    const { data, status, error } = yield call(getUserGroupList, paramsData);
    if (!data && status !== 200) throw apiError(error);
    const Page = userType.GET_USER_INVOLVED_GROUP_LIST_SUCCESS;
    yield put(
      userAction.getUserGroupListsDataSuccess({
        Page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get User group list Failed" };
    yield put(userAction.getUserGroupListsDataFailure(data));
  }
}

export function* userConfigurationListSaga(paramsData) {
  console.debug("userConfigurationListSaga");
  try {
    const { data, status, error } = yield call(
      getUserConfigurationLists,
      paramsData
    );
    if (!data && status !== 200) throw apiError(error);
    const userPage = userType.USER_PAGE;
    if (paramsData.data.isSendMail) {
      yield put(userAction.getUserConfigurationListEmailSuccess());
    } else {
      yield put(
        userAction.getUserConfigurationListSuccess({
          userPage,
          data,
        })
      );
    }
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get User List Failed" };
    yield put(userAction.getUserConfigurationListFailure(data));
  }
}

export function* updateUserConfigurationListSaga(params) {
  console.debug("updateUserConfigurationListSaga");
  try {
    const { data, status, error } = yield call(
      updateUserConfigurationList,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const userPage = userType.USER_PAGE;
    yield put(
      userAction.updateUserConfigurationListSuccess({
        userPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "User Configuration list updation Failed" };
    yield put(userAction.updateUserConfigurationListFailure(data));
  }
}

export function* userConfigurationDetailsSaga(paramsData) {
  console.debug("userConfigurationtDetailsSaga");
  try {
    const { data, status, error } = yield call(
      getUserConfigurationDetails,
      paramsData
    );
    if (!data && status !== 200) throw apiError(error);
    const userPage = userType.USER_PAGE;
    yield put(
      userAction.getUserConfigurationDetsilsSuccess({
        userPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get User Configuration Details Failed" };
    yield put(userAction.getUserConfigurationDetsilsFailure(data));
  }
}

export function* updateUserOnboardSaga(params) {
  console.debug("updateUserOnboardSaga");
  try {
    const { data, status, error } = yield call(
      updateUserOnboardDetails,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const userPage = userType.USER_PAGE;
    yield put(
      userAction.updateUserOnboardSuccess({
        userPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "User Onboard updation Failed" };
    yield put(userAction.updateUserOnboardFailure(data));
  }
}

export function* updateUserOnboardStatusSaga(params) {
  console.debug("updateUserOnboardStatusSaga");
  try {
    const { data, status, error } = yield call(updateUserOnboardStatus, params);
    if (!data && status !== 200) throw apiError(error);
    const userPage = userType.USER_PAGE;
    yield put(
      userAction.updateUserOnboardStatusSuccess({
        userPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "User Onboard  Status updation Failed" };
    yield put(userAction.updateUserOnboardStatusFailure(data));
  }
}

export function* NonPrivilegeuserListSaga(paramsData) {
  console.debug("NonPrivilegeuserListSaga");
  try {
    const { data, status, error } = yield call(getNonPrivilegeList, paramsData);
    if (!data && status !== 200) throw apiError(error);
    const userPage = userType.USER_PAGE;
    if (paramsData.data.isSendMail) {
      yield put(userAction.getNonPrivilegeListEmailSuccess());
    } else {
      yield put(
        userAction.getNonPrivilegeListSuccess({
          userPage,
          data,
        })
      );
    }
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Non Privilege User List Failed" };
    yield put(userAction.getNonPrivilegeListFailure(data));
  }
}

function* deleteNonPrivilegeuserSaga(payload) {
  console.debug("deleteNonPrivilegeuserSaga");
  try {
    const { data, status, error } = yield call(
      deleteNonPrivilegeUserList,
      payload
    );
    if (!data && status !== 200) throw apiError(error);
    const userPage = userType.USER_PAGE;
    yield put(
      userAction.deleteNonPrivilegeListSuccess({
        userPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Delete Non Privilege User list" };
    yield put(userAction.deleteNonPrivilegeListFailure(data));
  }
}

export function* addNonPrivilegeuserListSaga(payload) {
  console.debug("addNonPrivilegeuserListSaga");
  try {
    const { data, status, error } = yield call(
      addNonPrivilegeUserList,
      payload
    );
    if (!data && status !== 200) throw apiError(error);
    const userPage = userType.USER_PAGE;
    yield put(
      userAction.addNonPrivilegeListSuccess({
        userPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Non Privilege User Details Failed" };
    yield put(userAction.addNonPrivilegeListFailure(data));
  }
}

export function* NonPrivilegeuserListDetailsSaga(paramsData) {
  console.debug("NonPrivilegeuserListDetailsSaga");
  try {
    const { data, status, error } = yield call(
      getNonPrivilegeUserDetails,
      paramsData
    );
    if (!data && status !== 200) throw apiError(error);
    const userPage = userType.USER_PAGE;
    yield put(
      userAction.getNonPrivilegeListDetsilsSuccess({
        userPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Non Privilege User List Details Failed" };
    yield put(userAction.getNonPrivilegeListDetsilsFailure(data));
  }
}

export function* updateNonPrivelegeUserListSaga(params) {
  console.debug("updateNonPrivelegeUserListSaga");
  try {
    const { data, status, error } = yield call(
      updateNonPrivilegeUserList,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const userPage = userType.USER_PAGE;
    yield put(
      userAction.updateNonPrivilegeListSuccess({
        userPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Non Privilege User List updation Failed" };
    yield put(userAction.updateNonPrivilegeListFailure(data));
  }
}

export function* convertNonPrivelegeUserListSaga(params) {
  console.debug("convertNonPrivelegeUserListSaga");
  try {
    const { data, status, error } = yield call(
      convertNonPrivilegeUserList,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const userPage = userType.USER_PAGE;
    yield put(
      userAction.convertNonPrivilegeListSuccess({
        userPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : {
            message:
              error || " Convert Non Privilege User List updation Failed",
          };
    yield put(userAction.convertNonPrivilegeListFailure(data));
  }
}

export function* recreateUsersSaga(params) {
  console.debug("recreateUsersSaga");
  try {
    const { data, status, error } = yield call(recreateUsers, params);
    if (!data && status !== 200) throw apiError(error);
    const userPage = userType.USER_PAGE;
    yield put(
      userAction.recreateUsersSuccess({
        userPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Post Recreate Users Data Failed" };
    yield put(userAction.recreateUsersFailure(data));
  }
}

export function* getAssetOnUserSaga(paramsData) {
  console.debug("getAssetOnUserSaga");
  try {
    const { data, status, error } = yield call(getAssetOnUser, paramsData);
    if (!data && status !== 200) throw apiError(error);
    const userPage = userType.USER_PAGE;
    yield put(
      userAction.getAssetOnUserSuccess({
        userPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get getAssetOnUser Failed" };
    yield put(userAction.getAssetOnUserFailure(data));
  }
}

export function* getSupervisorOnAssetSaga(paramsData) {
  console.debug("getSupervisorOnAssetSaga");
  try {
    const { data, status, error } = yield call(
      getSupervisorOnAsset,
      paramsData
    );
    if (!data && status !== 200) throw apiError(error);
    const userPage = userType.USER_PAGE;
    yield put(
      userAction.getSupervisorOnAssetSuccess({
        userPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get getSupervisorOnAsset Failed" };
    yield put(userAction.getSupervisorOnAssetFailure(data));
  }
}

export function* checkUserInfo(payload) {
  console.debug("checkUserInfo");
  try {
    const { data, status, error } = yield call(checkUserInfos, payload);
    if (!data && status !== 200) throw apiError(error);
    const userPage = userType.USER_PAGE;
    yield put(
      userAction.checkUserInfoSuccess({
        userPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get check User Info Details Failed" };
    yield put(userAction.checkUserInfoFailure(data));
  }
}

export function* userListByRoleSaga(paramsData) {
  console.debug("userListByRoleSaga");
  try {
    const { data, status, error } = yield call(getUserByRoleList, paramsData);
    if (!data && status !== 200) throw apiError(error);
    const userPage = userType.USER_PAGE;
    if (paramsData.data.isSendMail) {
      yield put(userAction.getUserByRoleEmailSuccess());
    } else {
      yield put(
        userAction.getUserByRoleSuccess({
          userPage,
          data,
        })
      );
    }
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get User by role List Data Failed" };
    yield put(userAction.getUserByRoleFailure(data));
  }
}

export function* getSyncWithProcoreUserListSaga(paramsData) {
  console.debug("getSyncWithProcoreUserListSaga");
  try {
    const { data, status, error } = yield call(
      getSyncWithProcoreUserList,
      paramsData
    );
    if (!data && status !== 200) throw apiError(error);
    const userPage = userType.USER_PAGE;
    yield put(
      userAction.getSyncProcoreUserListSuccess({
        userPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Sync Procore User Failed" };
    yield put(userAction.getSyncProcoreUserListFailure(data));
  }
}

const myUserSaga = [
  takeLatest(userType.GET_USER, userDetails),
  takeLatest(userType.GET_SEARCH_USER, searchUsersSaga),
  takeLatest(userType.GET_ALL_USER, allUsersSaga),
  takeLatest(userType.GET_USER_LIST, userListSaga),
  takeLatest(userType.GET_USER_GROUP_LIST, userGroupListSaga),
  takeLatest(userType.POST_USER_LIST, addUser),
  takeLatest(userType.POST_USER_GROUP_LIST, addGroupUser),
  takeLatest(userType.GET_USER_LIST_DETAILS, userListDetailsSaga),
  takeLatest(userType.UPDATE_USER_LIST, updateUserListSaga),
  takeLatest(userType.DELETE_USER_LIST, deleteListSaga),
  takeLatest(userType.GET_USER_GROUP_DETAILS, getUserGroupDetailsSaga),
  takeLatest(userType.UPDATE_USER_GROUP, updateUserGroupSaga),
  takeLatest(userType.DELETE_USER_GROUP, deleteUserGroupSaga),
  takeLatest(userType.LEAVE_GROUP, leaveGroupSaga),
  takeLatest(userType.GET_SEARCH_GROUP, searchAllGroupSaga),
  takeLatest(
    userType.GET_USER_INVOLVED_GROUP_LIST,
    individualUserGroupListSaga
  ),
  takeLatest(userType.GET_USER_CONFIGURATION_LIST, userConfigurationListSaga),
  takeLatest(
    userType.UPDATE_USER_CONFIGURATION_LIST,
    updateUserConfigurationListSaga
  ),
  takeLatest(
    userType.GET_USER_CONFIGURATION_DETAILS,
    userConfigurationDetailsSaga
  ),
  takeLatest(userType.UPDATE_USER_ONBOARD, updateUserOnboardSaga),
  takeLatest(userType.GET_NON_PRIVILEGE_USER_LIST, NonPrivilegeuserListSaga),
  takeLatest(
    userType.DELETE_NON_PRIVILEGE_USER_LIST,
    deleteNonPrivilegeuserSaga
  ),
  takeLatest(
    userType.POST_NON_PRIVILEGE_USER_LIST,
    addNonPrivilegeuserListSaga
  ),
  takeLatest(
    userType.GET_NON_PRIVILEGE_USER_LIST_DETAILS,
    NonPrivilegeuserListDetailsSaga
  ),
  takeLatest(
    userType.UPDATE_NON_PRIVILEGE_USER_LIST,
    updateNonPrivelegeUserListSaga
  ),
  takeLatest(userType.UPDATE_USER_ONBOARD_STATUS, updateUserOnboardStatusSaga),
  takeLatest(
    userType.CONVERT_NON_PRIVILEGE_USER_LIST,
    convertNonPrivelegeUserListSaga
  ),
  takeLatest(userType.POST_RECREATE_USERS, recreateUsersSaga),
  takeLatest(userType.GET_ASSET_ON_USER, getAssetOnUserSaga),
  takeLatest(userType.GET_SUPERVISOR_ON_ASSET, getSupervisorOnAssetSaga),
  takeLatest(userType.CHECK_USER_INFO, checkUserInfo),
  takeLatest(userType.GET_USER_BY_ROLE_LIST, userListByRoleSaga),
  takeLatest(userType.GET_SYNC_PROCORE_USER, getSyncWithProcoreUserListSaga),
];
export default myUserSaga;
