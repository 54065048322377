import * as coursesAction from "../actions/lmsAction";
import { lmsType } from "../constants/lmsType";
import {
  getCoursesList,
  getCategoryList,
  createCourse,
  updateCourseList,
  getlessonsDropDownList,
  getCourseDetails,
  deleteCourse,
  publishCoursess,
  getCourseAssignedUserList,
  assignCourses,
  unAssignCourse,
  getCompletedcourses,
  unEnrollCourse,
  searchCourses,
  getMappedGlobalCourseList,
  specifyCourseToUserViewClient,
  specifyCourseToUserViewCourse,
} from "../../services/coursesService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

export function* createCourseSaga(param) {
  const payload = param.data;

  try {
    const { data, status, error } = yield call(createCourse, payload);
    if (!data && status !== 200) throw apiError(error);
    const casePage = lmsType.CREATE_PAGE;
    yield put(
      coursesAction.createCoursesSuccess({
        casePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create Case Failed" };
    yield put(coursesAction.createCoursesFailure(data));
  }
}

export function* updateCourseListSaga(param) {
  const payload = param.payload;
  try {
    const { data, status, error } = yield call(updateCourseList, payload);

    if (!data && status !== 200) throw apiError(error);
    const updatePage = lmsType.UPDATE_PAGE;
    yield put(
      coursesAction.updateCoursesSuccess({
        updatePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "update Case Failed" };
    yield put(coursesAction.updateCoursesFailure(data));
  }
}

export function* getCoursesListSaga(params) {
  try {
    const { data, status, error } = yield call(getCoursesList, params);
    if (!data && status !== 200) throw apiError(error);
    const coursesPage = lmsType.COURSES_PAGE;
    yield put(
      coursesAction.getCoursesListSuccess({
        coursesPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Course List Failed" };
    yield put(coursesAction.getCoursesListFailure(data));
  }
}
export function* getCategoryListSaga(params) {
  try {
    const { data, status, error } = yield call(getCategoryList, params);
    if (!data && status !== 200) throw apiError(error);
    const categoryPage = lmsType.CATEGORY_PAGE;
    yield put(
      coursesAction.getCategoryListSuccess({
        categoryPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Course List Failed" };
    yield put(coursesAction.getCategoryListFailure(data));
  }
}

export function* getlessonDropDownListSaga(params) {
  try {
    const { data, status, error } = yield call(getlessonsDropDownList, params);

    if (!data && status !== 200) throw apiError(error);
    const categoryPage = lmsType.LESSON_DROP_DOWN_PAGE;
    yield put(
      coursesAction.getLessonDropDownListSuccess({
        categoryPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Course List Failed" };
    yield put(coursesAction.getCategoryListFailure(data));
  }
}

// get details
export function* getCourseDetailsSaga(payload) {
  try {
    const { data, status, error } = yield call(getCourseDetails, payload);
    let iscourseDtaidata = data?.result?.courseDetails;
    iscourseDtaidata["lessons"] = data?.result?.lessonList;

    if (!data && status !== 200) throw apiError(error);
    //const { iscourseDtaidata } = data;
    yield put({
      type: lmsType.GET_COURSE_DETAILS_SUCCESS,
      payload: iscourseDtaidata,
    });
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Reprimand List Failed" };
    yield put({
      type: lmsType.GET_COURSE_DETAILS_FAILED,
      error: data,
    });
  }
}

function* deleteCourseSaga(payload) {
  try {
    const { data, status } = yield call(deleteCourse, payload);
    const coursePage = lmsType.COURSES_PAGE;

    yield put(
      coursesAction.deleteCourseSuccess({
        coursePage,
        data,
        status,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Course List Failed" };
    yield put(coursesAction.deleteCourseFailure(data));
  }
}

export function* publishCourseSaga(param) {
  const payload = param.data;
  try {
    const { data, status, error } = yield call(publishCoursess, payload);
    if (!data && status !== 200) throw apiError(error);
    const publishCourse = lmsType.publishCourse;
    yield put(
      coursesAction.publishCourseSuccess({
        publishCourse,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Publish Course Failed" };
    yield put(coursesAction.publishCourseFailure(data));
  }
}
export function* getAssignUserListSaga(params) {
  try {
    const { data, status, error } = yield call(
      getCourseAssignedUserList,
      params
    );

    const dataresult = data?.result;
    if (!data && status !== 200) throw apiError(error);
    const assignPage = lmsType.ASSIGN_PAGE;

    yield put(
      coursesAction.getAssignedListSuccess({
        assignPage,
        dataresult,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Assign List Failed" };
    yield put(coursesAction.getAssignedListFailure(data));
  }
}

export function* assignCourseSaga(param) {
  const payload = param.data;
  try {
    const { data, status, error } = yield call(assignCourses, payload);
    if (!data && status !== 200) throw apiError(error);
    const assignCourse = lmsType.POST_ASSIGN_COURSE_PENDING;
    yield put(
      coursesAction.assignCourseSuccess({
        assignCourse,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Assign Course Failed" };
    yield put(coursesAction.assignCourseFailure(data));
  }
}
// unassign Course
export function* unAssignCourseSaga(param) {
  const payload = param.data;

  try {
    const { data, status, error } = yield call(unAssignCourse, payload);
    if (!data && status !== 200) throw apiError(error);
    const unAssignCoursePage = lmsType.CREATE_PAGE_COURSEUNASSIGN;
    yield put(
      coursesAction.unAssignCourseSuccess({
        unAssignCoursePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create UnAssignCourse Failed" };
    yield put(coursesAction.unAssignCourseFailure(data));
  }
}

export function* getCompletedCourseStatuscheckSaga(params) {
  try {
    const { data, status, error } = yield call(getCompletedcourses, params);

    const dataresult = data?.result;
    if (!data && status !== 200) throw apiError(error);
    const assignPage = lmsType.COURSES_COMPLETED_STATUS_PAGE;

    yield put(
      coursesAction.getCompletedCourseStatuscheckSuccess({
        assignPage,
        dataresult,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get completed course status List Failed" };
    yield put(coursesAction.getCompletedCourseStatuscheckFailure(data));
  }
}
export function* unEnrollCourseSaga(param) {
  const payload = param.data;

  try {
    const { data, status, error } = yield call(unEnrollCourse, payload);
    if (!data && status !== 200) throw apiError(error);
    const unEnrollCoursePage = lmsType.CREATE_PAGE_COURSEUNENROLL;
    yield put(
      coursesAction.unEnrollCourseSuccess({
        unEnrollCoursePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create UnEnrollCourse Failed" };
    yield put(coursesAction.unEnrollCourseFailure(data));
  }
}
export function* searchCoursesSaga({ searchText }) {
  console.debug("searchUsersSaga");
  try {
    const {
      data: result,
      status,
      error,
    } = yield call(searchCourses, searchText);
    if (!result && status !== 200) throw apiError(error);
    const data = {
      responseMessages: {
        responseCode: "HBNG001",
      },
      users: result,
    };
    const searchCoursesPage = lmsType.CREATE_PAGE_SEARCH_COURSES;
    yield put(
      coursesAction.searchCoursesSuccess({
        searchCoursesPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Search Lessons Failed" };
    yield put(coursesAction.searchCoursesFailure(data));
  }
}

export function* getmappingCourseWithClientSaga(params) {
  try {
    const { data, status, error } = yield call(
      getMappedGlobalCourseList,
      params
    );
    console.log("himanshu saga", data);
    // const dataresult = data;
    if (!data && status !== 200) throw apiError(error);
    const assignPage = lmsType.CREATE_PAGE_MAPPED_GLOBAL_COURSE;

    yield put(
      coursesAction.getMappedGlobalCourseListSuccess({
        assignPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Mapped Course status List Failed" };
    yield put(coursesAction.getMappedGlobalCourseListFailure(data));
  }
}

export function* postspecifyCourseToUserViewClientSaga(param) {
  const payload = param.data;
  const clientId = param.clientId;

  try {
    const { data, status, error } = yield call(
      specifyCourseToUserViewClient,
      payload,
      clientId
    );
    if (!data && status !== 200) throw apiError(error);
    const casePage = lmsType.CREATE_PAGE_POST_SPECIFY_COURSE_TOUSER_VIEW_CLIENT;
    yield put(
      coursesAction.specifyCourseToUserViewClientSuccess({
        casePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create CourseToUserViewClient Failed" };
    yield put(coursesAction.specifyCourseToUserViewClientFailure(data));
  }
}
export function* postspecifyCourseToUserViewCourseSaga(param) {
  const payload = param.data;
  const courseId = param.courseId;

  try {
    const { data, status, error } = yield call(
      specifyCourseToUserViewCourse,
      payload,
      courseId
    );
    if (!data && status !== 200) throw apiError(error);
    const casePage = lmsType.CREATE_PAGE_POST_SPECIFY_COURSE_TOUSER_VIEW_COURSE;
    yield put(
      coursesAction.specifyCourseToUserViewCourseSuccess({
        casePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create CourseToUserViewCourse Failed" };
    yield put(coursesAction.specifyCourseToUserViewCourseFailure(data));
  }
}

//GET_SEARCH_COURSES
const myCaseSaga = [
  takeLatest(lmsType.GET_COURSES_LIST_PENDING, getCoursesListSaga),
  takeLatest(lmsType.GET_CATEGORY_LIST_PENDING, getCategoryListSaga),
  takeLatest(lmsType.POST_COURSES_PENDING, createCourseSaga),
  takeLatest(lmsType.UPDATE_COURSES_PENDING, updateCourseListSaga),
  takeLatest(
    lmsType.GET_LESSON_DROP_DOWN_LIST_PENDING,
    getlessonDropDownListSaga
  ),
  takeLatest(lmsType.GET_COURSE_DETAILS_PENDING, getCourseDetailsSaga),
  takeLatest(lmsType.DELETE_COURSE_PENDING, deleteCourseSaga),
  takeLatest(lmsType.POST_PUBLISH_COURSES_PENDING, publishCourseSaga),
  takeLatest(lmsType.GET_COURSE_ASSIGN_LIST_PENDING, getAssignUserListSaga),
  takeLatest(lmsType.POST_ASSIGN_COURSE_PENDING, assignCourseSaga),
  takeLatest(lmsType.POST_COURSE_UNASSIGN_PENDING, unAssignCourseSaga),
  takeLatest(
    lmsType.GET_COURSES_COMPLETED_STATUS_PENDING,
    getCompletedCourseStatuscheckSaga
  ),
  takeLatest(lmsType.POST_COURSE_UNENROLL_PENDING, unEnrollCourseSaga),
  takeLatest(lmsType.GET_SEARCH_COURSES, searchCoursesSaga),
  takeLatest(
    lmsType.MAPPED_GLOBAL_COURSE_PENDING,
    getmappingCourseWithClientSaga
  ),
  takeLatest(
    lmsType.POST_SPECIFY_COURSE_TOUSER_VIEW_CLIENT_PENDING,
    postspecifyCourseToUserViewClientSaga
  ),
  takeLatest(
    lmsType.POST_SPECIFY_COURSE_TOUSER_VIEW_COURSE_PENDING,
    postspecifyCourseToUserViewCourseSaga
  ),
];

export default myCaseSaga;
