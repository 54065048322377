import React, { useEffect, useState } from "react";
import UserConfigurationList from "../component/users/userConfigurationList";
import { useDispatch, useSelector } from "react-redux";
import * as userAction from "../../../redux/actions/userAction";
import { API_STATUS, ORG_ID, PAGE_SIZE } from "../../../constant/constant";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../library/common/Loader";
import { Navigate, useNavigate } from "react-router-dom";
import { ACCESS_DENIED } from "../../../constant/routeContant";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { PRODUCT_ID } from "../../../constant/storm-water/constant";
import { selectOnboardUsersStatus } from "../../../redux/selectors/adminSelector";

function UserConfigurationListContainer() {
  const dispatch = useDispatch();
  const [isSuccess, setIsSuccess] = useState(false);
  const [openGrandAccess, setOpenGrandAccess] = useState(false);
  const [openGrandResetAccess, setOpenGrandResetAccess] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);

  const updateUserConfigurationData = useSelector(
    (state) => state.updateUserConfigurationData
  );
  const updateUserConfigurationDataStatus = useSelector(
    (state) => state.updateUserConfigurationData.status
  );
  const onboardUsersStatus = useSelector(selectOnboardUsersStatus);
  const isLoading = updateUserConfigurationData.isLoading;

  const userConfigurationListData = useSelector(
    (state) => state.userConfigurationData
  );
  const recreateUsersReducer = useSelector((state) => state.recreateUsersData);
  const recreateUsersReducerstatus = useSelector(
    (state) => state.recreateUsersData.status
  );

  const showLoader = recreateUsersReducer.isLoading;

  const getuserConfigurationLists = (data) => {
    if (data?.isSendMail) {
      setSendEmail(true);
    } else {
      setSendEmail(false);
    }

    dispatch(userAction.getUserConfigurationList(data));
  };
  const defaultData = {
    page: 1,
    search: "",
    pageSize: PAGE_SIZE,
    Status: "",
    isSendMail: false,
  };

  const dropdownDataKeyValueParams = {
    orgId: ORG_ID(),
    flags: "21",
    productId: PRODUCT_ID(),
  };

  useEffect(() => {
    setIsSuccess(false);
    dispatch(adminAction.getDropdownDataKeyValue(dropdownDataKeyValueParams));
    getuserConfigurationLists(defaultData);
  }, []);

  useEffect(() => {
    if (
      updateUserConfigurationDataStatus === API_STATUS.SUCCESS &&
      updateUserConfigurationData?.isLoading === false
    ) {
      setOpenGrandAccess(false);
      setOpenGrandResetAccess(false);
      setIsSuccess(false);
      setTimeout(() => {
        window.location.reload();
      }, 3800);
    }
  }, [updateUserConfigurationDataStatus]);

  useEffect(() => {
    if (
      recreateUsersReducerstatus === API_STATUS.SUCCESS &&
      recreateUsersReducer?.isLoading === false
    ) {
      setIsSuccess(false);
      setTimeout(() => {
        window.location.reload();
      }, 3800);
    }
  }, [recreateUsersReducerstatus]);

  const updateUserConfiguration = (data) => {
    setIsSuccess(true);
    if (data.userIds.length == 0) {
      toast.error("Please Select User");
      return false;
    }

    dispatch(userAction.updateUserConfigurationList(data));
  };

  const recreateUsersList = (data) => {
    setIsSuccess(true);
    dispatch(userAction.recreateUsers(data));
  };

  useEffect(() => {
    if (userConfigurationListData?.status === API_STATUS.SUCCESS && sendEmail) {
      toast("User configuration has been sent to registered email ID");
    }
  }, [userConfigurationListData]);

  return (
    <>
      {checkPrivileges([12, 64, 65, 66]) ? (
        <>
          <ToastContainer />
          {showLoader && <Loader />}
          {isLoading && <Loader />}
          {updateUserConfigurationDataStatus === API_STATUS.SUCCESS &&
            updateUserConfigurationData?.data &&
            isSuccess &&
            toast("User Added Successfully")}
          {recreateUsersReducerstatus === API_STATUS.SUCCESS &&
            isSuccess &&
            toast("Welcome Email Send Successfully")}
          <div>
            <ToastContainer />
          </div>
          <UserConfigurationList
            userConfigurationListData={userConfigurationListData?.data}
            loading={userConfigurationListData.isLoading}
            getuserConfigurationLists={getuserConfigurationLists}
            updateUserConfiguration={updateUserConfiguration}
            recreateUsersList={recreateUsersList}
            setOpenGrandAccess={setOpenGrandAccess}
            openGrandAccess={openGrandAccess}
            openGrandResetAccess={openGrandResetAccess}
            setOpenGrandResetAccess={setOpenGrandResetAccess}
            onboardUsersStatus={onboardUsersStatus}
          />
        </>
      ) : (
        Navigate(ACCESS_DENIED)
      )}
    </>
  );
}

export default UserConfigurationListContainer;
