import { API_STATUS } from "../../constant/constant";
import { incpectionType } from "../constants/incpectionType";
const initialState = {
  isError: false,
  isLoading: false,
  isInLoading: false,
  isEmailSuccess: false,
  error: "",
  quesSet: [],
  signoffMethod: null,
  certifyStatementId: null,
  weather: [],
  quesSetDetails: [],
  dynamicFeild: [],
  msg: "",
  inpsViewList: [],
  isSuccess: false,
  successData: {},
  inspEditDetails: {},
  isInUpdateError: false,
  updateMsgError: "",
};
export const inspectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case incpectionType.GET_QUESTION_SET_PENDING:
      return {
        ...state,
        msg: "",
        isSuccess: false,
        isLoading: true,
      };
    case incpectionType.GET_QUESTION_SET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        error: "",
        quesSet: action.data,
      };
    case incpectionType.GET_QUESTION_SET_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.error,
      };

    // weather
    case incpectionType.GET_WEATHER_REF_PENDING:
      return {
        ...state,
        msg: "",
        isInLoading: true,
      };
    case incpectionType.GET_WEATHER_REF_SUCCESS:
      return {
        ...state,
        isInLoading: false,
        isInError: false,
        error: "",
        weather: action.data,
      };
    case incpectionType.GET_WEATHER_REF_FAILED:
      return {
        ...state,
        isInLoading: false,
        isInError: true,
        error: action.error,
      };

    // GET_QUEST_DYNAMIC_
    case incpectionType.GET_QUEST_DYNAMIC_FIELD_PENDING:
      return {
        ...state,
        msg: "",
        isSuccess: false,
        isInLoading: true,
      };
    case incpectionType.GET_QUEST_DYNAMIC_FIELD_SUCCESS:
      return {
        ...state,
        isInLoading: false,
        isInError: false,
        error: "",
        quesSetDetails: action.payload,
        dynamicFeild: action.dynamicFeild,
        signoffMethod: action.signoffMethod,
        certifyStatementId: action.certifyStatementId,
      };

    case incpectionType.REVOKE_DATA_UNMOUNT:
      return {
        ...state,
        msg: "",
        isSuccess: false,
        quesSetDetails: [],
        inspEditDetails: {},
        successData: {},
      };
    //post data
    // GET_QUEST_DYNAMIC_
    case incpectionType.POST_INSPECTION_PENDING:
      return {
        ...state,
        msg: "",
        isInLoading: true,
        isSuccess: false,
      };
    case incpectionType.POST_INSPECTION_SUCCESS:
      return {
        ...state,
        isInLoading: false,
        error: "",
        isSuccess: true,
        msg: action.payload,
        successData: action.successData,
      };
    case incpectionType.POST_INSPECTION_FAILED:
      return {
        ...state,
        isInLoading: false,
        msg: "",
        isSuccess: false,
        isInError: true,
        error: action.error,
      };
    // update

    case incpectionType.UPDATE_INSPECTION_SUCCESS:
      return {
        ...state,
        isInLoading: false,
        updateMsgError: "",
        isSuccess: true,
        successData: action.successData,
        msg: action.payload,
      };

    default:
      return state;
  }
};

export const inspectionViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case incpectionType.GET_INSPECTION_VIEW_LIST_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case incpectionType.GET_INSPECTION_VIEW_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isEmailSuccess: false,
        error: "",
        inpsViewList: action.payload,
      };
    case incpectionType.GET_INSPECTION_VIEW_LIST_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isEmailSuccess: true,
        error: "",
      };
    case incpectionType.GET_INSPECTION_VIEW_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    default:
      return state;
  }
};

export const inspectionDeleteReducer = (state = initialState, action) => {
  switch (action.type) {
    case incpectionType.DEELETE_INSPECTION_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incpectionType.DEELETE_INSPECTION_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incpectionType.DEELETE_INSPECTION_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const inspectionDetailView = (state = initialState, action) => {
  switch (action.type) {
    case incpectionType.GET_INSPECTION_DETAILS_PENDING:
      return {
        ...state,
        msg: "",
        isInLoading: true,
      };
    case incpectionType.GET_INSPECTION_DETAILS_SUCCESS:
      return {
        ...state,
        msg: "",
        isInLoading: false,
        isSuccess: true,
        inspEditDetails: action.payload,
      };
    case incpectionType.GET_INSPECTION_DETAILS_FAILED:
      return {
        ...state,
        msg: "",
        isInLoading: false,
        isSuccess: false,
        isInUpdateError: true,
        updateMsgError: action.error,
      };
    default:
      return state;
  }
};

export const adminAddUpdateSubcategoryReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case incpectionType.POST_SUBCATEGORY_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incpectionType.POST_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incpectionType.POST_SUBCATEGORY_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case incpectionType.CLEAR_SUBCATEGORY:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const adminAddUpdateCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case incpectionType.POST_CATEGORY_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incpectionType.POST_CATEGORY_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incpectionType.POST_CATEGORY_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case incpectionType.CLEAR_CATEGORY:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const addUpdateQuestionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case incpectionType.POST_ADDUPDATEQUESTIONS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incpectionType.POST_ADDUPDATEQUESTIONS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incpectionType.POST_ADDUPDATEQUESTIONS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case incpectionType.CLEAR_QUESTIONS:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const adminUpdateInspectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case incpectionType.POST_ADMINUPDATEINSPECTION_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incpectionType.POST_ADMINUPDATEINSPECTION_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incpectionType.POST_ADMINUPDATEINSPECTION_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case incpectionType.CLEAR_ADMINUPDATEINSPECTION:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const adminAddUpdateInspectionReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case incpectionType.POST_ADMINADDUPDATEINSPECTION_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incpectionType.POST_ADMINADDUPDATEINSPECTION_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incpectionType.POST_ADMINADDUPDATEINSPECTION_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case incpectionType.CLEAR_ADMINADDUPDATEINSPECTION:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const deleteQuestionReducer = (state = initialState, action) => {
  switch (action.type) {
    case incpectionType.DELETE_QUESTIONS:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incpectionType.DELETE_QUESTIONS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incpectionType.DELETE_QUESTIONS_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getCategoriesListReducer = (state = initialState, action) => {
  switch (action.type) {
    case incpectionType.GET_CATEGORIES_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incpectionType.GET_CATEGORIES_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incpectionType.GET_CATEGORIES_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getSubCategoriesListReducer = (state = initialState, action) => {
  switch (action.type) {
    case incpectionType.GET_SUB_CATEGORIES_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incpectionType.GET_SUB_CATEGORIES_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incpectionType.GET_SUB_CATEGORIES_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getQuestionsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case incpectionType.GET_QUESTIONS_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incpectionType.GET_QUESTIONS_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incpectionType.GET_QUESTIONS_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getQuestionSetListReducer = (state = initialState, action) => {
  switch (action.type) {
    case incpectionType.GET_QUESTIONS_SET_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incpectionType.GET_QUESTIONS_SET_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incpectionType.GET_QUESTIONS_SET_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getAssetListReducer = (state = initialState, action) => {
  switch (action.type) {
    case incpectionType.GET_ASSET_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incpectionType.GET_ASSET_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incpectionType.GET_ASSET_LIST_EMAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        isError: false,
        isLoading: false,
      };
    case incpectionType.GET_ASSET_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const cloneQuestionSetReducer = (state = initialState, action) => {
  switch (action.type) {
    case incpectionType.CLONE_QUESTION_SET:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incpectionType.CLONE_QUESTION_SET_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incpectionType.CLONE_QUESTION_SET_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case incpectionType.CLEAR_CLONE_QUESTION_SET:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const qestSetDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case incpectionType.QEST_SET_DETAIL:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incpectionType.QEST_SET_DETAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incpectionType.QEST_SET_DETAIL_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const getInspectionOpenItemDetailReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case incpectionType.GET_INSPECTION_OPENITEM_DETAIL:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incpectionType.GET_INSPECTION_OPENITEM_DETAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incpectionType.GET_INSPECTION_OPENITEM_DETAIL_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const closeInspectionOpenItemReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case incpectionType.UPDATE_INSPECTION_OPENITEM:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incpectionType.UPDATE_INSPECTION_OPENITEM_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incpectionType.UPDATE_INSPECTION_OPENITEM_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const getInspectionViolationCountsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case incpectionType.GET_INSPECTION_VIOLATION_COUNTS:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incpectionType.GET_INSPECTION_VIOLATION_COUNTS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incpectionType.GET_INSPECTION_VIOLATION_COUNTS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getItemListReducer = (state = initialState, action) => {
  switch (action.type) {
    case incpectionType.GET_ITEM_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incpectionType.GET_ITEM_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incpectionType.GET_ITEM_LIST_EMAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        isError: false,
        isLoading: false,
      };
    case incpectionType.GET_ITEM_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updateReprimandToClose = (state = initialState, action) => {
  switch (action.type) {
    case incpectionType.POST_REPRIMAND_TO_CLOSE:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incpectionType.POST_REPRIMAND_TO_CLOSE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incpectionType.POST_REPRIMAND_TO_CLOSE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getInspectionAcknowledgementReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case incpectionType.GET_INSPECTION_ACKNOWLEDGEMENT:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incpectionType.GET_INSPECTION_ACKNOWLEDGEMENT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incpectionType.GET_INSPECTION_ACKNOWLEDGEMENT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updateInspectionAcknowledgementReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case incpectionType.POST_INSPECTION_ACKNOWLEDGEMENT_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incpectionType.POST_INSPECTION_ACKNOWLEDGEMENT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incpectionType.POST_INSPECTION_ACKNOWLEDGEMENT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case incpectionType.CLEAR_INSPECTION_ACKNOWLEDGEMENT:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getExportItemListReducer = (state = initialState, action) => {
  switch (action.type) {
    case incpectionType.GET_EXPORT_ITEM_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incpectionType.GET_EXPORT_ITEM_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incpectionType.GET_EXPORT_ITEM_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const inspectionFieldsMapReducer = (state = initialState, action) => {
  switch (action.type) {
    case incpectionType.GET_INSPECTION_FIELDS_BY_QUESTIONSET:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incpectionType.GET_INSPECTION_FIELDS_BY_QUESTIONSET_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incpectionType.GET_INSPECTION_FIELDS_BY_QUESTIONSET_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updateInspectionFieldsMapReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case incpectionType.POST_INSPECTION_FIELDS_MAP_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incpectionType.POST_INSPECTION_FIELDS_MAP_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incpectionType.POST_INSPECTION_FIELDS_MAP_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case incpectionType.CLEAR_INSPECTION_FIELDS_MAP:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const inspectionReportAssetDetailsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case incpectionType.GET_INSPECTION_REPORT_ASSET_DETAILS:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incpectionType.GET_INSPECTION_REPORT_ASSET_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incpectionType.GET_INSPECTION_REPORT_ASSET_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getInspecionReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case incpectionType.GET_INSPECTION_REPORT:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incpectionType.GET_INSPECTION_REPORT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incpectionType.GET_INSPECTION_REPORT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getOpenItemPhotosReducer = (state = initialState, action) => {
  switch (action.type) {
    case incpectionType.GET_OPEN_ITEM_PHOTOS:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incpectionType.GET_OPEN_ITEM_PHOTOS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incpectionType.GET_OPEN_ITEM_PHOTOS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};


export const getInspectionOpenItemCountReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case incpectionType.GET_INSPECTION_OPEN_ITEM_COUNT_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incpectionType.GET_INSPECTION_OPEN_ITEM_COUNT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incpectionType.GET_INSPECTION_OPEN_ITEM_COUNT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
