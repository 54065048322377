import React, { useState, useEffect } from "react";
import { Button, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import "./commonObservationReport.scss";
import TextArea from "../../../library/custom/textArea/TextArea";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import BackBtn from "../../../library/custom/backBtn/backBtn";
import {
  VIEW_OBSERVATION_LISTS,
  OPEN_ITEMS_LIST,
  INSPECTION_REPORT,
} from "../../../constant/routeContant";
import ViewImages from "../../incidents/view-incident-report/common/viewImages/viewImages";
import {
  ORG_ID,
  GET_COUNT_TYPE,
  USER_ID,
} from "../../../constant/constant";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import { RemoveRedEye } from "@mui/icons-material";
import FormDropZone from "../../../library/dropZone/FormDropZone";

const ViolationObservationReport = ({
  observationReportData,
  InspectionOpenItemDetail,
  closeViolation,
  observationID,
  pageTitle,
  origin,
  closeInspectionCorrective,
  inspectionViolationCounts,
  getCount,
  observationType,
  involvedPartyId,
  observationStatus,
  backRouteText,
  backRoute,
  addedBy,
  statusReport,
}) => {
  const imageSize = {
    size: "small",
  };
  const [isInsCommet, setIsInsComment] = useState(false);
  const [isReprimandCommet, setIsReprimandComment] = useState(false);
  const [closesFilesData, setCloseFilesData] = useState("");
  const [closesFilesData1, setCloseFilesData1] = useState("");
  const data1 = observationReportData?.insObservationDetails;

  const involvedUserData = InspectionOpenItemDetail?.listInvolvedParty?.filter(
    (item) => parseInt(item?.userId) === parseInt(involvedPartyId)
  );

  const userData = involvedUserData?.find(
    (item) => parseInt(item?.userId) === parseInt(involvedPartyId)
  );

  const insCloseMedia = userData?.closedFiles
    ? userData?.closedFiles[0]?.filePath?.split("|")
    : "";

  const image = observationReportData?.files
    ? observationReportData?.files[0]?.filePath?.split("|")
    : "";

  const correctiveImage = observationReportData?.closedFiles
    ? observationReportData?.closedFiles[0]?.filePath?.split("|")
    : "";

  const [formValue, setFormValue] = useState({
    insObservationId: data1?.insObservationId,
    assignCorrectiveMeasures: "",
    escalateToReprimand: false,
    correctiveMeasure: "",
  });

  function mappedData() {
    let errors = false;
    if (formValue?.assignCorrectiveMeasures?.length === 0) {
      setIsInsComment(true);
      errors = true;
    }

    if (errors) {
      return false;
    }
    const data = {
      insObservationId: data1?.insObservationId,
      assignCorrectiveMeasures: formValue.assignCorrectiveMeasures,
      escalateToReprimand: formValue.escalateToReprimand,
      status: 3,
      organisationId: ORG_ID(),
      files: [closesFilesData1?.fileId ? closesFilesData1?.fileId : 0],
    };
    return data;
  }

  function mapData() {
    let errors = false;

    if (formValue?.correctiveMeasure?.length === 0) {
      setIsReprimandComment(true);
      errors = true;
    }

    if (errors) {
      return false;
    }

    const closeInspectionCorrectiveData = {
      id: InspectionOpenItemDetail?.inspectionOrReprimandId,
      type: GET_COUNT_TYPE.VIOLATION,
      involvedUserId: involvedPartyId,
      isEscalateToReprimand: false,
      files: [closesFilesData?.fileId ? closesFilesData?.fileId : 0],
      note: formValue.correctiveMeasure,
      userId: userData?.userId,
    };
    return closeInspectionCorrectiveData;
  }

  const closeInspection = () => {
    const InspectionSubmitData = mapData();
    if (!InspectionSubmitData) {
    } else {
      closeInspectionCorrective(InspectionSubmitData);
    }
  };

  const observationBackBtn = {
    title: "Back to Observation",
    route: VIEW_OBSERVATION_LISTS,
  };

  const openItemBackBtn = {
    title: backRouteText ? backRouteText : "Back to Open Items",
    route: backRoute ? backRoute : OPEN_ITEMS_LIST,
  };
  const submitData = () => {
    const data = mappedData();
    if (!data) {
    } else {
      closeViolation(data);
    }
  };

  const handleFileChange = (files, single) => {
    setCloseFilesData({ fileId: single[0], filePath: files });
  };

  const handleDeleteImageURL = () => {
    setCloseFilesData([]);
  };

  const handleFileChange1 = (files, single) => {
    setCloseFilesData1({ fileId: single[0], filePath: files });
  };

  const handleDeleteImageURL1 = () => {
    setCloseFilesData1([]);
  };
  const violationStatus = data1?.status;
  const navigate = useNavigate();
  const inspectionDetails = (id) => {
    navigate(INSPECTION_REPORT, {
      state: { reportId: InspectionOpenItemDetail?.inspectionId },
    });
  };
  //inspection corrective count
  const InsCorrectiveCount = {
    id: userData?.userId,
    type: GET_COUNT_TYPE?.VIOLATION,
  };
  //observation corrective count
  const ObservationCorrectiveCount = {
    id: data1?.observedEmployee,
    type: GET_COUNT_TYPE?.VIOLATION,
  };

  useEffect(() => {
    if (origin === "Inspections" && origin === "Inspections") {
      getCount(InsCorrectiveCount);
    }
    if (origin === "Observations" && origin === "Observations") {
      getCount(ObservationCorrectiveCount);
    } else {
    }
  }, [userData?.userId, data1?.observedEmployee]);

  return (
    <div className="observationOveride">
      <div className="commonOveride">
        <Box className="commoninfoWrap">
          <Box className="commoninfoBox">
            <Box className="commoninfoleft">
              <Typography className="titleViolation">Corrective</Typography>
              {origin === "Inspections" || origin === "Inspection" ? (
                <Typography
                  className="reportid "
                  mt={4}
                  onClick={() => inspectionDetails()}
                >
                  Inspection ID:{" "}
                  <span>{InspectionOpenItemDetail?.inspectionId}</span>
                  <span className="redirectPage">
                    {" "}
                    <RemoveRedEye />
                  </span>
                </Typography>
              ) : (
                <Typography className="reportid" mt={4}>
                  Observation ID: <span>{data1?.insObservationId}</span>
                </Typography>
              )}
              {origin === "Inspections" || origin === "Inspection" ? (
                <Typography className="CaseId" mt={4}>
                  Corrective ID:{" "}
                  <span>
                    {InspectionOpenItemDetail?.inspectionOrReprimandId}
                  </span>
                </Typography>
              ) : (
                ""
              )}

              <Box className="columns" mt={4}>
                <Typography
                  className="listView"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  Involved Party:
                  {origin === "Inspections" || origin === "Inspection" ? (
                    <span className="font-w400">
                      {userData?.userName ? userData?.userName : "N/A"}
                    </span>
                  ) : (
                    <span className="font-w400">
                      {data1?.observedEmployeName
                        ? data1?.observedEmployeName
                        : "N/A"}
                    </span>
                  )}
                </Typography>
                {origin === "Inspections" || origin === "Inspection" ? (
                  <Typography className="listView">
                    Corrective Party:
                    <span className="font-w400">
                      {InspectionOpenItemDetail?.itemRespOrCorrecingPartyName
                        ? InspectionOpenItemDetail?.itemRespOrCorrecingPartyName
                        : "N/A"}
                    </span>
                  </Typography>
                ) : (
                  <Typography className="listView">
                    Corrective Party:
                    <span className="font-w400">
                      {data1?.correctiveUserName
                        ? data1?.correctiveUserName
                        : "N/A"}
                    </span>
                  </Typography>
                )}
              </Box>

              <Box className="columns">
                {origin === "Inspections" || origin === "Inspection" ? (
                  <Typography className="listView">
                    Date of Corrective:
                    <span className="font-w400">
                      {InspectionOpenItemDetail?.inspectionDate}
                    </span>
                  </Typography>
                ) : (
                  <Typography className="listView">
                    Date of Observation:
                    <span className="font-w400">{data1?.observationDate}</span>
                  </Typography>
                )}

                {origin === "Inspections" || origin === "Inspection" ? (
                  <Typography className="listView">
                    Time of Corrective:
                    <span className="font-w400">
                      {InspectionOpenItemDetail?.inspectionTime}
                    </span>
                  </Typography>
                ) : (
                  <Typography className="listView">
                    Time of Observation:
                    <span className="font-w400">{data1?.observationTime}</span>
                  </Typography>
                )}
              </Box>

              <Box className="columns">
                {origin === "Inspections" || origin === "Inspection" ? (
                  <Typography className="listView">
                    Location:
                    <span className="font-w400">
                      {InspectionOpenItemDetail?.location}
                    </span>
                  </Typography>
                ) : (
                  <Typography className="listView">
                    Location:
                    <span className="font-w400">{data1?.location}</span>
                  </Typography>
                )}

                {addedBy && (
                  <Typography className="listView">
                    Inspector Name:
                    <span className="font-w400">{addedBy}</span>
                  </Typography>
                )}
              </Box>
              {origin === "Inspections" || origin === "Inspection" ? (
                <Box className="columns">
                  <Typography className="listView">
                    Asset Type:
                    <span className="font-w400">
                      {InspectionOpenItemDetail?.assetType === 1
                        ? "Project"
                        : InspectionOpenItemDetail?.assetType === 3
                        ? "Vehicle"
                        : InspectionOpenItemDetail?.assetType === 2
                        ? "Equipment"
                        : InspectionOpenItemDetail?.assetType === 4
                        ? "Custom Assets"
                        : ""}
                    </span>
                  </Typography>
                  <Typography className="listView">
                    {InspectionOpenItemDetail?.assetType === 1
                      ? "Project"
                      : InspectionOpenItemDetail?.assetType === 3
                      ? "Vehicle"
                      : InspectionOpenItemDetail?.assetType === 2
                      ? "Equipment"
                      : InspectionOpenItemDetail?.assetType === 4
                      ? "Custom Assets"
                      : ""}{" "}
                    :
                    <span className="font-w400">
                      {InspectionOpenItemDetail?.assetName}
                    </span>
                  </Typography>
                </Box>
              ) : (
                <Box className="columns">
                  <Typography className="listView">
                    Project:
                    <span className="font-w400">{data1?.jobSiteName}</span>
                  </Typography>
                </Box>
              )}

              <Box className="columns">
                {origin === "Inspections" || origin === "Inspection" ? (
                  <Typography className="listView">
                    Category:
                    <span className="font-w400">
                      {InspectionOpenItemDetail?.catName}
                    </span>
                  </Typography>
                ) : (
                  ""
                )}

                {origin === "Inspections" || origin === "Inspection" ? (
                  <Typography className="listView">
                    Sub Category:
                    <span className="font-w400">
                      {InspectionOpenItemDetail?.subCatName}
                    </span>
                  </Typography>
                ) : (
                  ""
                )}
              </Box>

              {origin === "Inspections" || origin === "Inspection" ? (
                <Typography className="listView ">
                  Question{" "}
                  {" " + InspectionOpenItemDetail?.questionSequenceNumber}:
                  <span className="blockView">
                    {parse(unescape(InspectionOpenItemDetail?.questionName))}
                  </span>{" "}
                </Typography>
              ) : (
                ""
              )}

              {origin === "Inspections" || origin === "Inspection" ? (
                <Typography className="listView ">
                  Inspection Details:
                  <span className="blockView">
                    {parse(unescape(InspectionOpenItemDetail?.issue))}
                  </span>{" "}
                </Typography>
              ) : (
                <Typography className="listView">
                  Observation Details:
                  <span className="blockView">
                    {parse(unescape(data1?.observedDetails))}
                  </span>{" "}
                </Typography>
              )}

              {origin === "Inspections" ? (
                <Typography className="listView ">
                  Media:
                  <span className="blockView">
                    {InspectionOpenItemDetail?.correctiveFiles?.map((list) => {
                      return (
                        <ViewImages
                          key={list?.key}
                          singleImagesid={list?.fileId}
                          singleImagespath={list?.filePath}
                          imageSize={imageSize}
                          singleImage={true}
                          multiImage={false}
                        />
                      );
                    })}
                  </span>
                </Typography>
              ) : (
                <Typography className="listView ">
                  Media:
                  <span className="blockView">
                    <ViewImages image={image} imageSize={imageSize} />
                  </span>
                </Typography>
              )}

              {origin === "Inspections" || origin === "Inspection" ? (
                ""
              ) : (
                <Typography className="listView">
                  Comments:
                  <span className="blockView">{data1?.comment}</span>{" "}
                </Typography>
              )}
              {data1?.status === 3 && origin === "Observations" ? (
                <>
                  <Typography className="listView ">
                    Corrective Measures:
                    <span className="blockView">
                      {data1?.assignCorrectiveMeasures}
                    </span>
                  </Typography>
                  <Typography className="listView desNone">
                    Media:
                    <span className="blockView">
                      <ViewImages
                        image={correctiveImage}
                        imageSize={imageSize}
                      />
                    </span>
                  </Typography>
                </>
              ) : userData?.status === "Closed" &&
                (origin === "Inspections" || origin === "Inspection") ? (
                <>
                  <Typography className="listView ">
                    Corrective Measures:
                    <span className="blockView">
                      {userData?.note ? userData?.note : ""}
                    </span>
                  </Typography>
                  <Typography className="listView desNone">
                    Media:
                    <span className="blockView">
                      <ViewImages image={insCloseMedia} imageSize={imageSize} />
                    </span>
                  </Typography>
                </>
              ) : observationStatus === 3 ? (
                <>
                  <Typography className="listView ">
                    Corrective Measures:
                    <span className="blockView">
                      {data1?.assignCorrectiveMeasures}
                    </span>
                  </Typography>
                  <Typography className="listView desNone">
                    Media:
                    <span className="blockView">
                      <ViewImages
                        image={correctiveImage}
                        imageSize={imageSize}
                      />
                    </span>
                  </Typography>
                </>
              ) : (
                ""
              )}
            </Box>

            <Box className="commoninfoRight">
              <Grid mt={4}>
                <Typography className="incstatus">
                  {" "}
                  Observation Status
                </Typography>

                {violationStatus === 2 ? (
                  <Button
                    variant="contained"
                    className="statusbtnOpen cursonDefault"
                  >
                    {data1?.statusText}
                  </Button>
                ) : userData && userData?.statusId === 2 ? (
                  <Button
                    variant="contained"
                    className="statusbtnOpen cursonDefault"
                  >
                    {userData?.status}
                  </Button>
                ) : userData && userData?.statusId === 3 ? (
                  <Button
                    variant="contained"
                    className="statusbtn cursonDefault"
                  >
                    {userData?.status}
                  </Button>
                ) : InspectionOpenItemDetail?.listInvolvedParty?.length >= 0 &&
                  !violationStatus ? (
                  <Button
                    variant="contained"
                    className={`${
                      InspectionOpenItemDetail?.itemStatus === 2
                        ? "statusbtnOpen"
                        : "statusbtn"
                    } cursonDefault`}
                  >
                    {InspectionOpenItemDetail?.itemStatus === 2
                      ? "Open"
                      : "Closed"}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className="statusbtn cursonDefault"
                  >
                    {backRoute ? statusReport : data1?.statusText}
                  </Button>
                )}
              </Grid>

              <Grid mt={4} className="violationBox">
                <Typography className="previousCorrective ">
                  Previous Corrective Item's
                </Typography>
                {origin === "Inspections" || "Inspection" ? (
                  <Button variant="contained" className="reprimandstatusbtn">
                    {inspectionViolationCounts?.data?.result >= 1
                      ? inspectionViolationCounts?.data?.result
                      : 0}
                  </Button>
                ) : origin === "Observations" || observationType === 2 ? (
                  <Button variant="contained" className="reprimandstatusbtn">
                    {inspectionViolationCounts?.data?.result >= 1
                      ? inspectionViolationCounts?.data?.result
                      : 0}
                  </Button>
                ) : (
                  ""
                )}
              </Grid>
            </Box>
          </Box>
        </Box>

        {(violationStatus === 2 && origin === "Observations") ||
        violationStatus === 2 ? (
          <Typography className="correctivemeasures desNone" mt={4}>
            <Grid item md={12} className="closedItemDropboxs">
              <FormLabels label="Corrective Measures" isRequired={true} />
              <TextArea
                Required={true}
                placeholder={"Enter Here"}
                value={formValue.assignCorrectiveMeasures}
                onChange={(e) => {
                  if (
                    e.target.value === " " &&
                    formValue.assignCorrectiveMeasures.trim() === ""
                  )
                    setFormValue({
                      ...formValue,
                      assignCorrectiveMeasures: "",
                    });
                  else
                    setFormValue({
                      ...formValue,
                      assignCorrectiveMeasures: e.target.value,
                    });
                  setIsInsComment(false);
                }}
                isError={isInsCommet}
              />
              {isInsCommet ? (
                <div className="newErrorMsg">
                  Please Select this required field
                </div>
              ) : (
                ""
              )}
              <FormDropZone
                label={""}
                paraText1={"Drop the files here ..."}
                paraText2={"Drop the files here, "}
                paraText3={"or Browse"}
                handleFileChange={handleFileChange1}
                fileType={10}
                imageUrl={closesFilesData1?.filePath}
                deleteImageURL={(url) => handleDeleteImageURL1()}
                id="addClosed"
                key="addClosed"
                isImage={false}
                isMultipleIcon={true}
                allImages={true}
              />
            </Grid>
          </Typography>
        ) : (origin === "Inspections" || origin === "Inspection") &&
          ((userData && userData?.statusId === 2) ||
            (!userData &&
              userData?.length < 1 &&
              InspectionOpenItemDetail?.itemStatus === 2)) ? (
          <Typography className="correctivemeasures desNone" mt={4}>
            <Grid item md={12} className="closedItemDropboxs">
              <FormLabels label="Corrective Measures" isRequired={true} />
              <TextArea
                placeholder={"Enter Here"}
                value={formValue.correctiveMeasure}
                onChange={(e) => {
                  if (
                    e.target.value === " " &&
                    formValue.correctiveMeasure.trim() === ""
                  )
                    setFormValue({
                      ...formValue,
                      correctiveMeasure: "",
                    });
                  else
                    setFormValue({
                      ...formValue,
                      correctiveMeasure: e.target.value,
                    });
                  setIsReprimandComment(false);
                }}
                isError={isReprimandCommet}
              />
              {isReprimandCommet ? (
                <div className="newErrorMsg">
                  Please Select this required field
                </div>
              ) : (
                ""
              )}

              <FormDropZone
                label={""}
                paraText1={"Drop the files here ..."}
                paraText2={"Drop the files here, "}
                paraText3={"or Browse"}
                handleFileChange={handleFileChange}
                fileType={10}
                imageUrl={closesFilesData?.filePath}
                deleteImageURL={(url) => handleDeleteImageURL()}
                id="addClosed"
                key="addClosed"
                isImage={false}
                isMultipleIcon={true}
                allImages={true}
              />
            </Grid>
          </Typography>
        ) : userData?.statusId === 3 ? (
          ""
        ) : (
          ""
        )}

        <Box className="escalatereprimand">
          <Grid mt={4}>
            {pageTitle === "Item Management" || origin === "Inspections" ? (
              <BackBtn dataBack={openItemBackBtn} className="equipmentBack" />
            ) : origin === "Inspections" || origin === "Observations" ? (
              <BackBtn dataBack={openItemBackBtn} className="equipmentBack" />
            ) : (
              <BackBtn
                dataBack={observationBackBtn}
                className="equipmentBack"
              />
            )}
          </Grid>

          {violationStatus === 2 ? (
            <Grid mt={4} style={{ marginRight: "-240px" }}>
              <Button
                varientContained={true}
                className="closereprimandbtn"
                label=" Close Item"
                onClick={submitData}
              >
                Close Item
              </Button>
            </Grid>
          ) : (origin === "Inspections" || origin === "Inspection") &&
            ((userData && userData?.statusId === 2) ||
              (!userData && InspectionOpenItemDetail?.itemStatus === 2)) ? (
            <Grid mt={4} style={{ marginRight: "-240px" }}>
              <Button
                varientContained={true}
                className="closereprimandbtn"
                label=" Close Item"
                onClick={closeInspection}
              >
                Close Item
              </Button>
            </Grid>
          ) : userData?.statusId === 3 &&
            (origin === "Inspections" || origin === "Inspection") ? (
            ""
          ) : (
            ""
          )}
        </Box>
      </div>
    </div>
  );
};

export default ViolationObservationReport;
