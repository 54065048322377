import { getApiUrl } from "../config";
import { ORG_ID } from "../constant/constant";
import { dateValiadtor } from "../utils/helper";
import { get, post, deletes } from "./axiosRequest";

// get question set
export const getQestSet = async (data) => {
  const { SubClassId, organisationId, assetTypeId = "", isAdmin } = data.data;
  const url = `${
    getApiUrl().incpectionApiUrl
  }Inspection/GetQuestionSetList?SubClassId=${SubClassId}&organisationId=${organisationId}&assetTypeId=${assetTypeId}&isAdmin=${isAdmin}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

// Get weather Referece Data
export const getReferenceData = async () => {
  const url = `${getApiUrl().incpectionApiUrl}Inspection/GetReferenceData`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

// question set dynamically list
export const getQestSetList = async (data) => {
  const { questSetId } = data.getQuesDynamicFieldData;
  const url = `${
    getApiUrl().incpectionApiUrl
  }Inspection/getquestionswithdynamicfields?questsetId=${questSetId}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

// question set dynamically list
export const postQestSetList = async (data) => {
  const url = `${getApiUrl().incpectionApiUrl}Inspection/addinspections`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};
// updateQestSetList
export const updateQestSetList = async (data) => {
  const url = `${getApiUrl().incpectionApiUrl}/updateinspection`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};
// get Inspection Rport List
export const getInspectionReportListService = async (paylod) => {
  const {
    compnayId,
    divisionId,
    searchInspection,
    inspectionTypes,
    startDate,
    endDate,
    pageSize,
    page,
    organisationId,
    assetTypeId,
    userId,
    status,
    assetId,
    isSendMail,
  } = paylod;
  const assetTypeId1 = !!assetTypeId ? `assettype=${assetTypeId}&` : "";
  const compnayId1 = !!compnayId ? `compnayId=${compnayId}&` : "";
  const divisionId3 = !!divisionId ? `divisionId=${divisionId}&` : "";
  const searchInspection4 = !!searchInspection
    ? `searchInspection=${searchInspection}&`
    : "";
  const inspectionTypes5 = inspectionTypes
    ? `inspectionTypes=${inspectionTypes}&`
    : "";
  const startDate6 = !!startDate ? `startDate=${startDate}&` : "";
  const endDate7 = !!endDate ? `endDate=${endDate}&` : "";
  const pageSizes = !!pageSize ? `pageSize=${pageSize}&` : "";
  const pages = !!page ? `page=${page}&` : "";
  const organisationIds = !!organisationId
    ? `organisationId=${organisationId}&`
    : "";
  const userIdD = userId >= 0 ? `userId=${userId}` : "";
  const statuss = !!status ? `status=${status}&` : "";
  const assetIds = !!assetId ? `assetId=${assetId}&` : "";
  const url = `${
    getApiUrl().incpectionApiUrl
  }Inspection/GetInspectionList?${statuss}${compnayId1}${divisionId3}${searchInspection4}${inspectionTypes5}${startDate6}${endDate7}${pageSizes}${pages}${organisationIds}${assetTypeId1}${assetIds}${userIdD}&isSendMail=${isSendMail}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

// deleteInspectionRportItemService
export const deleteInspectionService = async (id) => {
  const { InspectionId } = id.payload;

  const url = `${getApiUrl().incpectionApiUrl}Inspection/deleteinspection/${
    InspectionId ? InspectionId : ""
  }`;
  try {
    return await deletes(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

export const getInspectionDetailsService = async (data) => {
  const { InspectionId, organisationId } = data.data;
  const url = `${
    getApiUrl().incpectionApiUrl
  }Inspection/GetInspectionReportView?InspectionId=${InspectionId}&organisationId=${organisationId}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

// Inspection Configuration
export const adminAddUpdateCategory = async ({ data }) => {
  const url = `${
    getApiUrl().incpectionApiUrl
  }Inspection/AdminAddUpdateCategory`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const adminAddUpdateSubcategory = async ({ data }) => {
  const url = `${
    getApiUrl().incpectionApiUrl
  }Inspection/AdminAddUpdateSubcategory`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const addUpdateQuestions = async ({ data }) => {
  const url = `${getApiUrl().incpectionApiUrl}Inspection/AddUpdateQuestions`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const adminUpdateInspection = async ({ data }) => {
  const url = `${getApiUrl().incpectionApiUrl}Inspection/AdminUpdateInspection`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
export const adminAddUpdateInspection = async ({ data }) => {
  const url = `${
    getApiUrl().incpectionApiUrl
  }Inspection/AdminAddUpdateInspection`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const deleteQuestion = async ({ payload }) => {
  const url = `${
    getApiUrl().incpectionApiUrl
  }Inspection/DeleteQuestion?questionId=${payload}`;
  try {
    return await deletes(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getCategoriesList = async ({ data }) => {
  const {
    searchText,
    organisationId,
    page,
    pageSize,
    allowPaging,
    isActive = "",
  } = data;
  const url = `${
    getApiUrl().incpectionApiUrl
  }Inspection/GetCategoriesList?searchText=${searchText}&organisationId=${organisationId}&isActive=${isActive}&pageSize=${pageSize}&page=${page}&allowPaging=${allowPaging}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getSubcategoriesList = async ({ data }) => {
  const { searchText, organisationId,  page, pageSize, allowPaging ,isActive=""} =
    data;
  const url = `${
    getApiUrl().incpectionApiUrl
  }Inspection/GetSubcategoriesList?searchText=${searchText}&organisationId=${organisationId}&isActive=${isActive}&pageSize=${pageSize}&page=${page}&allowPaging=${allowPaging}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getQuestionsList = async ({ data }) => {
  const url = `${getApiUrl().incpectionApiUrl}Inspection/GetQuestionsList`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getQuestionSetList = async (data) => {
  const {
    organisationId,
    certifyStatementId = "",
    allowPagination = false,
    pageSize = "",
    page = "",
    search = "",
    isActive = "",
    assetTypeId = "",
  } = data.data;
  const url = `${
    getApiUrl().incpectionApiUrl
  }Inspection/GetQuestionSetList?organisationId=${organisationId}&certifyStatementId=${certifyStatementId}&assetTypeId=${assetTypeId}&isActive=${isActive}&allowPagination=${allowPagination}&page=${page}&pageSize=${pageSize}&search=${search}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getAsset = async (data) => {
  const { searchText, type } = data.data;
  const url = `${getApiUrl().adminApiUrl}/common/GetAsset?search=${
    searchText ? searchText : ""
  }&AssetType=${type}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        OrgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const cloneQuestionSet = async (data) => {
  const { organisationId, createdBy, newQuestionSet, cloneQuestionSetId } =
    data.data;
  const url = `${
    getApiUrl().incpectionApiUrl
  }Inspection/CloneQuestionSet?organisationId=${organisationId}&createdBy=${createdBy}&newQuestionSet=${newQuestionSet}&cloneQuestionSetId=${cloneQuestionSetId}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        OrgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getQestSetDetail = async (data) => {
  const { questSetId, organisationId } = data.data;
  const url = `${
    getApiUrl().incpectionApiUrl
  }Inspection/GetInspectionConfiguration?questsetId=${questSetId}&organisationId=${organisationId}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

// getInspection open item details
export const getInspectionItemLogDetails = async ({ data }) => {
  const { type, id } = data;
  const url = `${
    getApiUrl().incpectionApiUrl
  }Inspection/GetInspectionOpenItemDetail?id=${id}&type=${type}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

// updateInspectionopen item close
export const updateInspectionItemLogClose = async ({ data }) => {
  const url = `${
    getApiUrl().incpectionApiUrl
  }Inspection/UpdateInspectionOpenItem`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getInspectionViolationCounts = async (data) => {
  const { id, type } = data.data;
  const url = `${getApiUrl().incpectionApiUrl}Inspection/GetItemCounts?id=${
    id ? id : 0
  }&type=${type}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getItemListService = async (data) => {
  const {
    userId,
    search = "",
    page,
    projectId,
    divisionId,
    itemTypes,
    statusIds = "",
    fromDate = "",
    toDate = "",
    status,
    pageSize,
    assetType = "",
    categoryId = "",
    subCategoryId = "",
    reportDetails = "",
    origin = "",
    assetCatId = "",
    isSendMail,
  } = data.data;
  const url = `${
    getApiUrl().incpectionApiUrl
  }Inspection/GetItemList?userId=${userId}&origin=${origin}&assetCatId=${assetCatId}&reportDetails=${reportDetails}&assetType=${assetType}&categoryId=${categoryId}&subCategoryId=${subCategoryId}&search=${search}&itemTypes=${itemTypes}&statusIds=${statusIds}&page=${page}&pageSize=${pageSize}&fromDate=${
    dateValiadtor(fromDate) ? fromDate : ""
  }&toDate=${
    dateValiadtor(toDate) ? toDate : ""
  }&status=${status}&projectId=${projectId}&divisionId=${divisionId}&isSendMail=${
    isSendMail || false
  }`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

// updateReprimandToClose item close
export const updateReprimandToClose = async ({ data }) => {
  const url = `${
    getApiUrl().incpectionApiUrl
  }Inspection/UpdateReprimandToClose`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getInspectionAcknowledgement = async (data) => {
  const { type, status } = data.data;
  const url = `${
    getApiUrl().incpectionApiUrl
  }Inspection/GetReferenceDataByType?type=${type}&status=${
    status ? status : ""
  }`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateInspectionAcknowledgement = async ({ data }) => {
  const url = `${
    getApiUrl().incpectionApiUrl
  }Inspection/UpdateReferenceDataByType`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getExportItemListService = async ({ data }) => {
  const url = `${getApiUrl().incpectionApiUrl}Inspection/ExportItemList`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
export const inspecionFieldsByQuestionSet = async (data) => {
  const { questionSetId } = data.data;
  const url = `${
    getApiUrl().incpectionApiUrl
  }Inspection/GetInspecionFieldsByQuestionSet?QuestionSetId=${questionSetId}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
export const UpdateInspectionFieldsMap = async ({ data }) => {
  const url = `${
    getApiUrl().incpectionApiUrl
  }Inspection/UpdateInspectionFieldsMap`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
export const getInspectionReportAssetDetails = async (data) => {
  const {
    assetType,
    customAssetType,
    startDate,
    endDate,
    divisionId,
    assetId,
    reportDetails,
    page,
    pageSize,
    orgId,
    assetCatId,
  } = data.data;
  const url = `${
    getApiUrl().incpectionApiUrl
  }Inspection/getInspectionReportAssetDetails?assetType=${
    assetType ? assetType : ""
  }&customAssetType=${customAssetType ? customAssetType : ""}&startDate=${
    startDate ? startDate : ""
  }&endDate=${endDate ? endDate : ""}&divisionId=${
    divisionId ? divisionId : ""
  }&assetId=${assetId ? assetId : ""}&assetCatId=${
    assetCatId ? assetCatId : ""
  }&reportDetails=${reportDetails ? reportDetails : ""}&page=${
    page ? page : ""
  }&pageSize=${pageSize ? pageSize : ""}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: orgId ? orgId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getInspectionReport = async (data) => {
  const {
    assetType,
    customAssetType,
    startDate,
    endDate,
    divisionId,
    assetId,
    assetCatId,
    orgId,
  } = data.data;
  const url = `${
    getApiUrl().incpectionApiUrl
  }Inspection/GetInspectionReport?assetType=${assetType}&customAssetType=${customAssetType}&startDate=${startDate}&endDate=${endDate}&divisionId=${divisionId}&assetId=${assetId}&assetCatId=${assetCatId}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: orgId ? orgId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getOpenItemPhotos = async (data) => {
  const { id, filesFor, rootId, orgId } = data.data;
  const url = `${
    getApiUrl().incpectionApiUrl
  }Inspection/GetOpenItemPhotos?id=${id}&filesFor=${filesFor}&rootId=${rootId}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: orgId ? orgId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getInspectionOpenItemCount = async (data) => {
  const { inspectionId, orgId, AppTypeId, AppDetails } = data;
  console.log(data, "data");

  const url = `${
    getApiUrl().incpectionApiUrl
  }Inspection/GetInspectionOpenItemCount?inspectionId=${inspectionId}`;
  console.log(url);

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        OrgId: orgId ? orgId : ORG_ID(),
        AppTypeId: AppTypeId,
        AppDetails: AppDetails,
      },
    });
  } catch (error) {
    return error;
  }
};
