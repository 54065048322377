import React from "react";
import UserManagementList from "../component/userManagement/userManagementList";

import {
  ADMIN_USER,
  ADMIN_GROUP,
  ADMIN_ASSIGN_ROLE,
  ADMIN_USER_CONFIGURATION,
  ADMIN_NONPRIVILEGE_USER_LIST,
  ACCESS_DENIED,
  ADMIN_USER_SEARCH,
  ADMIN_PROCORE_USER_LIST,
} from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import { checkPrivileges } from "../../../utils/rolesHelper";

const UserManagementContainer = () => {
  const navigate = useNavigate();

  const onClickHandler = (id) => {
    if (id === 1) {
      navigate(ADMIN_USER);
    } else if (id === 2) {
      navigate(ADMIN_GROUP);
    } else if (id === 3) {
      navigate(ADMIN_ASSIGN_ROLE);
    } else if (id === 4) {
      navigate(ADMIN_USER_CONFIGURATION);
    }else if (id === 5) {
      navigate(ADMIN_NONPRIVILEGE_USER_LIST);
    } else if (id === 6) {
      navigate(ADMIN_USER_SEARCH);
    } else if (id === 7) {
      navigate(ADMIN_PROCORE_USER_LIST);
    }
  };

  return (
    <>
      {checkPrivileges([12, 55, 56, 57, 64, 65, 66, 77, 76, 139, 14, 148]) ? (
        <>
          <div className="dashTitle">User Management</div>
          <UserManagementList onClick={onClickHandler} />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};

export default UserManagementContainer;
