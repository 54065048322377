import * as loginAction from "../actions/loginActions";
import { getUserDetails } from "../../services/userService";
import { loginTypes } from "../constants/loginTypes";
import {
  NotifiactionLogin,
  NotifiactionLogout,
  NotificationCont,
  NotificationCount,
  NotificationList,
  getLoginOTP,
  login,
  sendLoginOTP,
  signOut,
  updateNotifiactionCount,
  updateNotifiactionList,
} from "../../services/loginService";
import { call, put, takeLatest } from "redux-saga/effects";
import { awsverify } from "../../utils/awsverify";
import * as userAction from "../actions/userAction";
import { userType } from "../constants/userType";
import { apiError } from "../../utils/helper";
import { SAFTEY_SERVICE_TYPE } from "../../constant/constant";
import { validateMobileSignup } from "../../services/signupService";

const loginError = (errorObject) => {
  return errorObject.response ? errorObject.response : errorObject;
};

export const getAllPrivilege = (privileges) => {
  let allPrivileges = [];
  privileges?.forEach((item) => {
    if (allPrivileges.indexOf(item.key) === -1) {
      allPrivileges.push(item.key);
    }
  });
  return allPrivileges;
};

export function* loginRequest({ userName, password, Session }) {
  console.debug("loginRequest");
  try {
    if (!userName || !password) throw loginError({});
    const response = Session
      ? yield call(sendLoginOTP, userName, password, Session)
      : yield call(login, userName, password);
    const { idToken, accessToken, error } = response;
    console.log(response);
    if (Session && response?.data?.Session) {
      yield put(loginAction.loginFailure(response?.data));
      return;
    }
    if (Session && response.error) {
      yield put(loginAction.loginFailure(response.error));
      return;
    }
    if (!idToken || !accessToken) throw loginError(error);
    localStorage.setItem("mobile", userName);
    localStorage.setItem("access_token", accessToken);
    localStorage.setItem("id_token", idToken);
    const {
      data,
      status,
      error: userError,
    } = yield call(getUserDetails, userName);
    if (status !== 200 || userError) throw loginError(userError);
    let isOnboarded = false;
    if (status === 200 && data) {
      const {
        user: {
          userId,
          organizationId,
          firstName,
          jobTitle,
          jobTitleId,
          organization,
          divisionId,
          profileImage,
          privileges,
          product,
          signatureUrl
        },
        responseMessages: { responseCode },
      } = data;
      if (responseCode === "HBNG404" && !organizationId && !userId) {
        localStorage.removeItem("access_token", accessToken);
        localStorage.removeItem("id_token", idToken);
        throw loginError({
          code: responseCode,
          message: "User was not created successfully !!",
        });
      }
      //Product ID is not mapped
      if (product && product.length < 1) {
        localStorage.removeItem("access_token", accessToken);
        localStorage.removeItem("id_token", idToken);
        throw loginError({
          code: responseCode,
          message: "Product ID is not mapped successfully !!",
        });
      }

      //Setting values in local storage
      localStorage.setItem("userInfo", JSON.stringify(data.user));
      localStorage.setItem(
        "privileges",
        JSON.stringify(getAllPrivilege(privileges))
      );
      localStorage.setItem(
        "serviceType",
        product?.length === 1 ? product[0]?.key : ""
      );
      localStorage.setItem("userId", userId);
      localStorage.setItem("loginOrganizationId", organizationId);
      localStorage.setItem("loginOrganizationName", organization);
      localStorage.setItem("orgId", organizationId);
      localStorage.setItem("orgName", organization);
      localStorage.setItem("divisionId", divisionId);
      localStorage.setItem("name", firstName);
      localStorage.setItem("firstName", `${firstName}`);
      localStorage.setItem("companyName", organization);
      localStorage.setItem("profileImage", profileImage);
      localStorage.setItem("signatureUrl", signatureUrl);


      if (jobTitle || jobTitleId || organizationId) isOnboarded = true;
      localStorage.setItem("isOnboarded", isOnboarded);
      const userPage = userType.USER_PAGE;
      yield put(
        userAction.getUserDetailsSuccess({
          userPage,
          data,
        })
      );
    }
    yield call(setCurrentUser, {
      idToken,
      accessToken,
      tokenType: "id",
      isOnboarded,
    });
  } catch (error) {
    localStorage.removeItem("mobile");
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    if (error && error.message && error.code === "UserNotConfirmedException") {
      error.message = "Incorrect username or password.";
    }
    const data =
      error && error.message && error.code
        ? { code: error.code, message: error.message }
        : { message: error || "Login Failed" };
    yield put(loginAction.loginFailure(data));
  }
}

// set current user
export function* setCurrentUser({
  idToken,
  accessToken,
  tokenType,
  isOnboarded,
}) {
  const loginPage = loginTypes.LOGINPAGE;
  yield put(
    loginAction.loginSuccess({
      userSuccess: true,
      isOnboarded: isOnboarded,
      loginPage,
      userInfo: {},
    })
  );

  const jwt = yield call(awsverify, {
    accessToken: idToken,
    tokenType: tokenType,
  });
  if (typeof jwt !== "string") {
    yield put(
      loginAction.loginSuccess({
        userSuccess: true,
        loginPage,
        userInfo: jwt,
        isOnboarded: isOnboarded,
      })
    );
  } else {
    const res = yield call(signOut);
    if (res === "success") {
      localStorage.clear();
      yield put(
        loginAction.loginSuccess({
          userSuccess: false,
          loginPage,
          userInfo: {},
        })
      );
    }
  }
}

export function* clearAll() {
  yield put(loginAction.clearErrors());
}

export function* setRiderctUrl(url) {
  yield put(loginAction.setRiderctUrls(url));
}

// logout user
export function* logoutUserSaga() {
  // alert()
  const loginPage = loginTypes.LOGINPAGE;
  try {
    const res = yield call(signOut);
    if (res === "success") {
      yield put(loginAction.setRiderctUrls(""));
      const dtaKey = [
        "columnVisibility",
        "columnVisibilityKudus",
        "columnVisibilityReprimnd",
        "columnVisibilityViolation",
        "columnVisibilityAction",
      ];
      const dataval = dtaKey?.map((val) => {
        return localStorage.getItem(val);
      });

      localStorage.clear();

      const dataval2 = dtaKey?.map((val, index) => {
        return localStorage.setItem(val, dataval[index]);
      });

      yield put(
        loginAction.loginSuccess({
          userSuccess: false,
          loginPage,
          userInfo: {},
        })
      );
      window.location.reload();
    }
  } catch (error) {
    console.log(error);
  }
}

//push Notification
export function* notificationLoginSaga(params) {
  console.debug("notificationSaga");
  try {
    const { data, status, error } = yield call(NotifiactionLogin, params);
    if (!data && status !== 200) throw apiError(error);
    const loginPage = loginTypes.LOGINPAGE;
    yield put(
      loginAction.postNotifiactionLoginSuccess({
        loginPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Notification Login Creation Failed" };
    yield put(loginAction.postNotifiactionLoginFailure(data));
  }
}

export function* notificationLogoutSaga(params) {
  console.debug("notificationLogoutSaga");
  try {
    const { data, status, error } = yield call(NotifiactionLogout, params);
    if (!data && status !== 200) throw apiError(error);
    const loginPage = loginTypes.LOGINPAGE;
    yield put(
      loginAction.postNotifiactionLogoutSuccess({
        loginPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Notification Logout Creation Failed" };
    yield put(loginAction.postNotifiactionLogoutFailure(data));
  }
}

export function* NotificationListSaga(params) {
  try {
    const { data, status, error } = yield call(NotificationList, params);
    if (!data && status !== 200) throw apiError(error);
    const loginPage = loginTypes.LOGINPAGE;

    yield put(
      loginAction.getNotifiactionListSuccess({
        loginPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Site List Failed" };
    yield put(loginAction.getNotifiactionListFailure(data));
  }
}

export function* updateNotificationListSaga(params) {
  console.debug("notificationLogoutSaga");
  try {
    const { data, status, error } = yield call(updateNotifiactionList, params);
    if (!data && status !== 200) throw apiError(error);
    const loginPage = loginTypes.LOGINPAGE;
    yield put(
      loginAction.updateNotifiactionListSuccess({
        loginPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Notification Update  List  Failed" };
    yield put(loginAction.updateNotifiactionListFailure(data));
  }
}

export function* NotificationContSaga(params) {
  try {
    const { data, status, error } = yield call(NotificationCount, params);
    if (!data && status !== 200) throw apiError(error);
    const loginPage = loginTypes.LOGINPAGE;

    yield put(
      loginAction.getNotifiactionCountSuccess({
        loginPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Notification  Count Failed" };
    yield put(loginAction.getNotifiactionCountFailure(data));
  }
}

export function* updateNotificationCountSaga(params) {
  console.debug("notificationLogoutSaga");
  try {
    const { data, status, error } = yield call(updateNotifiactionCount, params);
    if (!data && status !== 200) throw apiError(error);
    const loginPage = loginTypes.LOGINPAGE;
    yield put(
      loginAction.updateNotifiactionCountSuccess({
        loginPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Notification Update Count  Failed" };
    yield put(loginAction.updateNotifiactionCountFailure(data));
  }
}

export function* loginOTPSaga(params) {
  console.debug("loginOTPSaga");
  try {
    const { data, status, error } = yield call(getLoginOTP, params);
    yield put(
      loginAction.getLoginOTPSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Login OTP  Failed" };
    yield put(loginAction.getLoginOTPFailure(data));
  }
}

export function* loginOTPUserVerifySaga(data) {
  const { mobile } = data.data;
  try {
    if (!mobile) throw loginError({});
    const { status: mobileStatus, data: mobileResponse } = yield call(
      validateMobileSignup,
      mobile
    );
    yield put(
      loginAction.getLoginOTPUserVerifySuccess({
        mobileResponse,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Login OTP User Verify Failed" };
    yield put(loginAction.getLoginOTPUserVerifyFailure(data));
  }
}

const myLoginSaga = [
  takeLatest(loginTypes.POST_LOGIN_PENDING, loginRequest),
  takeLatest(loginTypes.SET_CURRENT_USER, setCurrentUser),
  takeLatest(loginTypes.POST_REDIRECT_URL_PENDING, setRiderctUrl),
  takeLatest(loginTypes.lOGIN_LOGOUT, logoutUserSaga),
  takeLatest(loginTypes.CLEAR_ERRORS, clearAll),
  takeLatest(loginTypes.POST_NOTIFICATION_LOGIN_PENDING, notificationLoginSaga),
  takeLatest(
    loginTypes.POST_NOTIFICATION_LOGOUT_PENDING,
    notificationLogoutSaga
  ),
  takeLatest(loginTypes.GET_NOTIFICATION_LIST_PENDING, NotificationListSaga),
  takeLatest(
    loginTypes.POST_UPDATE_NOTIFICATION_LIST_PENDING,
    updateNotificationListSaga
  ),
  takeLatest(loginTypes.GET_NOTIFICATION_COUNT_PENDING, NotificationContSaga),
  takeLatest(
    loginTypes.POST_UPDATE_NOTIFICATION_COUNT_PENDING,
    updateNotificationCountSaga
  ),
  takeLatest(loginTypes.GET_LOGIN_OTP_PENDING, loginOTPSaga),
  takeLatest(
    loginTypes.GET_LOGIN_OTP_USER_VERIFY_PENDING,
    loginOTPUserVerifySaga
  ),
];

export default myLoginSaga;
