import React, { useState } from "react";
import Buttons from "../../../../library/custom/button/Button";
import { Grid } from "@mui/material";
import {
  CLIENT_SATATUS_FILTER,
  ISwitch,
  PAGE_SIZE,
  USER_ID,
} from "../../../../constant/constant";
import Loader, { TableLoader } from "../../../../library/common/Loader";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import NORow from "../../../../library/custom/noRow/noRow";
import ReportRowManipulte from "./jhaReportRowManipulate";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import { InputField } from "../../../../library/custom/textBox/InputField";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import SearchIcon from "@mui/icons-material/Search";
import * as jhaAction from "../../../../redux/actions/jhaAction";
import { useDispatch } from "react-redux";
import ServiceeFilterList from "../jha/serviceListFilter";
import FilterListIcon from "@mui/icons-material/FilterList";

const JHACategoryConfiguration = ({
  handleEditOnClick,
  categoriesList = [],
  isLoading,
  addJhaCategory,
  getCategoriesListData,
  addCategoryLoading,
  handleStatusChange,
  itemsCount,
}) => {
  const dataBack = {
    title: "Back to Hazard Analysis Configuration",
    route: -1,
  };

  const [anchorElFilter, setAnchorElFilter] = useState(null);

  const openFilter = Boolean(anchorElFilter);
  const id = openFilter ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorElFilter(event.currentTarget);
  };

  const [filterFormValue, setFilterFormValue] = useState({
    isActive: "",
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleCloseRerportRow = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch();
  const handleChange = (categorySetItem) => {
    const newCatList = [...categoriesList];
    newCatList.forEach((lists) => {
      if (lists.id === categorySetItem.id) {
        lists.isGlobal = false;
        lists.categoryId = lists.id;
        lists.isActive = !lists.isActive;
        lists.listsmodifiedBy = parseInt(USER_ID());
      }
    });
    setPage(1);
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 300);
    handleStatusChange(categorySetItem);
  };
  const columns = [
    { field: "categoryName", headerName: "Category Name", flex: 2 },
    { field: "modifiedDate", headerName: "Last Updated", flex: 1 },
    {
      flex: 1,
      field: "status",
      headerName: "Status",
      renderCell: (rows) => (
        <Grid className="catListBox">
          <Grid className="closeIcon">
            <FormControl component="fieldset" variant="standard">
              <FormGroup className="isActiveBox">
                <FormControlLabel
                  control={
                    <ISwitch
                      checked={rows?.row.isActive ? rows?.row.isActive : false}
                      onChange={() => handleChange(rows?.row)}
                      name="status"
                    />
                  }
                />

                <div>{rows?.row?.isActive ? "Active" : "Inactive"}</div>
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      ),
    },
    {
      flex: 1,
      field: "icon",
      renderHeader: () => "",
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            editOnClick={() => {
              setOpen(true);
              seteditFormValue({
                ...editFormValue,
                categoryName: rows?.row?.categoryName,
                categoryId: rows?.row?.id,
                isActive: rows?.row?.isActive,
              });
            }}
            edit
            editLabel="Edit Category Name"
            handleClose={handleCloseRerportRow}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
          />
        );
      },
    },
  ];
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const [errorsMsgShow, setErrorsMsgShow] = useState();
  const [editErrorsMsgShow, setEditErrorsMsgShow] = useState();
  const [isAlreadyExists, setIsAlreadyExists] = useState(false);
  const [isExistsErrorMsg, setisExistsErrorMsg] = useState("");
  const [formValue, setFormValue] = useState({
    categoryName: "",
    isGlobal: false,
    createdBy: parseInt(USER_ID()),
  });

  function submitData() {
    let error = false;
    if (formValue?.categoryName?.trim() === "") {
      setErrorsMsgShow(true);
      setFormValue({
        ...formValue,
        categoryName: "",
      });
      error = true;
    }

    const index = categoriesList?.findIndex(
      (object) =>
        object.categoryName.toUpperCase() ===
        formValue?.categoryName.toUpperCase()
    );
    if (index >= 0) {
      setIsAlreadyExists(true);
      setisExistsErrorMsg("Category Already Exists");
      error = true;
    }
    if (!error) {
      addJhaCategory(formValue);
      setFormValue({
        ...formValue,
        categoryName: "",
      });
    }
  }

  // search category
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  function handleautoSearch(e) {
    setSearch(e.target.value);
    setPage(1);

    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 300);
    getCategoriesListData({
      page: 1,
      pageSize: PAGE_SIZE,
      search: e.target.value,
      isAll:
        filterFormValue.isActive === 1
          ? 0
          : filterFormValue.isActive === 2
          ? 2
          : 1,
    });
  }

  // pagination

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      page: newPage + 1,
      pageSize: PAGE_SIZE,
      search: search,
      isAll:
        filterFormValue.isActive === 1
          ? 0
          : filterFormValue.isActive === 2
          ? 2
          : 1,
    };
    getCategoriesListData(data);
  };

  // edit
  const [editFormValue, seteditFormValue] = useState({
    categoryName: "",
    isGlobal: false,
    categoryId: 0,
    isActive: undefined,
    modifiedBy: parseInt(USER_ID()),
  });

  const [isTableLoading, setisTableLoading] = useState(false);

  function handleFilterClear(params) {
    setPage(1);
    setFilterFormValue({
      assetType: "",
      isActive: "",
    });
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 300);
    setSearch("");
    getCategoriesListData({
      page: 1,
      pageSize: PAGE_SIZE,
      search: "",
      isAll: 1,
    });
    setAnchorElFilter(null);
    window.close();
  }

  function handleFilterApply(params) {
    setPage(1);
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 300);
    getCategoriesListData({
      page: 1,
      pageSize: PAGE_SIZE,
      search: search,
      isAll:
        filterFormValue.isActive === 1
          ? 0
          : filterFormValue.isActive === 2
          ? 2
          : 1,
    });
    setAnchorElFilter(null);
    window.close();
  }
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid className="modalContainer">
          <Box className="modalBox">
            <div className="modalTitle">Edit Category Name</div>
            <div className="modalInputBox">
              <InputField
                type="text"
                isRequired={true}
                value={editFormValue.categoryName}
                onChange={(e) => {
                  seteditFormValue({
                    ...editFormValue,
                    categoryName: e.target.value,
                  });
                  setisExistsErrorMsg("");
                }}
                label="Edit Categry Name"
                placeholder="Category Name Here..."
                isError={editErrorsMsgShow}
                errorMsg={editErrorsMsgShow ? "Category Name is Required" : ""}
              />
            </div>
            <Grid container className="modalFooterBox">
              <Grid item lg={2} xs={12} sm={3} md={3}>
                <Buttons
                  varientText={true}
                  label="Cancel"
                  onClick={handleClose}
                />
              </Grid>
              <Grid item lg={2} xs={12} sm={3} md={3}>
                <Buttons
                  varientContained={true}
                  label={"Save"}
                  onClick={() => {
                    if (editFormValue.categoryName.trim() === "") {
                      seteditFormValue({
                        ...editFormValue,
                        categoryName: "",
                      });
                      setEditErrorsMsgShow(true);
                    } else {
                      handleEditOnClick(editFormValue);
                      handleClose();
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Modal>

      <Grid className="inspectionOverRide">
        <Grid container className="confBox">
          <Grid item md={8.5} xs={12}>
            <div className="confTitile">
              Hazard Analysis Category Configuration
            </div>
          </Grid>
        </Grid>

        <Grid item container mt={1.5} lg={12} spacing={1}>
          <Grid item lg={6} mt={2} className="Catoption">
            <InputField
              disabled={false}
              isRequired={true}
              type="text"
              label={"Create Hazard Analysis Category"}
              value={formValue.categoryName}
              placeholder="Enter here"
              onChange={(e) => {
                setIsAlreadyExists(false);
                setisExistsErrorMsg("");
                if (
                  e.target.value === " " &&
                  formValue.categoryName.trim() === ""
                )
                  setFormValue({
                    ...formValue,
                    categoryName: "",
                  });
                else
                  setFormValue({
                    ...formValue,
                    categoryName: e.target.value,
                  });
                setErrorsMsgShow(false);
              }}
              isError={errorsMsgShow}
              errorMsg={errorsMsgShow ? "Category Name is Required" : ""}
            />
          </Grid>
          <Grid className="smallAddBtn" item mt={5.8} lg={1.1}>
            <Buttons
              aria-describedby="dd"
              variant="contained"
              type="button"
              name="btn"
              label={"Create"}
              onClick={(e) => submitData()}
              id="btnAddEquipment"
              varientAddIconBlue={true}
            ></Buttons>
          </Grid>

          <Grid md={12} item>
            {isAlreadyExists && <p className="errorMsg">{isExistsErrorMsg}</p>}
          </Grid>
        </Grid>
        <Grid item container md={12} className="CategoriesBox" mt={3}>
          <Grid item md={12} xs={8} mb={3}>
            <div className="filter_of_caselist">
              <div className="searchcaseid">
                <label htmlFor="search">
                  <span>
                    <SearchIcon />
                  </span>
                  <div>
                    <input
                      id="search"
                      placeholder="Search"
                      type="text"
                      value={search}
                      className=""
                      onChange={(e) => handleautoSearch(e)}
                      autoComplete="off"
                    />
                  </div>
                </label>
              </div>
              <div className="filter_btn">
                <button
                  aria-describedby={id}
                  variant="contained"
                  type="button"
                  name="btn"
                  onClick={(e) => handleClick(e)}
                >
                  <FilterListIcon id="icn" /> Filters
                </button>
              </div>
            </div>

            <ServiceeFilterList
              id={id}
              open={openFilter}
              anchorEl={anchorElFilter}
              handleClose={() => {
                setAnchorElFilter(null);
                window.close();
              }}
              handleApply={() => handleFilterApply()}
              secondLabel="Status"
              secondLebelFilter={CLIENT_SATATUS_FILTER}
              secondLebelValue={filterFormValue.isActive}
              firstLebelOnChanges={(e) =>
                setFilterFormValue({
                  ...filterFormValue,
                })
              }
              secondLebelOnChanges={(e) =>
                setFilterFormValue({
                  ...filterFormValue,
                  isActive: e.target.value,
                })
              }
              handleClear={() => handleFilterClear()}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid container item md={12} xs={12}>
            <Grid item md={12} sm={12} className="" mt={3}>
              {isTableLoading ? (
                <Loader />
              ) : (
                <div className="desc_box row_uniq">
                  <div className="table_style_ui">
                    <GridTable
                      getRowId={(r) => r.id}
                      rows={categoriesList ?? []}
                      rowCount={itemsCount ?? 0}
                      columns={columns}
                      loading={isLoading}
                      page={page}
                      pagination
                      pageSize={PAGE_SIZE}
                      rowsPerPageOptions={[PAGE_SIZE]}
                      onPageChange={handlePagignation}
                      backIconButtonProps={{ disabled: false }}
                      disableSelectionOnClick
                      components={{
                        LoadingOverlay: TableLoader,
                        NoRowsOverlay: NORow,
                      }}
                    />
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
        <div onClick={() => dispatch(jhaAction.clearJha())}>
          <BackBtn dataBack={dataBack} />
        </div>
      </Grid>
    </>
  );
};

export default JHACategoryConfiguration;
