import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ACCESS_DENIED, ADMIN_ADD_CREDENTIAL } from "../../../constant/routeContant";
import AdminCredential from "../component/adminCredentialList";
import { useDispatch, useSelector } from "react-redux";
import * as credentialAction from "../../../redux/actions/credentialAction";
import { toast, ToastContainer } from "react-toastify";
import { ORG_ID, PAGE_SIZE, API_STATUS } from "../../../constant/constant";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import { checkPrivileges } from "../../../utils/rolesHelper";

function AdminCredentialListContainer() {
  const navigate = useNavigate();
  const [sendEmail, setSendEmail] = useState(false);
  const defaultData = {
    userId: 0,
    page: 1,
    search: "",
    pageSize: PAGE_SIZE,
    orgId: ORG_ID(),
    credentialType: "",
    userType: 0,
    isSendMail: false,
  };

  const userCredentialListData = useSelector(
    (state) => state.getCredentialUserListData
  );
  const isLoading = userCredentialListData?.isLoading;
  const dispatch = useDispatch();

  const getUserCredentials = (data) => {
    if (data?.isSendMail) {
      setSendEmail(true);
    } else {
      setSendEmail(false);
    }
    dispatch(credentialAction.getUserCredentialList(data));
  };

  useEffect(() => {
    getUserCredentials(defaultData);
  }, []);

  const credentialTypes = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.credentialTypes
  );

  const deleteCredentialReducerData = useSelector(
    (state) => state.deleteCredentialReducerData.status
  );

  const deleteCredential = (credentialId) => {
    dispatch(credentialAction.deleteCredential(credentialId));
  };

  useEffect(() => {
    if (deleteCredentialReducerData === API_STATUS.SUCCESS) {
      getUserCredentials(defaultData);
    }
  }, [deleteCredentialReducerData]);

  const getDropdownDataKeyValueData = {
    orgId: ORG_ID(),
    flags: 8,
  };

  useEffect(() => {
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);

  const navigateAddCredential = () => {
    navigate(ADMIN_ADD_CREDENTIAL);
  };

  useEffect(() => {
    if (userCredentialListData?.status === API_STATUS.SUCCESS && sendEmail) {
      toast("User credential has been sent to registered email ID");
    }
  }, [userCredentialListData]);

  return (
    <>
      {checkPrivileges([12, 13, 47, 48]) ? (
        <>
          <ToastContainer />
          <AdminCredential
            navigateAddCredential={navigateAddCredential}
            credentialListData={
              isLoading ? [] : userCredentialListData?.data?.result
            }
            isLoading={isLoading}
            getUserCredentials={getUserCredentials}
            credentialTypes={credentialTypes}
            deleteCredentials={deleteCredential}
          />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}

export default AdminCredentialListContainer;
