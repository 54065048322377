import { Grid, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { REPORTS_ANALYTICS_DASHBOARD_LIST } from "../../../constant/constant";
import TabPanel from "../../lms/component/tabPanel/tabPanel";
import InspectionsContainer from "../inspections/container/inspectionsContainer";
import LmsContainer from "../lms/container/lmsContainer";
import IncidentsContainer from "../incidents/container/incidentsContainer";
import "./reportAnalytics.scss";
import { useLocation } from "react-router-dom";

const ReportAnalytics = () => {
  const location = useLocation();

  function a11yProps(index, id) {
    return {
      id: `${id}`,
      "aria-controls": `${index}`,
    };
  }

  const [value, setValue] = useState(
    location?.state?.tab ? location?.state?.tab : 0
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid container>
        <Grid item md={12} xs={12}>
          <Tabs value={value} onChange={handleChange} className="tabsWrapper">
            {REPORTS_ANALYTICS_DASHBOARD_LIST?.map((tabItem, i) => {
              return (
                <Tab
                  className="tabList"
                  label={tabItem?.name}
                  key={i}
                  {...a11yProps(i, tabItem?.id)}
                />
              );
            })}
          </Tabs>
        </Grid>

        <Grid item md={12} xs={12} mt={3}>
          <TabPanel value={value} index={0}>
            <InspectionsContainer tab={value} />
          </TabPanel>
          <TabPanel value={value} xs={12} index={1}>
            <LmsContainer tab={value} />
          </TabPanel>
          <TabPanel value={value} xs={12} index={2}>
            <IncidentsContainer tab={value} />
          </TabPanel>
        </Grid>
      </Grid>
    </>
  );
};

export default ReportAnalytics;
