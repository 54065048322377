import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  FormControl,
  RadioGroup,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Buttons from "../../../library/custom/button/Button";
import {
  QUESTION_ANSWER_RESPONSE_TYPE,
  QUESTION_RESPONSE_TYPE,
} from "../../../constant/constant";
import MultiSelectBox from "../../../library/custom/selectMenu/MultiSelectBox";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import TextEditor from "../../../library/custom/textEditor/TextEditor";
import RadioButton from "../../../library/custom/radioButton/RadioButton";
import Acceptable from "./acceptable";
import NotAcceptable from "./notAcceptable";
import { Add, Remove } from "@mui/icons-material";
import {
  toggleSkipCategory,
  toggleSkipSubCategory,
} from "../../../utils/incpectionHelperNew";

const Categorys = (props) => {
  const initializeExpandedState = () => {
    const initialExpanded = {};
    props?.listInspectionCategory?.forEach((category, categoryIndex) => {
      if (!category.isSkipped) {
        initialExpanded[categoryIndex] = false;
        category?.listInspectionSubCategory?.forEach(
          (subcategory, subcategoryIndex) => {
            if (!subcategory.isSkipped) {
              initialExpanded[`${categoryIndex}-${subcategoryIndex}`] = false;
            }
          }
        );
      }
    });
    return initialExpanded;
  };

  const [expanded, setExpanded] = useState(initializeExpandedState);

  useEffect(() => {
    setExpanded(initializeExpandedState());
  }, []);

  const handleAccordionChange = (key, isExpanded) => {
    let categoryIndex = null;
    let subCategoryIndex = null;

    if (typeof key === "string") {
      const parts = key.split("-").map(Number);

      categoryIndex = parts[0];
      if (parts.length > 1) {
        subCategoryIndex = parts[1];
      }
    }

    const category = props?.listInspectionCategory[categoryIndex];
    const isCategorySkipped = category?.isSkipped;
    const isSubCategorySkipped =
      subCategoryIndex !== null
        ? category?.listInspectionSubCategory[subCategoryIndex]?.isSkipped
        : false;

    if (isCategorySkipped || isSubCategorySkipped) {
      setExpanded((prevExpanded) => ({
        ...prevExpanded,
        [key]: false,
      }));
    } else {
      setExpanded((prevExpanded) => ({
        ...prevExpanded,
        [key]: isExpanded,
      }));
    }
  };

  const handleCategoryToggle = (e, categoryIndex) => {
    e.stopPropagation();
    toggleSkipCategory(
      e,
      props?.setListInspectionCategory,
      props?.listInspectionCategory,
      categoryIndex
    );

    const newIsSkipped =
      !props?.listInspectionCategory[categoryIndex]?.isSkipped;
    setExpanded((prevExpanded) => {
      const newExpanded = { ...prevExpanded };
      if (newIsSkipped) {
        delete newExpanded[categoryIndex];
        props?.listInspectionCategory[
          categoryIndex
        ]?.listInspectionSubCategory?.forEach((_, subCategoryIndex) => {
          delete newExpanded[`${categoryIndex}-${subCategoryIndex}`];
        });
      } else {
        newExpanded[categoryIndex] = false;
      }
      return newExpanded;
    });
  };

  const handleSubCategoryToggle = (e, categoryIndex, subCategoryIndex) => {
    e.stopPropagation();
    toggleSkipSubCategory(
      e,
      props?.setListInspectionCategory,
      props?.listInspectionCategory,
      categoryIndex,
      subCategoryIndex
    );

    const newIsSkipped =
      !props?.listInspectionCategory[categoryIndex]?.listInspectionSubCategory[
        subCategoryIndex
      ]?.isSkipped;
    setExpanded((prevExpanded) => {
      const newExpanded = { ...prevExpanded };
      if (newIsSkipped) {
        delete newExpanded[`${categoryIndex}-${subCategoryIndex}`];
      } else {
        newExpanded[`${categoryIndex}-${subCategoryIndex}`] = false;
      }
      return newExpanded;
    });
  };

  return (
    <>
      <>
        {props?.listInspectionCategory?.map((category, categoryIndex) => {
          const allSubcategoriesSkippable =
            category?.listInspectionSubCategory?.every(
              (subcategory) => subcategory?.isSkippable
            ) ?? false;
          return (
            <div key={categoryIndex}>
              <Accordion
                className="swAccordianParent"
                expanded={
                  expanded[categoryIndex] !== undefined
                    ? expanded[categoryIndex]
                    : false
                }
                onChange={(e, isExpanded) =>
                  handleAccordionChange(`${categoryIndex}`, isExpanded)
                }
              >
                <AccordionSummary
                  className="swAccordian"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <div className="cateTitleWIns">
                    <Typography className="accoTitle">
                      {category.value}
                    </Typography>
                    <div className="cateTitleWInsRight">
                      {category?.isSkippable && allSubcategoriesSkippable && (
                        <button
                          className="skipCat"
                          onClick={(e) =>
                            handleCategoryToggle(e, categoryIndex)
                          }
                        >
                          {category?.isSkipped ? "Undo" : "Skip"}
                        </button>
                      )}
                      <div className="inspectPersantage">
                        {category.howManyCat}/
                        {category.listInspectionSubCategory?.length} Inspected (
                        {category.catPercent?.toFixed(2)}%)
                      </div>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {category?.listInspectionSubCategory?.map(
                    (subcategory, subcategoryIndex) => (
                      <>
                        <Accordion
                          key={subcategoryIndex}
                          className="swAccordianChild"
                          expanded={
                            expanded[`${categoryIndex}-${subcategoryIndex}`] !==
                            undefined
                              ? expanded[`${categoryIndex}-${subcategoryIndex}`]
                              : false
                          }
                          onChange={(e, isExpanded) =>
                            handleAccordionChange(
                              `${categoryIndex}-${subcategoryIndex}`,
                              isExpanded
                            )
                          }
                        >
                          <AccordionSummary
                            className="swChildAccordian"
                            expandIcon={<ExpandMoreIcon />}
                          >
                            <div className="subCatButton">
                              <Typography className="accoTitle">
                                {subcategory.value}
                              </Typography>
                              <div className="cateTitleWInsRight">
                                {subcategory?.isSkippable && (
                                  <button
                                    className="skipSubCat"
                                    onClick={(e) =>
                                      handleSubCategoryToggle(
                                        e,
                                        categoryIndex,
                                        subcategoryIndex
                                      )
                                    }
                                  >
                                    {subcategory?.isSkipped ? "Undo" : "Skip"}
                                  </button>
                                )}

                                <div className="subCatButtonBox">
                                  <div className="inspectPersantage">
                                    {subcategory.howManySub}/
                                    {
                                      subcategory?.listInspectionQuestionAnswer
                                        ?.length
                                    }{" "}
                                    Inspected (
                                    {subcategory.subPercent?.toFixed(2)}
                                    %)
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails>
                            <ul>
                              {subcategory?.listInspectionQuestionAnswer?.map(
                                (question, questionIndex) => (
                                  <>
                                    <li
                                      key={questionIndex}
                                      className="accordianQuestionBox"
                                    >
                                      <div className="quesTitle">
                                        {question.quesNo}. {question.value}
                                      </div>

                                      {question.responsetype ===
                                      QUESTION_RESPONSE_TYPE.ACCEPTABLE_NOTACCEPTABLE_NOTAPPLICABLE ? (
                                        <>
                                          <div className="radioContainer">
                                            <RadioGroup
                                              row
                                              aria-labelledby="demo-row-radio-buttons-group-label"
                                              name="row-radio-buttons-group"
                                              className="radioGroup"
                                            >
                                              <RadioButton
                                                checked={
                                                  parseInt(
                                                    question?.radioSelectiovalue
                                                  ) === 1
                                                }
                                                value={"1"}
                                                label="Acceptable"
                                                onChange={(e) =>
                                                  props?.handleFormChange(
                                                    e?.target?.value,
                                                    question,
                                                    subcategory,
                                                    category,
                                                    "radioSelectiovalue"
                                                  )
                                                }
                                              ></RadioButton>

                                              <RadioButton
                                                checked={
                                                  parseInt(
                                                    question?.radioSelectiovalue
                                                  ) === 2
                                                }
                                                value={"2"}
                                                label="Not Acceptable"
                                                onChange={(e) =>
                                                  props?.handleFormChange(
                                                    e?.target?.value,
                                                    question,
                                                    subcategory,
                                                    category,
                                                    "radioSelectiovalue"
                                                  )
                                                }
                                              ></RadioButton>
                                              <RadioButton
                                                checked={
                                                  parseInt(
                                                    question?.radioSelectiovalue
                                                  ) === 3
                                                }
                                                value={"3"}
                                                label="N/A"
                                                onChange={(e) =>
                                                  props?.handleFormChange(
                                                    e?.target?.value,
                                                    question,
                                                    subcategory,
                                                    category,
                                                    "radioSelectiovalue"
                                                  )
                                                }
                                              ></RadioButton>
                                            </RadioGroup>
                                          </div>

                                          {parseInt(
                                            question?.radioSelectiovalue
                                          ) ===
                                            QUESTION_ANSWER_RESPONSE_TYPE.ACCEPTABLE && (
                                            <div className="commonWrapper">
                                              {question?.responsData?.acceptable?.map(
                                                (acc, accIndex) => {
                                                  return (
                                                    <Acceptable
                                                      question={question}
                                                      subcategory={subcategory}
                                                      category={category}
                                                      acc={acc}
                                                      key={accIndex}
                                                      handleFormChange={
                                                        props?.handleFormChange
                                                      }
                                                      acceptablelength={
                                                        question?.responsData
                                                          ?.acceptable?.length
                                                      }
                                                      isAutoSearchUserLoading={
                                                        props?.isAutoSearchUserLoading
                                                      }
                                                      handleAllUserSearch={
                                                        props?.handleAllUserSearch
                                                      }
                                                      allUsers={props?.allUsers}
                                                      assets={props?.assets}
                                                      setImage={props?.setImage}
                                                      deleteImageURLS={
                                                        props?.deleteImageURLS
                                                      }
                                                    />
                                                  );
                                                }
                                              )}
                                              <Grid
                                                className="inputGrid"
                                                mt={1}
                                                mb={3}
                                                gap={2}
                                              >
                                                <Buttons
                                                  primary={true}
                                                  label={"Add Kudos"}
                                                  startIcon={
                                                    props?.iskudos ? (
                                                      <Remove />
                                                    ) : (
                                                      <Add />
                                                    )
                                                  }
                                                  disabled={
                                                    question?.responsData
                                                      ?.acceptable?.length === 4
                                                      ? true
                                                      : false
                                                  }
                                                  onClick={(e) =>
                                                    props?.handleFormChange(
                                                      0,
                                                      question,
                                                      subcategory,
                                                      category,
                                                      "kudos"
                                                    )
                                                  }
                                                />
                                              </Grid>
                                            </div>
                                          )}

                                          {parseInt(
                                            question?.radioSelectiovalue
                                          ) ===
                                            QUESTION_ANSWER_RESPONSE_TYPE.NOTACCEPTABLE && (
                                            <div className="commonWrapper">
                                              {question?.responsData?.notacceptable?.map(
                                                (notacc, notaccIndex) => {
                                                  return (
                                                    <NotAcceptable
                                                      key={notaccIndex}
                                                      question={question}
                                                      subcategory={subcategory}
                                                      category={category}
                                                      acc={notacc}
                                                      handleFormChange={
                                                        props?.handleFormChange
                                                      }
                                                      notacceptablelength={
                                                        question?.responsData
                                                          ?.notacceptable
                                                          ?.length
                                                      }
                                                      isAutoSearchUserLoading={
                                                        props?.isAutoSearchUserLoading
                                                      }
                                                      handleAllUserSearch={
                                                        props?.handleAllUserSearch
                                                      }
                                                      allUsers={props?.allUsers}
                                                      assets={props?.assets}
                                                      setImage={props?.setImage}
                                                      deleteImageURLS={
                                                        props?.deleteImageURLS
                                                      }
                                                    />
                                                  );
                                                }
                                              )}
                                              <Grid
                                                className="inputGrid"
                                                mt={1}
                                                mb={3}
                                                gap={2}
                                              >
                                                <Buttons
                                                  primary={true}
                                                  label={"Add Corrective Item"}
                                                  startIcon={
                                                    !props?.iscorrectiveitem ? (
                                                      <Add />
                                                    ) : (
                                                      <Remove />
                                                    )
                                                  }
                                                  redBg={true}
                                                  disabled={
                                                    question?.responsData
                                                      ?.notacceptable
                                                      ?.length === 4
                                                      ? true
                                                      : false
                                                  }
                                                  onClick={(e) =>
                                                    props?.handleFormChange(
                                                      0,
                                                      question,
                                                      subcategory,
                                                      category,
                                                      "addcorrectiveitem"
                                                    )
                                                  }
                                                />
                                              </Grid>
                                            </div>
                                          )}
                                        </>
                                      ) : question.responsetype ===
                                        QUESTION_RESPONSE_TYPE.TEXTBOX ? (
                                        <div className="commonWrapper">
                                          <TextEditor
                                            value={
                                              question?.textAreaDetail ?? ""
                                            }
                                            placeholder={"Enter Here..."}
                                            name="freeText"
                                            onChange={(e) =>
                                              props?.handleFormChange(
                                                e,
                                                question,
                                                subcategory,
                                                category,
                                                "textAreaDetail"
                                              )
                                            }
                                          />
                                        </div>
                                      ) : question.responsetype ===
                                        QUESTION_RESPONSE_TYPE.SINGLE_SELECT ? (
                                        <div className="commonWrapper">
                                          <Grid item md={5} xs={12}>
                                            <SelectMenu
                                              listData={question?.options}
                                              value={
                                                question?.singleSelect ?? 0
                                              }
                                              onchangehandler={(e) =>
                                                props?.handleFormChange(
                                                  e.target.value,
                                                  question,
                                                  subcategory,
                                                  category,
                                                  "singleSelect"
                                                )
                                              }
                                              placeholder="Please Select"
                                            />
                                          </Grid>
                                        </div>
                                      ) : question.responsetype ===
                                        QUESTION_RESPONSE_TYPE.MULTI_SELECT ? (
                                        <div className="commonWrapper">
                                          <Grid item md={5} xs={12}>
                                            <FormControl fullWidth>
                                              <MultiSelectBox
                                                placeholder={"Please Select"}
                                                listData={question?.options}
                                                value={
                                                  question?.multiSelect ?? []
                                                }
                                                onChange={(e) =>
                                                  props?.handleFormChange(
                                                    e?.target?.value,
                                                    question,
                                                    subcategory,
                                                    category,
                                                    "multiSelect"
                                                  )
                                                }
                                              />
                                            </FormControl>
                                          </Grid>
                                        </div>
                                      ) : null}
                                    </li>

                                    {question.isQuestionError && (
                                      <p className="error erroor-bg">
                                        Question is required
                                      </p>
                                    )}
                                  </>
                                )
                              )}
                            </ul>
                          </AccordionDetails>
                        </Accordion>
                        <div>
                          {subcategory.subCatError && (
                            <p className="error">
                              All Question are required xx.
                            </p>
                          )}
                        </div>
                      </>
                    )
                  )}
                </AccordionDetails>
              </Accordion>
              <div>
                {category.catError && (
                  <p className="error">All Question are required.</p>
                )}
              </div>
            </div>
          );
        })}
      </>
    </>
  );
};

export default Categorys;