import React, { useEffect } from "react";
import ViewUser from "../component/users/viewUser";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import * as userAction from "../../../redux/actions/userAction";
import { ORG_ID } from "../../../constant/constant";
import { useSelector } from "react-redux";
import Loader from "../../../library/common/Loader";

function ViewUserContainer() {
  const dispatch = useDispatch();
  const location = useLocation();
  const userid = location?.state?.userDetails?.userid;
  const getUserListDetsilsData = useSelector(
    (state) => state?.getUserListDetsilsData?.data
  );
  const showLoader = useSelector(
    (state) => state?.getUserListDetsilsData?.isLoading
  );
  const getUserListDetail = () => {
    const data = {
      userId: userid,
      orgId: ORG_ID(),
    };
    dispatch(userAction.getUserListDetsils(data));
  };
  useEffect(() => {
    getUserListDetail();
  }, [dispatch]);

  return (
    <>
      {showLoader && <Loader />}
      <ViewUser getUserListDetsilsData={getUserListDetsilsData} />
    </>
  );
}

export default ViewUserContainer;
