import React, { useEffect, useState, useRef } from "react";
import "./inspections.scss";
import { FormControl, Grid } from "@mui/material";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import DatePicker from "../../../../library/custom/datePicker/DatePicker";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import { ASSETTYPE } from "../../../../constant/inspectionConstant";
import {
  downloadFullPDF,
  getAssetTypeValue,
  getDateRange,
  getDayNameAndDate,
  getFormatedDate,
  getLabelByAssetType,
  getNoOptionsTextByAssetType,
} from "../../../../utils/helper";
import TopSection from "../../../../library/topSection/topSection";
import {
  COLORS,
  GET_ITEM_LOG,
  ORG_ID,
  TIMEPERIOD,
  TOP_FIVE_INSPECTOR,
  TOP_FIVE_SUB_CONTRACTORS,
  TOP_FIVE_SUB_CONTRACTORS_KUDOS,
  TOP_FIVE_USERS_CORRECTIVE,
  TOP_FIVE_USERS_KUDOS,
} from "../../../../constant/constant";
import { useNavigate } from "react-router-dom";
import {
  REPORTS_ANALYTICS_DASHBOARD_Detail,
  REPORTS_ANALYTICS_DASHBOARD_ITEMS,
  VIEW_INSPECTION_REPORT,
} from "../../../../constant/routeContant";
import BarChart from "../../../../library/custom/charts/barchart";
import PieChart from "../../../../library/custom/charts/piechart";
import Doughnutchart from "../../../../library/custom/charts/doughnutchart";
import LineChart from "../../../../library/custom/charts/linechart";
import DownloadIcon from "@mui/icons-material/Download";
import { Button } from "@mui/material";
import SearchAutoComplete2 from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import Loader from "../../../../library/common/Loader";
import Buttons from "../../../../library/custom/button/Button";
import { PRODUCT_ID } from "../../../../constant/storm-water/constant";
import { useThemeContext } from "../../../../context";

const Inspections = (props) => {
  console.log("uat deploy");

  const navigate = useNavigate();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [assetType, setAssetType] = useState();
  const [timePeriod, setTimePeriod] = useState(2);
  const [divisionId, setDivisionId] = useState("AllDivision");
  const [assets, setAssets] = useState();
  const [divisions, setDivisions] = useState([]);
  const [categoryId, setCategoryId] = useState("AllCategory");

  const { mode } = useThemeContext();
  const autoSearch = useRef(null);

  const handleAssetOnChange = (val) => {
    setAssets(val);
  };

  const handleAssetChange = (e) => {
    setAssetType(e?.target?.value);
    const value = e?.target?.value;

    const dateRange = getDateRange(
      timePeriod === 1
        ? 7
        : timePeriod === 2
        ? 30
        : timePeriod === 3
        ? 60
        : timePeriod === 4
        ? 90
        : null
    );
    let flags = [6];
    if (value === 2) {
      flags.push(10);
    } else if (value === 3) {
      flags.push(11);
    } else if (value === 4) {
      flags.push(13);
    }
    const getDropdownDataKeyValueData = {
      orgId: ORG_ID(),
      flags: flags,
      productId: PRODUCT_ID(),
    };
    props?.getDivisionData(getDropdownDataKeyValueData);
    setStartDate(getFormatedDate(dateRange.startDate));
    setEndDate(getFormatedDate(dateRange.endDate));
    setTimePeriod(2);
    setAssets();
    setCategoryId("AllCategory");
    setDivisionId("AllDivision");
  };

  const handleCategoryIdChange = (e) => {
    setCategoryId(e?.target?.value);
  };

  const handleDivisionChange = (e) => {
    setDivisionId(e.target.value);
  };
  const handleTimePeriodChange = (e) => {
    const selectedPeriod = e?.target?.value;
    setTimePeriod(selectedPeriod);
    const dateRange = getDateRange(
      selectedPeriod === 1
        ? 7
        : selectedPeriod === 2
        ? 30
        : selectedPeriod === 3
        ? 60
        : selectedPeriod === 4
        ? 90
        : null
    );
    setStartDate(getFormatedDate(dateRange.startDate));
    setEndDate(getFormatedDate(dateRange.endDate));
  };
  const onchangeStartdatePicker = (e) => {
    setStartDate(e);
  };
  const onchangeEnddatePicker = (e) => {
    setEndDate(e);
  };

  useEffect(() => {
    if (assetType && divisionId && startDate && endDate) {
      const data = {
        assetType: assetType,
        customAssetType: "",
        startDate: getFormatedDate(startDate),
        endDate: getFormatedDate(endDate),
        divisionId: divisionId === "AllDivision" ? 0 : divisionId,
        assetId: assets?.key ?? 0,
        assetCatId: categoryId === "AllCategory" ? 0 : categoryId,
      };
      props?.getInspecionReportData(data);
    }
  }, [assetType, divisionId, assets, startDate, endDate, categoryId]);

  const drilldownHandler = (action, reportDetails, trendData, alldata) => {
    const data = {
      assetType: assetType,
      customAssetType: "",
      startDate: getFormatedDate(startDate),
      endDate: getFormatedDate(endDate),
      divisionId: divisionId === "AllDivision" ? 0 : divisionId,
      assetId: assets?.key,
      assetValue: assets?.value,
      timePeriod: timePeriod,
      previousEndDate: props?.inspecionReport?.previousEndDate,
      previousStartDate: props?.inspecionReport?.previousStartDate,
      assetCatId: categoryId === "AllCategory" ? 0 : categoryId,
    };

    if (action === "totalInspection" && reportDetails === 25) {
      navigate(VIEW_INSPECTION_REPORT, {
        state: {
          assetType: assetType,
          fromDate: startDate,
          toDate: endDate,
          status: 5,
        },
      });
    } else if (action === "totalkudos") {
      navigate(REPORTS_ANALYTICS_DASHBOARD_ITEMS, {
        state: {
          assetId: "",
          drillDetail: { apiData: data },
          itemLog: GET_ITEM_LOG.Kudos,
          itemTypeValue: "Kudo",
          page: "dashboardIns",
        },
      });
    } else if (action === "totalcorrectiveitem") {
      navigate(REPORTS_ANALYTICS_DASHBOARD_ITEMS, {
        state: {
          assetId: "",
          drillDetail: { apiData: data },
          itemLog: GET_ITEM_LOG.Corrective,
          itemTypeValue: "Corrective",
          page: "dashboardIns",
        },
      });
    } else {
      navigate(REPORTS_ANALYTICS_DASHBOARD_Detail, {
        state: {
          page: action,
          apiData: data,
          reportDetails: reportDetails,
          alldata: alldata,
          trendPassData: trendData,
          tab: props?.tab,
        },
      });
    }
  };

  const topAssetList = {
    labels: props?.inspecionReport?.topAssetList?.map((itm) => itm.assetName),
    datasets: [
      {
        label: getAssetTypeValue(assetType),
        data: props?.inspecionReport?.topAssetList?.map((item) => item?.count),
        backgroundColor: [COLORS.BrightAqua],
        borderColor: [COLORS.BrightAqua],
        borderWidth: 1,
        color: COLORS.White,
      },
    ],
  };

  const data = props?.inspecionReport?.comparisonChart || [];
  const [currentPage, setCurrentPage] = useState(0);
  const ITEMS_PER_PAGE = 7;

  const getPaginatedData = () => {
    const start = currentPage * ITEMS_PER_PAGE;
    const end = start + ITEMS_PER_PAGE;
    return data.slice(start, end);
  };

  const paginatedData = getPaginatedData();

  const InspectedDay = {
    labels: paginatedData.map((item) => getDayNameAndDate(item?.date)),
    datasets: [
      {
        label: getAssetTypeValue(props?.trendData?.apiData?.assetType),
        data: paginatedData.map((item) => item?.totalInspectionCount),
        backgroundColor: [COLORS.BrightAqua],
        borderColor: [COLORS.BrightAqua],
        borderWidth: 1,
        color: COLORS.White,
      },
    ],
  };

  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if ((currentPage + 1) * ITEMS_PER_PAGE < data.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const optionsDay = {
    indexAxis: "x",
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: "white",
        anchor: "end",
        align: "end",
        formatter: function (value, context) {
          return truncateLabel(context.chart.data.labels[context.dataIndex]);
        },
      },
      scales: {
        x: {
          stacked: false,
          ticks: {
            color: mode === "dark" ? COLORS.White : COLORS.Dune,
            autoSkip: false, // Prevents skipping of labels
          },
        },
        y: {
          color: mode === "dark" ? COLORS.White : COLORS.Dune,
          stacked: false,
        },
      },
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const label = data.labels[tooltipItem.index];
          return label;
        },
      },
    },
  };

  const topCategoryList = {
    labels: props?.inspecionReport?.topCategoryList?.map((itm) => itm.catName),
    datasets: [
      {
        label: "Categorys",
        data: props?.inspecionReport?.topCategoryList?.map(
          (item) => item?.count
        ),
        backgroundColor: [COLORS.BrightAqua],
        borderColor: [COLORS.BrightAqua],
        borderWidth: 1,
        color: COLORS.White,
      },
    ],
  };

  const inspectionsCompleted = {
    labels: props?.inspecionReport?.inspectionCompletedByAsset?.map(
      (itm) => itm.assetName
    ),
    datasets: [
      {
        label: "inspection Completed",
        data: props?.inspecionReport?.inspectionCompletedByAsset?.map(
          (item) => item?.count
        ),
        backgroundColor: [COLORS.BrightAqua],
        borderColor: [COLORS.BrightAqua],
        borderWidth: 1,
        color: COLORS.White,
      },
    ],
  };

  const inspectionsDoneByUser = {
    labels: props?.inspecionReport?.inspectionCompletedByPerInspector?.map(
      (itm) => itm.userName
    ),
    datasets: [
      {
        label: "inspection CompletedBy Per Inspector",
        data: props?.inspecionReport?.inspectionCompletedByPerInspector?.map(
          (item) => item?.count
        ),
        backgroundColor: [COLORS.BrightAqua],
        borderColor: [COLORS.BrightAqua],
        borderWidth: 1,
        color: COLORS.White,
      },
    ],
  };

  const pietopCategoryList = {
    labels: props?.inspecionReport?.topCategoryList?.map((itm) => itm.catName),
    datasets: [
      {
        label: "Category",
        data: props?.inspecionReport?.topCategoryList?.map(
          (item) => item?.count
        ),
        backgroundColor: [
          COLORS.MediumGreen,
          COLORS.BlueDress,
          COLORS.BrightAqua,
          COLORS.NeonPurple,
          COLORS.RustOrange,
        ],
        borderColor: [
          COLORS.MediumGreen,
          COLORS.BlueDress,
          COLORS.BrightAqua,
          COLORS.NeonPurple,
          COLORS.RustOrange,
        ],
        borderWidth: 1,
        borderRadius: "25px",
        color: COLORS.White,
      },
    ],
  };

  const topSubCategoryList = {
    labels: props?.inspecionReport?.topSubCategoryList?.map(
      (itm) => itm.subCatName
    ),
    datasets: [
      {
        label: "Sub Category",
        data: props?.inspecionReport?.topSubCategoryList?.map(
          (item) => item?.count
        ),
        backgroundColor: [COLORS.BrightAqua],
        borderColor: [COLORS.BrightAqua],
        borderWidth: 1,
        color: COLORS.White,
      },
    ],
  };

  const pietopSubCategoryList = {
    labels: props?.inspecionReport?.topSubCategoryList?.map(
      (itm) => itm.subCatName
    ),
    datasets: [
      {
        label: "SubCategory",
        data: props?.inspecionReport?.topSubCategoryList?.map(
          (item) => item?.count
        ),
        backgroundColor: [
          COLORS.MediumGreen,
          COLORS.BlueDress,
          COLORS.BrightAqua,
          COLORS.NeonPurple,
          COLORS.RustOrange,
        ],
        borderColor: [
          COLORS.MediumGreen,
          COLORS.BlueDress,
          COLORS.BrightAqua,
          COLORS.NeonPurple,
          COLORS.RustOrange,
        ],
        borderWidth: 1,
        borderRadius: "25px",
        color: COLORS.White,
      },
    ],
  };

  const treandingPrevSubCategory = {
    labels: ["Kudos", "Corrective"],
    datasets: [
      {
        label: "SubCategory",
        data: [
          props?.inspecionReport?.totalKudosPreviousCountBySubCategory,
          props?.inspecionReport?.totalCorrectivePreviousCountBySubCategory,
        ],
        backgroundColor: [COLORS.MediumGreen, COLORS.RubyRed],
        borderColor: [COLORS.MediumGreen, COLORS.RubyRed],
        borderWidth: 1,
        borderRadius: "25px",
        color: COLORS.White,
      },
    ],
  };

  const treandingCurrentSubCategory = {
    labels: ["Kudos", "Corrective"],
    datasets: [
      {
        label: "SubCategory",
        data: [
          props?.inspecionReport?.totalKudosTargetCountBySubCategory,
          props?.inspecionReport?.totalCorrectiveTargetCountBySubCategory,
        ],
        backgroundColor: [COLORS.MediumGreen, COLORS.RubyRed],
        borderColor: [COLORS.MediumGreen, COLORS.RubyRed],
        borderWidth: 1,
        borderRadius: "25px",
        color: COLORS.White,
      },
    ],
  };

  const treandingPreCategory = {
    labels: ["Kudos", "Corrective"],
    datasets: [
      {
        label: "Category",
        data: [
          props?.inspecionReport?.totalKudosPreviousCount,
          props?.inspecionReport?.totalCorrectivePreviousCount,
        ],
        backgroundColor: [COLORS.MediumGreen, COLORS.RubyRed],
        borderColor: [COLORS.MediumGreen, COLORS.RubyRed],
        borderWidth: 1,
        borderRadius: "25px",
        color: COLORS.White,
      },
    ],
  };

  const treandingCurrentCategory = {
    labels: ["Kudos", "Corrective"],
    datasets: [
      {
        label: "Category",
        data: [
          props?.inspecionReport?.totalKudosTargetCount,
          props?.inspecionReport?.totalCorrectiveTargetCount,
        ],
        backgroundColor: [COLORS.MediumGreen, COLORS.RubyRed],
        borderColor: [COLORS.MediumGreen, COLORS.RubyRed],
        borderWidth: 1,
        borderRadius: "25px",
        color: COLORS.White,
      },
    ],
  };
  const truncateLabel = (label, maxLength = 30) => {
    if (label?.length <= maxLength) {
      return label;
    }
    return `${label?.substring(0, maxLength)}...`;
  };

  const options = {
    indexAxis: "x",
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: "white",
        anchor: "end",
        align: "end",
        formatter: function (value, context) {
          return truncateLabel(context.chart.data.labels[context.dataIndex]);
        },
      },
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const label = data.labels[tooltipItem.index];
          return label;
        },
      },
    },
    scales: {
      x: {
        stacked: false,
        ticks: {
          color: mode === "dark" ? COLORS.White : COLORS.Dune,
        },
      },
      y: {
        stacked: false,
        ticks: {
          color: mode === "dark" ? COLORS.White : COLORS.Dune,
        },
      },
    },
  };

  const pieoptions = {
    tooltips: {
      enabled: false,
    },
    responsive: true,

    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          color: mode === "dark" ? COLORS.White : COLORS.Dune,
          usePointStyle: true,
          pointStyle: "circle",
          useBorderRadius: true,
          pointStyleWidth: 11,
          boxHeight: 8,
          height: "5px",
          borderWidth: 1,
          padding: 20,
          font: {
            size: "12px",
            weight: "500",
            color: COLORS.Dune,
          },
        },
      },

      datalabels: {
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.forEach((data) => {
            sum += data;
          });
          let percentage = ((value * 100) / sum).toFixed(0) + "%";
          return percentage === "0%" ? "" : percentage;
        },
        color: mode === "dark" ? COLORS.White : COLORS.Dune,
        font: {
          size: 12,
          weight: 500,
        },
      },

      onHover: function (e) {
        e.native.target.style.cursor = "pointer";
      },
      rotation: 270,
      events: ["click"],
      onClick: function (event, element) {},
    },
  };

  const doughnutoptions = {
    tooltips: {
      enabled: false,
    },
    responsive: true,

    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          color: mode === "dark" ? COLORS.White : COLORS.Dune,
          usePointStyle: true,
          pointStyle: "circle",
          useBorderRadius: true,
          pointStyleWidth: 11,
          boxHeight: 8,
          height: "5px",
          borderWidth: 1,
          padding: 20,
          font: {
            size: "12px",
            weight: "500",
            color: COLORS.Dune,
          },
        },
      },

      datalabels: {
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.forEach((data) => {
            sum += data;
          });
          let percentage = ((value * 100) / sum).toFixed(0) + "%";
          return percentage === "0%" ? "" : percentage;
        },
        color: COLORS.White,
        font: {
          size: 12,
          weight: 500,
        },
      },

      onHover: function (e) {
        e.native.target.style.cursor = "pointer";
      },
      rotation: 270,
      events: ["click"],
      onClick: function (event, element) {},
    },
  };

  const formatDate = (date) => {
    const day = date?.getDate()?.toString()?.padStart(2, "0");
    const month = (date?.getMonth() + 1)?.toString()?.padStart(2, "0");
    const year = date?.getFullYear();
    return `${month}-${day}-${year}`;
  };

  const getDaysDifference = (startDate, endDate) => {
    return Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
  };

  const generateDateRange = (startDate, endDate) => {
    const dates = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      dates?.push(new Date(currentDate));
      currentDate?.setDate(currentDate?.getDate() + 1);
    }
    return dates;
  };

  const aggregateData = (data) => {
    const aggregated = {};
    const startDate = new Date(data[0]?.date);
    const endDate = new Date(data[data.length - 1]?.date);

    const daysDifference = getDaysDifference(startDate, endDate);
    const isDaily = daysDifference <= 60;
    const allDates = generateDateRange(startDate, endDate);
    allDates.forEach((date) => {
      const year = date?.getFullYear();
      const month = (date?.getMonth() + 1)?.toString().padStart(2, "0");
      const day = date?.getDate()?.toString()?.padStart(2, "0");
      const key = isDaily ? `${year}-${month}-${day}` : `${year}-${month}`;
      aggregated[key] = {
        year,
        month,
        day: isDaily ? day : undefined,
        fullDate: isDaily ? formatDate(date) : `${month}-${year}`,
        kudosCount: 0,
        correctiveCount: 0,
        notApplicableCount: 0,
      };
    });

    data.forEach((entry) => {
      const date = new Date(entry?.date);
      const year = date?.getFullYear();
      const month = (date?.getMonth() + 1)?.toString()?.padStart(2, "0");
      const day = date?.getDate()?.toString()?.padStart(2, "0");
      const key = isDaily ? `${year}-${month}-${day}` : `${year}-${month}`;

      if (aggregated[key]) {
        aggregated[key].kudosCount += entry.kudosCount || 0;
        aggregated[key].correctiveCount += entry.correctiveCount || 0;
        aggregated[key].notApplicableCount += entry.notApplicableCount || 0;
      }
    });

    return Object.values(aggregated);
  };

  const generateLineChartData = (data) => {
    const aggregatedData = aggregateData(data);

    return {
      labels: aggregatedData.map((entry) => entry.fullDate),
      datasets: [
        {
          label: "Kudos",
          data: aggregatedData.map((entry) => entry.kudosCount || 0),
          borderColor: COLORS.MediumGreen,
          backgroundColor: COLORS.MediumGreen,
          borderWidth: 2,
          fill: false,
        },
        {
          label: "Corrective",
          data: aggregatedData.map((entry) => entry.correctiveCount || 0),
          borderColor: COLORS.RubyRed,
          backgroundColor: COLORS.RubyRed,
          borderWidth: 2,
          fill: false,
        },
        {
          label: "N/A",
          data: aggregatedData.map((entry) => entry.notApplicableCount || 0),
          borderColor: COLORS.IronsideGrey,
          backgroundColor: COLORS.IronsideGrey,
          borderWidth: 2,
          fill: false,
        },
      ],
    };
  };

  const linechartoptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          color: mode === "dark" ? COLORS.White : COLORS.Dune,
          font: {
            family: "Lato",
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          color: mode === "dark" ? COLORS.White : COLORS.Dune,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          color: mode === "dark" ? COLORS.White : COLORS.Dune,
        },
      },
    },
  };

  useEffect(() => {
    if (props?.backDetail?.backpage === "backpage") {
      setAssetType(props?.backDetail?.apiData?.assetType);
      setAssets(
        props?.backDetail?.apiData?.assetId
          ? {
              key: props?.backDetail?.apiData?.assetId,
              value: props?.backDetail?.apiData?.assetValue,
            }
          : { key: "", value: "" }
      );
      setDivisionId(
        props?.backDetail?.apiData?.divisionId === 0
          ? "AllDivision"
          : props?.backDetail?.apiData?.divisionId
      );
      setStartDate(props?.backDetail?.apiData?.startDate);
      setEndDate(props?.backDetail?.apiData?.endDate);
      setTimePeriod(props?.backDetail?.apiData?.timePeriod);
      setCategoryId(
        props?.backDetail?.apiData?.assetCatId === 0
          ? "AllCategory"
          : props?.backDetail?.apiData?.assetCatId
      );
    }

    let flags = [6];
    if (props?.backDetail?.apiData?.assetType === 2) {
      flags.push(10);
    } else if (props?.backDetail?.apiData?.assetType === 3) {
      flags.push(11);
    } else if (props?.backDetail?.apiData?.assetType === 4) {
      flags.push(13);
    }
    const getDropdownDataKeyValueData = {
      orgId: ORG_ID(),
      flags: flags,
      productId: PRODUCT_ID(),
    };
    props?.getDivisionData(getDropdownDataKeyValueData);
  }, [props?.backDetail]);

  useEffect(() => {
    props?.divisions?.unshift({ key: "AllDivision", value: "All Divisions" });
    setDivisions(props?.divisions);
  }, [props?.divisions]);

  return (
    <>
      <div id="pdf-loader" class="loader pdf-loader">
        <Loader />
      </div>
      <div className="overviewIns overviewInsReport" id="pdf-content">
        <div id="first-page" className="w-100">
          <Grid container>
            <Grid item md={6} xs={12}>
              <h1>Overview</h1>
            </Grid>
            {assetType && divisionId && startDate && endDate && (
              <Grid item md={6} xs={12} className="hideinpdf">
                <Button
                  onClick={() =>
                    downloadFullPDF(
                      "pdf-content",
                      "inspection.pdf",
                      [2, 3]?.includes(assetType)
                        ? [
                            "first-page",
                            "second-page",
                            "third-page",
                            "four-page",
                          ]
                        : ["first-page", "second-page", "third-page"]
                    )
                  }
                  variant="contained"
                  startIcon={<DownloadIcon className="downloadIcon" />}
                  className="downloadpdf sw marginRemove float_right"
                >
                  Download as PDF
                </Button>
              </Grid>
            )}

            <Grid container mt={2} spacing={2}>
              <Grid md={4} xs={12} item className="pdfmrg">
                <FormControl fullWidth>
                  <FormLabels label="Asset Type" isRequired={false} />
                  <SelectMenu
                    listData={ASSETTYPE}
                    value={assetType || ""}
                    onchangehandler={(e) => handleAssetChange(e)}
                  />
                </FormControl>
              </Grid>
            </Grid>
            {assetType && (
              <Grid container mt={2} spacing={2}>
                <Grid md={4} xs={12} item>
                  <FormControl fullWidth>
                    <FormLabels label="Division" isRequired={false} />
                    <SelectMenu
                      placeholder="Please Select"
                      listData={divisions}
                      value={divisionId}
                      onchangehandler={(e) => handleDivisionChange(e)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            )}
            {[2, 3, 4]?.includes(assetType) && (
              <Grid container mt={2} spacing={2}>
                <Grid md={4} xs={12} item>
                  <FormControl fullWidth>
                    <FormLabels
                      label={
                        assetType === 3
                          ? "Vehicle Category"
                          : assetType === 2
                          ? "Equipment Category"
                          : assetType === 4
                          ? "Custom Asset Category"
                          : []
                      }
                      isRequired={false}
                    />
                    <SelectMenu
                      listData={
                        assetType === 3
                          ? props?.vehicleCategories
                          : assetType === 2
                          ? props?.equipmentCategories
                          : assetType === 4
                          ? props?.customAssetCategories
                          : []
                      }
                      value={categoryId}
                      onchangehandler={(e) => handleCategoryIdChange(e)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            )}
            {assetType && (
              <Grid container mt={2} spacing={2}>
                <Grid md={4} xs={12} item className="customLableIns">
                  <FormLabels
                    label={
                      assetType === 2
                        ? "Make And Model And Serial Number"
                        : `${getLabelByAssetType(assetType)} Name`
                    }
                    isRequired={false}
                  />
                  <SearchAutoComplete2
                    optionsData={
                      props?.isAssetLoading ? [] : props?.assetOption ?? []
                    }
                    isKeyValuePair={true}
                    value={assets}
                    isLoading={props?.isAssetLoading}
                    noOptionsTextMsg={getNoOptionsTextByAssetType(assetType)}
                    typeOnchange={(event) =>
                      props?.searchAsset(
                        event.target.value,
                        assetType,
                        categoryId === "AllCategory" ? 0 : categoryId,
                        divisionId === "AllDivision" ? 0 : divisionId
                      )
                    }
                    onChange={(e, value) => handleAssetOnChange(value)}
                    autoCompClear={autoSearch}
                  />
                </Grid>
              </Grid>
            )}

            <Grid container mt={2} spacing={2}>
              {assetType && divisionId && (
                <>
                  <Grid md={4} xs={12} item>
                    <FormControl fullWidth>
                      <FormLabels label="Time Period" isRequired={false} />
                      <SelectMenu
                        listData={TIMEPERIOD}
                        value={timePeriod || ""}
                        onchangehandler={(e) => handleTimePeriodChange(e)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item md={12}>
                    {assetType && divisionId && timePeriod === 5 ? (
                      <>
                        <Grid item container spacing={2} mb={2} mt={1}>
                          <Grid item lg={4} xs={12} sm={12} md={4}>
                            <FormLabels label={"From"} isRequired={false} />
                            <DatePicker
                              maxDateEnable={false}
                              value={startDate}
                              disabled={false}
                              onChangeHandler={(e) =>
                                onchangeStartdatePicker(e)
                              }
                            />
                          </Grid>
                          <Grid item lg={4} xs={12} sm={12} md={4}>
                            <FormLabels label={"To"} isRequired={false} />
                            <DatePicker
                              maxDateEnable={false}
                              minDate={startDate}
                              value={endDate}
                              disabled={false}
                              onChangeHandler={(e) => onchangeEnddatePicker(e)}
                            />
                          </Grid>
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                </>
              )}
            </Grid>
            {assetType && divisionId && startDate && endDate && (
              <Grid container spacing={2} mt={2}>
                <Grid
                  item
                  md={8}
                  xs={12}
                  onClick={() => drilldownHandler("projects", 1)}
                >
                  <Grid className="commonBoxShodow cursor">
                    <h2 className="titletop">
                      Top 5 {getAssetTypeValue(assetType)} (Corrective)
                    </h2>
                    <BarChart
                      labels={topAssetList.labels}
                      datasets={topAssetList.datasets}
                      options={options}
                      height="auto"
                    />
                  </Grid>
                </Grid>
                <Grid item md={4} xs={12} className="cursor">
                  <Grid
                    md={12}
                    item
                    onClick={() => drilldownHandler("inspectorcorrective", 4)}
                  >
                    <TopSection
                      title={"Top 5 Inspectors"}
                      column={TOP_FIVE_INSPECTOR}
                      rows={props?.inspecionReport?.topInspectorList}
                    />
                  </Grid>

                  <Grid
                    className="commonBoxShodow cursor"
                    mt={2}
                    md={12}
                    onClick={() => drilldownHandler("totalInspection", 25)}
                  >
                    <div className="titletop small">Total Inspections</div>
                    <div className="discription total">
                      {props?.inspecionReport?.totalInspection}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </div>

        {assetType && divisionId && startDate && endDate && (
          <>
            <div id="second-page" className="w-100">
              <Grid container spacing={2} mt={2}>
                <Grid
                  item
                  md={8}
                  xs={12}
                  onClick={() => drilldownHandler("categoriescorrective", 2)}
                >
                  <Grid className="commonBoxShodow cursor">
                    <h2 className="titletop">Top 5 Categories (Corrective)</h2>
                    <BarChart
                      labels={topCategoryList.labels}
                      datasets={topCategoryList.datasets}
                      options={options}
                      height="auto"
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={12}
                  onClick={() => drilldownHandler("categoriescorrective", 2)}
                >
                  <Grid className="commonBoxShodow cursor">
                    <h2 className="titletop">
                      Category Comparison(Corrective)
                    </h2>
                    <PieChart
                      labels={pietopCategoryList.labels}
                      datasets={pietopCategoryList.datasets}
                      options={pieoptions}
                      height="auto"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={2} mt={2}>
                <Grid
                  item
                  md={8}
                  xs={12}
                  onClick={() => drilldownHandler("subCategoriescorrective", 3)}
                >
                  <Grid className="commonBoxShodow cursor">
                    <h2 className="titletop">
                      Top 5 Sub Categories (Corrective)
                    </h2>
                    <BarChart
                      labels={topSubCategoryList.labels}
                      datasets={topSubCategoryList.datasets}
                      options={options}
                      height="auto"
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={12}
                  onClick={() => drilldownHandler("subCategoriescorrective", 3)}
                >
                  <Grid className="commonBoxShodow cursor">
                    <h2 className="titletop">
                      Sub Category Comparison(Corrective)
                    </h2>
                    <PieChart
                      labels={pietopSubCategoryList.labels}
                      datasets={pietopSubCategoryList.datasets}
                      options={pieoptions}
                      height="auto"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={2}>
                <Grid item md={9} xs={12}>
                  <Grid className="commonBoxShodow">
                    <LineChart
                      labels={
                        generateLineChartData(
                          props?.inspecionReport?.comparisonChart || []
                        ).labels
                      }
                      datasets={
                        generateLineChartData(
                          props?.inspecionReport?.comparisonChart || []
                        ).datasets
                      }
                      options={linechartoptions}
                      height="auto"
                    />
                  </Grid>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Grid
                    className="commonBoxShodow cursor"
                    mb={2}
                    onClick={() => drilldownHandler("totalkudos")}
                  >
                    <div className="titletop small">Total Kudos</div>
                    <div className="discription green">
                      {props?.inspecionReport?.totalKudos}
                    </div>
                  </Grid>
                  <Grid
                    className="commonBoxShodow cursor"
                    mb={2}
                    onClick={() => drilldownHandler("totalcorrectiveitem")}
                  >
                    <div className="titletop small">Total Corrective Items</div>
                    <div className="discription red">
                      {props?.inspecionReport?.totalCorrective}
                    </div>
                  </Grid>
                  <Grid className="commonBoxShodow" mb={2}>
                    <div className="titletop small">Total N/A</div>
                    <div className="discription na">
                      {props?.inspecionReport?.totalNotApplicable}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <div id="third-page" className="mt-2 w-100">
              <Grid container spacing={2}>
                <Grid item md={8} xs={12}>
                  <Grid
                    className="commonBoxShodow cursor"
                    onClick={() =>
                      drilldownHandler(
                        "trendingcategories",
                        "",
                        props?.inspecionReport?.inspectionTrend
                      )
                    }
                  >
                    <h2 className="titletop">
                      Trending Categories: Positive vs Corrective Items
                    </h2>

                    <Grid container>
                      <Grid item md={6} xs={12}>
                        <Doughnutchart
                          labels={treandingPreCategory.labels}
                          datasets={treandingPreCategory.datasets}
                          options={doughnutoptions}
                          height="280px"
                        />
                        <div className="currentPrev">Previous Period</div>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Doughnutchart
                          labels={treandingCurrentCategory.labels}
                          datasets={treandingCurrentCategory.datasets}
                          options={doughnutoptions}
                          height="280px"
                        />
                        <div className="currentPrev">Current Period</div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    mt={2}
                    className="commonBoxShodow cursor"
                    onClick={() =>
                      drilldownHandler(
                        "trendingsubcategories",
                        "",
                        props?.inspecionReport?.inspectionTrendBySubCategory
                      )
                    }
                  >
                    <h2 className="titletop">
                      Trending Sub Categories: Positive vs Corrective Items
                    </h2>

                    <Grid container>
                      <Grid item md={6} xs={12}>
                        <Doughnutchart
                          labels={treandingPrevSubCategory.labels}
                          datasets={treandingPrevSubCategory.datasets}
                          options={doughnutoptions}
                          height="280px"
                        />
                        <div className="currentPrev">Previous Period</div>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Doughnutchart
                          labels={treandingCurrentSubCategory.labels}
                          datasets={treandingCurrentSubCategory.datasets}
                          options={doughnutoptions}
                          height="280px"
                        />
                        <div className="currentPrev">Current Period</div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    onClick={() =>
                      drilldownHandler("userCorrectiveAsInvolvedParty", 5)
                    }
                    className="cursor mb-2"
                  >
                    <TopSection
                      title={"Top 5 Users(Corrective)"}
                      column={TOP_FIVE_USERS_CORRECTIVE}
                      rows={props?.inspecionReport?.topCorrectiveUserList}
                    />
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    onClick={() =>
                      drilldownHandler("userKudosAsInvolvedParty", 6)
                    }
                    className="cursor mb-2"
                  >
                    <TopSection
                      title={"Top 5 Users(Kudos)"}
                      column={TOP_FIVE_USERS_KUDOS}
                      rows={props?.inspecionReport?.topKudosUserList}
                    />
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    onClick={() =>
                      drilldownHandler("subContractorKudosAsInvolvedParty", 8)
                    }
                    className="cursor mb-2"
                  >
                    <TopSection
                      title={"Top 5 Subcontractors(Kudos)"}
                      column={TOP_FIVE_SUB_CONTRACTORS_KUDOS}
                      rows={props?.inspecionReport?.topKudosSubContractorList}
                    />
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    onClick={() =>
                      drilldownHandler(
                        "subContractorCorrectiveAsInvolvedParty",
                        7
                      )
                    }
                    className="cursor mb-2"
                  >
                    <TopSection
                      title={"Top 5 Subcontractors(Corrective)"}
                      column={TOP_FIVE_SUB_CONTRACTORS}
                      rows={
                        props?.inspecionReport?.topCorrectiveSubContractorList
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              {[2, 3]?.includes(assetType) && (
                <Grid container mt={2}>
                  <Grid item md={9} xs={12} className="commonBoxShodow cursor">
                    <h2 className="titletop">
                      {getAssetTypeValue(assetType)} Inspected Per Day
                    </h2>
                    <div className="date-range">
                      Date Range{" "}
                      <span>
                        {paginatedData[0]?.date} {"-"} {paginatedData[6]?.date}
                      </span>
                    </div>
                    <div
                      onClick={() =>
                        drilldownHandler(
                          "inspectedperday",
                          "",
                          props?.inspecionReport?.comparisonChart
                        )
                      }
                    >
                      <BarChart
                        labels={InspectedDay.labels}
                        datasets={InspectedDay.datasets}
                        options={optionsDay}
                        height="300px"
                      />
                    </div>
                    <div className="pagination-controls hideinpdf">
                      <Buttons
                        label={"Previous"}
                        varientContained={true}
                        onClick={handlePrevious}
                        disabled={currentPage === 0}
                      />

                      <Buttons
                        label={"Next"}
                        varientContained={true}
                        onClick={handleNext}
                        disabled={
                          (currentPage + 1) * ITEMS_PER_PAGE >= data.length
                        }
                      />
                    </div>
                  </Grid>
                </Grid>
              )}
            </div>
            {[2, 3]?.includes(assetType) && (
              <div id="four-page" className="mt-2 w-100">
                <Grid container mt={2}>
                  <Grid
                    item
                    md={9}
                    xs={12}
                    className="commonBoxShodow cursor"
                    mt={2}
                    onClick={() =>
                      drilldownHandler("inspectioncompletedperassetdetails", 9)
                    }
                  >
                    <h2 className="titletop">
                      {assetType === 2 ? "Equipment" : "Vehicles"} Inspections
                      Completed
                    </h2>
                    <BarChart
                      labels={inspectionsCompleted.labels}
                      datasets={inspectionsCompleted.datasets}
                      options={options}
                      height="340px"
                    />
                  </Grid>

                  <Grid
                    item
                    md={9}
                    xs={12}
                    className="commonBoxShodow cursor"
                    mt={2}
                    onClick={() =>
                      drilldownHandler("inspectiondonebyuserdetails", 10)
                    }
                  >
                    <h2 className="titletop">Inspections Done By User</h2>
                    <BarChart
                      labels={inspectionsDoneByUser.labels}
                      datasets={inspectionsDoneByUser.datasets}
                      options={options}
                      height="340px"
                    />
                  </Grid>
                </Grid>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Inspections;
