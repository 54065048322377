import React, { useEffect, useState } from "react";
import "./lms.scss";
import { FormControl, Grid } from "@mui/material";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import {
  COLORS,
  LINE_CHART,
  LOWEST_PARTICIPATION_TRAINING,
  MOST_COMPLETE_TRAINING_USERS,
  MOST_INCOMPLETE_TRAINING_USERS,
  ORG_ID,
  TIMEPERIOD,
  TOP_FIVE_EXPIRED_CREDENTIALS,
  TOP_FIVE_HIGHEST_COMPLETED_TRAINING_USERS,
  TOP_FIVE_SUB_CONTRACTORS_WITH_MOST_SAFETY_MEETINGS,
  topfiveprojects,
  USERS_WITH_INCOMPLETE_TRAINING,
} from "../../../../constant/constant";
import {
  downloadFullPDF,
  getDateRange,
  getFormatedDate,
  getMonthNameFromDate,
} from "../../../../utils/helper";
import DatePicker from "../../../../library/custom/datePicker/DatePicker";
import TopSection from "../../../../library/topSection/topSection";
import LineChart from "../../../../library/custom/charts/linechart";
import BarChart from "../../../../library/custom/charts/barchart";
import {
  REPORTS_ANALYTICS_LMS_DASHBOARD_DETAILS,
  REPORTS_CREDENTIAL_COUNT,
} from "../../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import { PRODUCT_ID } from "../../../../constant/storm-water/constant";

import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import Loader from "../../../../library/common/Loader";
import { useThemeContext } from "../../../../context";
const Lms = ({
  divisions,
  getLmsDashboardReportData,
  getLMSDashboardReport,
  backDetail,
  getDivisionData,
  tab,
}) => {
  const navigate = useNavigate();
  const [division, setDivision] = useState("");
  const [timePeriod, setTimePeriod] = useState(2);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [divisionsList, setDivisionsList] = useState([]);

  const { mode } = useThemeContext();
  useEffect(() => {
    if (backDetail?.backpage === "backpage") {
      setDivision(
        backDetail?.apiData?.divisionId === 0
          ? "AllDivision"
          : backDetail?.apiData?.divisionId
      );

      setTimePeriod(backDetail?.apiData?.timePeriod);
      setStartDate(backDetail?.apiData?.startDate);
      setEndDate(backDetail?.apiData?.endDate);
      setTimePeriod(backDetail?.apiData?.timePeriod);
    }

    const getDropdownDataKeyValueData = {
      orgId: ORG_ID(),
      flags: "6",
      productId: PRODUCT_ID(),
    };
    getDivisionData(getDropdownDataKeyValueData);
  }, [backDetail]);

  useEffect(() => {
    divisions?.unshift({ key: "AllDivision", value: "All Division" });
    setDivisionsList(divisions);
  }, [divisions]);
  useEffect(() => {
    if (startDate && endDate) {
      const data = {
        startDate: getFormatedDate(startDate),
        endDate: getFormatedDate(endDate),
        divisionId: division === "AllDivision" ? 0 : division,
      };
      getLmsDashboardReportData(data);
    }
  }, [startDate, endDate, division]);

  const handleTimePeriodChange = (e) => {
    const selectedPeriod = e?.target?.value;
    setTimePeriod(selectedPeriod);
    const dateRange = getDateRange(
      selectedPeriod === 1
        ? 7
        : selectedPeriod === 2
        ? 30
        : selectedPeriod === 3
        ? 60
        : selectedPeriod === 4
        ? 90
        : null
    );
    setStartDate(getFormatedDate(dateRange.startDate));
    setEndDate(getFormatedDate(dateRange.endDate));
  };

  const onchangeStartdatePicker = (e) => {
    setStartDate(e);
  };
  const onchangeEnddatePicker = (e) => {
    setEndDate(e);
  };

  const onChangeDivision = (e) => {
    setDivision(e.target.value);
    // const dateRange = getDateRange(
    //   timePeriod === 1
    //     ? 7
    //     : timePeriod === 2
    //     ? 30
    //     : timePeriod === 3
    //     ? 60
    //     : timePeriod === 4
    //     ? 90
    //     : null
    // );
    // setStartDate(getFormatedDate(dateRange.startDate));
    // setEndDate(getFormatedDate(dateRange.endDate));
  };

  const formatDate = (date) => {
    const day = date?.getDate()?.toString()?.padStart(2, "0");
    const month = (date?.getMonth() + 1)?.toString()?.padStart(2, "0");
    const year = date?.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const getDaysDifference = (startDate, endDate) => {
    return Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
  };

  const generateDateRange = (startDate, endDate) => {
    const dates = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      dates?.push(new Date(currentDate));
      currentDate?.setDate(currentDate?.getDate() + 1);
    }
    return dates;
  };

  const aggregateData = (data) => {
    const aggregated = {};
    const startDate = new Date(data[0]?.date);
    const endDate = new Date(data[data.length - 1]?.date);
    const daysDifference = getDaysDifference(startDate, endDate);
    const isDaily = daysDifference <= 60;
    const allDates = generateDateRange(startDate, endDate);
    allDates.forEach((date) => {
      const year = date?.getFullYear();
      const month = (date?.getMonth() + 1)?.toString().padStart(2, "0");
      const day = date?.getDate()?.toString()?.padStart(2, "0");
      const key = isDaily ? `${year}-${month}-${day}` : `${year}-${month}`;
      aggregated[key] = {
        year,
        month,
        day: isDaily ? day : undefined,
        fullDate: isDaily ? formatDate(date) : `${month}-${year}`,
        lessonCount: 0,
        courseCount: 0,
        safetyMeetingCount: 0,
        jhaCount: 0,
      };
    });

    data.forEach((entry) => {
      const date = new Date(entry?.date);
      const year = date?.getFullYear();
      const month = (date?.getMonth() + 1)?.toString()?.padStart(2, "0");
      const day = date?.getDate()?.toString()?.padStart(2, "0");
      const key = isDaily ? `${year}-${month}-${day}` : `${year}-${month}`;

      if (aggregated[key]) {
        aggregated[key].lessonCount += entry.lessonCount || 0;
        aggregated[key].courseCount += entry.courseCount || 0;
        aggregated[key].safetyMeetingCount += entry.safetyMeetingCount || 0;
        aggregated[key].jhaCount += entry.jhaCount || 0;
      }
    });

    return Object.values(aggregated);
  };

  const generateLineChartData = (data) => {
    const aggregatedData = aggregateData(data);

    return {
      labels: aggregatedData.map((entry) => entry.fullDate),
      datasets: [
        {
          label: "Lessons",
          data: aggregatedData.map((entry) => entry.lessonCount || 0),
          borderColor: COLORS.VeryDardBlue,
          backgroundColor: COLORS.VeryDardBlue,
          borderWidth: 2,
          fill: false,
        },
        {
          label: "Courses",
          data: aggregatedData.map((entry) => entry.courseCount || 0),
          borderColor: COLORS.Pink,
          backgroundColor: COLORS.Pink,
          borderWidth: 2,
          fill: false,
        },
        {
          label: "Safety Meetings",
          data: aggregatedData.map((entry) => entry.safetyMeetingCount || 0),
          borderColor: COLORS.blue,
          backgroundColor: COLORS.blue,
          borderWidth: 2,
          fill: false,
        },
        {
          label: "Hazard Analysis",
          data: aggregatedData.map((entry) => entry.jhaCount || 0),
          borderColor: COLORS.LightGreen,
          backgroundColor: COLORS.LightGreen,
          borderWidth: 2,
          fill: false,
        },
      ],
    };
  };

  const linechartoptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          color: mode === "dark" ? COLORS.White : COLORS.Dune,
          font: {
            family: "Lato",
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          color: mode === "dark" ? COLORS.White : COLORS.Dune,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          color: mode === "dark" ? COLORS.White : COLORS.Dune,
        },
      },
    },
  };
  const truncateLabel = (label, maxLength = 30) => {
    if (label?.length <= maxLength) {
      return label;
    }
    return `${label?.substring(0, maxLength)}...`;
  };

  const options = {
    indexAxis: "x",
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        color: mode === "dark" ? COLORS.White : COLORS.Dune,
        display: false,
      },
      datalabels: {
        color: "white",
        anchor: "end",
        align: "end",
        formatter: function (value, context) {
          return truncateLabel(context.chart.data.labels[context.dataIndex]);
        },
      },
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const label = data.labels[tooltipItem.index];
          return label;
        },
      },
    },
    scales: {
      x: {
        stacked: false,
        ticks: {
          autoSkip: false, // Prevents skipping of labels
          color: mode === "dark" ? COLORS.White : COLORS.Dune,
        },
      },
      y: {
        stacked: false,
        ticks: {
          color: mode === "dark" ? COLORS.White : COLORS.Dune,
        },
      },
    },
  };

  const topLessonList = {
    labels: getLMSDashboardReport?.data?.lessonHighestAmountCompletion?.map(
      (itm) => itm.name
    ),
    datasets: [
      {
        label: "Lessons",
        data: getLMSDashboardReport?.data?.lessonHighestAmountCompletion?.map(
          (item) => item?.count
        ),
        backgroundColor: [COLORS.BrightAqua],
        borderColor: [COLORS.BrightAqua],
        borderWidth: 1,
        color: COLORS.White,
      },
    ],
  };
  const topCourseList = {
    labels: getLMSDashboardReport?.data?.courseHighestAmountCompletion?.map(
      (itm) => itm.name
    ),
    datasets: [
      {
        label: "",
        data: getLMSDashboardReport?.data?.courseHighestAmountCompletion?.map(
          (item) => item?.count
        ),
        backgroundColor: [COLORS.BrightAqua],
        borderColor: [COLORS.BrightAqua],
        borderWidth: 1,
        color: COLORS.White,
      },
    ],
  };

  const drilldownHandler = (action, reportDetails, card) => {
    reportDetails === 1
      ? navigate(REPORTS_CREDENTIAL_COUNT, {
          state: {
            name: "Expired Credentials",
            tabId: "2",
            startDate: startDate,
            endDate: endDate,
            timePeriod: timePeriod,
            divisionId: division,
            tabIdCred: "1",
            apiData: {
              startDate: startDate,
              endDate: endDate,
              divisionId: division,
              reportDetails: 8,
              tab: tab,
              card: card,
              timePeriod: timePeriod,
            },
          },
        })
      : reportDetails === 3
      ? navigate(REPORTS_CREDENTIAL_COUNT, {
          state: {
            name: "Expiring Credentials",
            tabId: "1",
            startDate: startDate,
            endDate: endDate,
            timePeriod: timePeriod,
            divisionId: division,
            tabIdCred: "1",
            apiData: {
              startDate: startDate,
              endDate: endDate,
              divisionId: division,
              reportDetails: 8,
              tab: tab,
              card: card,
              timePeriod: timePeriod,
            },
          },
        })
      : reportDetails === 2
      ? navigate(REPORTS_ANALYTICS_LMS_DASHBOARD_DETAILS, {
          state: {
            page: action,
            tabId: "1",
            apiData: {
              startDate: startDate,
              endDate: endDate,
              divisionId: division,
              reportDetails: 9,
              tab: tab,
              timePeriod: timePeriod,
            },
          },
        })
      : reportDetails === 4
      ? navigate(REPORTS_ANALYTICS_LMS_DASHBOARD_DETAILS, {
          state: {
            page: action,
            tabId: "1",
            apiData: {
              startDate: startDate,
              endDate: endDate,
              divisionId: division,
              reportDetails: 8,
              tab: tab,
              timePeriod: timePeriod,
            },
          },
        })
      : reportDetails === 5
      ? navigate(REPORTS_ANALYTICS_LMS_DASHBOARD_DETAILS, {
          state: {
            page: action,
            tabId: "1",
            apiData: {
              startDate: startDate,
              endDate: endDate,
              divisionId: division,
              reportDetails: 8,
              tab: tab,
              card: card,
              timePeriod: timePeriod,
            },
          },
        })
      : reportDetails === 6
      ? navigate(REPORTS_ANALYTICS_LMS_DASHBOARD_DETAILS, {
          state: {
            page: action,
            tabId: "1",
            apiData: {
              startDate: startDate,
              endDate: endDate,
              divisionId: division,
              reportDetails: 6,
              tab: tab,
              timePeriod: timePeriod,
            },
          },
        })
      : reportDetails === 7
      ? navigate(REPORTS_ANALYTICS_LMS_DASHBOARD_DETAILS, {
          state: {
            page: action,
            tabId: "1",
            apiData: {
              startDate: startDate,
              endDate: endDate,
              divisionId: division,
              reportDetails: 10,
              tab: tab,
              timePeriod: timePeriod,
            },
          },
        })
      : reportDetails === 8
      ? navigate(REPORTS_ANALYTICS_LMS_DASHBOARD_DETAILS, {
          state: {
            page: action,
            tabId: "1",
            apiData: {
              startDate: startDate,
              endDate: endDate,
              divisionId: division,
              reportDetails: 7,
              tab: tab,
              timePeriod: timePeriod,
            },
          },
        })
      : reportDetails === 9
      ? navigate(REPORTS_ANALYTICS_LMS_DASHBOARD_DETAILS, {
          state: {
            page: action,
            tabId: "1",
            apiData: {
              startDate: startDate,
              endDate: endDate,
              divisionId: division,
              reportDetails: 12,
              tab: tab,
              timePeriod: timePeriod,
            },
          },
        })
      : reportDetails === 10
      ? navigate(REPORTS_ANALYTICS_LMS_DASHBOARD_DETAILS, {
          state: {
            page: action,
            tabId: "1",
            apiData: {
              startDate: startDate,
              endDate: endDate,
              divisionId: division,
              reportDetails: 11,
              tab: tab,
              timePeriod: timePeriod,
            },
          },
        })
      : reportDetails === 11
      ? navigate(REPORTS_ANALYTICS_LMS_DASHBOARD_DETAILS, {
          state: {
            page: action,
            tabId: "1",
            apiData: {
              startDate: startDate,
              endDate: endDate,
              divisionId: division,
              reportDetails: 13,
              tab: tab,
              timePeriod: timePeriod,
            },
          },
        })
      : console.log();
  };
  // const [hideLabel, sethideLabel] = useState(false);
  return (
    <>
      <div id="pdf-loader" class="loader pdf-loader">
        <Loader />
      </div>
      <div className="overviewIns" id="pdf-content">
        <Grid container>
          <div id="first-page" className="w-100">
            <Grid container>
              <Grid item md={6} xs={12} mt={2}>
                <h1>Overview</h1>
              </Grid>
              {division && startDate && endDate && (
                <Grid item md={6} xs={12} className="hideinpdf">
                  <Button
                    onClick={() => {
                      downloadFullPDF(
                        "pdf-content",
                        "lms.pdf",
                        // [2, 3]?.includes(assetType)
                        //   ? ["first-page", "second-page", "third-page", "four-page"]
                        // :
                        ["first-page", "second-page"]
                      );
                    }}
                    variant="contained"
                    startIcon={<DownloadIcon className="downloadIcon" />}
                    className="downloadpdf sw marginRemove float_right"
                  >
                    Download as PDF
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid container mt={2}>
              <Grid md={4} xs={12} item>
                <FormControl fullWidth>
                  <FormLabels label="Division" isRequired={true} />
                  <SelectMenu
                    listData={divisionsList}
                    value={division || ""}
                    onchangehandler={(e) => onChangeDivision(e)}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container mt={2}>
              {division && (
                <>
                  <Grid md={4} xs={12} item>
                    <FormControl fullWidth>
                      <FormLabels label="Time Period" isRequired={true} />
                      <SelectMenu
                        listData={TIMEPERIOD}
                        value={timePeriod || ""}
                        onchangehandler={(e) => handleTimePeriodChange(e)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item md={12}>
                    {division && timePeriod === 5 ? (
                      <>
                        <Grid item container spacing={2} mb={2} mt={1}>
                          <Grid item lg={4} xs={12} sm={12} md={4}>
                            <FormLabels label={"From"} isRequired={false} />
                            <DatePicker
                              maxDateEnable={false}
                              value={startDate}
                              disabled={false}
                              onChangeHandler={(e) =>
                                onchangeStartdatePicker(e)
                              }
                            />
                          </Grid>
                          <Grid item lg={4} xs={12} sm={12} md={4}>
                            <FormLabels label={"To"} isRequired={false} />
                            <DatePicker
                              maxDateEnable={false}
                              minDate={startDate}
                              value={endDate}
                              disabled={false}
                              onChangeHandler={(e) => onchangeEnddatePicker(e)}
                            />
                          </Grid>
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                </>
              )}
            </Grid>
            {division && startDate && endDate && (
              <Grid container spacing={2} mt={3}>
                <Grid
                  item
                  md={7}
                  xs={12}
                  onClick={() => drilldownHandler("expiredCredentials", 1)}
                  className="cursor"
                >
                  <TopSection
                    title={"Expired Credentials - By User"}
                    column={TOP_FIVE_EXPIRED_CREDENTIALS}
                    rows={
                      getLMSDashboardReport?.data?.expiredcredentialList ?? []
                    }
                    topsection={"credential"}
                  />
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={12}
                  // onClick={() =>
                  //   drilldownHandler("subContractorWithMostParticipation", 2)
                  // }
                  // className="cursor"
                >
                  <TopSection
                    title={"Sub Contractors with Most Safety Meetings"}
                    column={TOP_FIVE_SUB_CONTRACTORS_WITH_MOST_SAFETY_MEETINGS}
                    rows={
                      getLMSDashboardReport?.data
                        ?.subContractoMostParticipationSafetyMeetings ?? []
                    }
                  />
                </Grid>
              </Grid>
            )}
            {division && startDate && endDate && (
              <Grid container spacing={2} mt={3}>
                <Grid
                  item
                  md={7}
                  xs={12}
                  onClick={() => drilldownHandler("expiringCredential", 3)}
                  className="cursor"
                >
                  <TopSection
                    title={"Expiring Credentials - By User"}
                    column={TOP_FIVE_EXPIRED_CREDENTIALS}
                    rows={
                      getLMSDashboardReport?.data
                        ?.closestToexpircredentialList ?? []
                    }
                    topsection={"credential"}
                  />
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={12}
                  onClick={() =>
                    drilldownHandler("usersWithHighestCompletedTraining", 4)
                  }
                  className="cursor"
                >
                  <TopSection
                    title={"Users with Highest Completed Training"}
                    column={TOP_FIVE_HIGHEST_COMPLETED_TRAINING_USERS}
                    rows={
                      getLMSDashboardReport?.data
                        ?.usersHighestCompletedTraining ?? []
                    }
                    // rows={props?.inspecionReport?.topCorrectiveUserList ?? []}
                  />
                </Grid>
              </Grid>
            )}
            {division && startDate && endDate && (
              <Grid className="commonBoxShodow" container spacing={2} mt={3}>
                <Grid item md={9} xs={12}>
                  <Grid>
                    <LineChart
                      labels={
                        generateLineChartData(
                          getLMSDashboardReport?.data
                            ?.completedTrainingComparison || []
                        ).labels
                      }
                      datasets={
                        generateLineChartData(
                          getLMSDashboardReport?.data
                            ?.completedTrainingComparison || []
                        ).datasets
                      }
                      options={linechartoptions}
                      height="auto"
                    />
                  </Grid>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Grid
                    // onClick={() =>
                    //   drilldownHandler(
                    //     "completedTraining",
                    //     4,
                    //     "Training"
                    //   )
                    // }
                    mb={2}
                    className="commonBoxShodow"
                  >
                    <div className="titletop small ">Total Training</div>
                    <div className="discription red">
                      {getLMSDashboardReport?.data?.totalTrainingCount}
                    </div>
                  </Grid>
                </Grid>
                <Grid container gap={2} item md={12} xs={12}>
                  <Grid
                    onClick={() =>
                      drilldownHandler("completedTraining", 5, "lesson")
                    }
                    mb={2}
                    item
                    md={2.5}
                    className="commonBoxShodow cursor"
                  >
                    <div className="titletop small">Lessons</div>
                    <div className="discription darkBlue">
                      {getLMSDashboardReport?.data?.totalLessonCount}
                    </div>
                  </Grid>
                  <Grid
                    onClick={() =>
                      drilldownHandler("completedTraining", 5, "course")
                    }
                    mb={2}
                    item
                    md={2.5}
                    className="commonBoxShodow cursor"
                  >
                    <div className="titletop small">Courses</div>
                    <div className="discription pink">
                      {getLMSDashboardReport?.data?.totalCourseCount}
                    </div>
                  </Grid>
                  <Grid
                    // onClick={() =>
                    //   drilldownHandler("completedTraining", 5, "safety")
                    // }
                    mb={2}
                    item
                    md={2.5}
                    className="commonBoxShodow "
                  >
                    <div className="titletop small">Safety Meetings</div>
                    <div className="discription blue">
                      {getLMSDashboardReport?.data?.totalSafetyMeetingCount}
                    </div>
                  </Grid>
                  <Grid
                    onClick={() =>
                      drilldownHandler("completedTraining", 5, "hazard")
                    }
                    mb={2}
                    item
                    md={2.5}
                    className="commonBoxShodow cursor"
                  >
                    <div className="titletop small">Hazard Analysis</div>
                    <div className="discription lightGreen">
                      {getLMSDashboardReport?.data?.totalHazardAnalysisCount}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </div>
          {division && startDate && endDate && (
            <div id="second-page" className="w-100">
              <Grid container spacing={2} mt={3}>
                <Grid
                  item
                  md={8}
                  xs={12}
                  onClick={() => drilldownHandler("lessons", 6)}
                >
                  <Grid container className="commonBoxShodow cursor">
                    <Grid item lg={12}>
                      <h2 className="titletop">
                        Lessons with Highest Completion
                      </h2>
                    </Grid>
                    {/* {!hideLabel && (
                        <Grid className="verticalText" item lg={1}>
                          <h2 className="titletop">
                            Number of Completed Lessons
                          </h2>
                        </Grid>
                      )} */}

                    <Grid item lg={12}>
                      <BarChart
                        labels={topLessonList.labels}
                        datasets={topLessonList.datasets}
                        options={options}
                        height="auto"
                      />
                    </Grid>

                    <Grid item lg={12} textAlign={"center"}>
                      <h2 className="titletop">Lessons</h2>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={12}
                  onClick={() =>
                    drilldownHandler("trainingWithLowestParticipation", 7)
                  }
                  className="cursor"
                >
                  <TopSection
                    title={"Training with Lowest Participation"}
                    column={LOWEST_PARTICIPATION_TRAINING}
                    rows={
                      getLMSDashboardReport?.data?.trainingLowestCompletion ??
                      []
                    }
                    topsection={"NAME"}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={3}>
                <Grid
                  item
                  md={8}
                  xs={12}
                  onClick={() =>
                    drilldownHandler("coursesWithHighestCompletion", 8)
                  }
                >
                  <Grid container className="commonBoxShodow cursor">
                    <Grid item lg={12}>
                      <h2 className="titletop">
                        Courses with Highest Completion{" "}
                      </h2>
                    </Grid>
                    {/* <Grid className="verticalText" item lg={1}>
                    <h2 className="titletop">Number of Completed Courses</h2>
                  </Grid> */}

                    <Grid item lg={12}>
                      <BarChart
                        labels={topCourseList.labels}
                        datasets={topCourseList.datasets}
                        options={options}
                        height="auto"
                      />
                    </Grid>
                    <Grid item lg={12} textAlign={"center"}>
                      <h2 className="titletop"> Courses</h2>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={12}
                  // onClick={() =>
                  //   drilldownHandler("usersWithMostSafetyMeetings", 9)
                  // }
                  // className="cursor"
                >
                  <TopSection
                    title={"Most Safety Meetings Conducted"}
                    column={MOST_COMPLETE_TRAINING_USERS}
                    rows={
                      getLMSDashboardReport?.data?.userWithMostSafetyMeeting ??
                      []
                    }
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} mt={3}>
                <Grid
                  item
                  md={4}
                  xs={12}
                  onClick={() =>
                    drilldownHandler("usersWithMostIncompletedTraining", 10)
                  }
                  className="cursor"
                >
                  <Grid>
                    <TopSection
                      title={"Users with Most Incomplete Training"}
                      column={USERS_WITH_INCOMPLETE_TRAINING}
                      rows={
                        getLMSDashboardReport?.data
                          ?.usersMostIncompleteTraining ?? []
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Grid
                    mb={2}
                    onClick={() =>
                      drilldownHandler("pastDueHazardAnalysis", 11)
                    }
                    className="commonBoxShodow cursor"
                  >
                    <div className="titletop small cursor">
                      Past Due Hazard Analysis
                    </div>
                    <div className="discription red">
                      {getLMSDashboardReport?.data?.pastDueHazardAnalysis}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          )}
        </Grid>
      </div>
    </>
  );
};

export default Lms;
