import React, { useEffect, useState } from "react";
import OshaRecordkeeping from "../component/oshaRecordkeeping";
import { useDispatch, useSelector } from "react-redux";
import * as incidentAction from "../../../redux/actions/incidentAction";
import { generateYears } from "../../../utils/helper";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../library/common/Loader";
import {
  ACCESS_DENIED,
  EDITCASE,
  ESTABLISHMENT_ADD,
  OSHA_ITASUBMISSION,
  OSHA_RECORDKEEPING,
  PERSONAL_INJURY,
  VIEW_INCIDENT_REPORT
} from "../../../constant/routeContant";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { ToastContainer } from "react-toastify";

const OshaRecordkeepingContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const years = generateYears(currentYear - 2, currentYear);
  const [establishmentId, setEstablishmentId] = useState();
  const [year, setYear] = useState(currentYear.toString() - 1);
  const [employeeName, setEmployeeName] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [pdfLoader, setPdfLoader] = useState(false);

  const location = useLocation();

  const getOshaDetails = useSelector((state) => state.getOshaDetails);
  const getOshaDetailsData = (data) => {
    dispatch(incidentAction.getOshaDetails(data));
  };
  const incident = useSelector((state) => state.incident.data);
  const getEstablishmentDetail = useSelector(
    (state) => state?.establishmentDetail
  );
  const getOshaLog = useSelector((state) => state?.getOshaLog);

  const getEstablishmentDetailData = (data) => {
    dispatch(incidentAction.getEstablishmentDetail(data));
  };
  const getOshaLogData = (data) => {
    dispatch(incidentAction.getOshaLog(data));
  };

  const addGetOSHALog = useSelector((state) => state?.addGetOSHALog);

  const isLoader = useSelector(
    (state) =>
      state?.establishmentDetail?.isLoading ||
      state?.incident?.isLoading ||
      state?.getOshaLog?.isLoading ||
      state?.addGetOSHALog?.isLoading ||
      state.getOshaDetails?.isLoading
  );

  const isTableLoader = useSelector((state) => state?.getOshaLog?.isLoading);

  useEffect(() => {
    if (establishmentId) {
      const data = {
        establishmentId: establishmentId,
      };
      getEstablishmentDetailData(data);
    }
  }, [establishmentId]);

  const getIncidentData = () => {
    dispatch(incidentAction.getIncidentData());
  };
  useEffect(() => {
    getIncidentData();
  }, []);

  useEffect(() => {
    if (establishmentId && year) {
      const defaultData = {
        establishmentId: establishmentId,
        year: year,
        status: "",
        search: "",
        isIncidentRecordable: "",
      };

      getOshaLogData(defaultData);
    }
  }, [establishmentId, year]);

  const editEstablishment = (id) => {
    navigate(ESTABLISHMENT_ADD, {
      state: {
        establishmentId: id,
        editMode: true,
        route: OSHA_RECORDKEEPING,
        routeText: "Back to OSHA Recordkeeping",
        year: year,
      },
    });
  };
  const handleViewOnClick = (rows) => {
    navigate(VIEW_INCIDENT_REPORT, {
      state: {
        caseId: rows?.caseId,
        incidentId: rows?.incidentId,
        incidentType: 1,
        incidentURL: OSHA_RECORDKEEPING,
        backRouteText: "Back to OSHA Recordkeeping",
        year: year,
        establishmentId: establishmentId,
      },
    });
  };
  const handleEditOnClick = (rows) => {
    navigate(PERSONAL_INJURY, {
      state: {
        caseId: rows?.caseId,
        incidentId: rows?.incidentId,
        editMode: true,
        isDirect: true,
        route: OSHA_RECORDKEEPING,
        routeText: "Back to OSHA Recordkeeping",
        year: year,
        establishmentId: establishmentId,
      },
    });
  };

  const addOshaDetailHandler = (data) => {
    dispatch(incidentAction.addGetOSHALog(data));
  };
  const handleSubmit = () => {
    navigate(OSHA_ITASUBMISSION, {
      state: {
        year: year,
        establishmentId: establishmentId,
        oshaDetail: true,
        submitFromRecordkeeping: true,
      },
    });
  };

  const handleCaseEditOnClick = (rows) => {
    navigate(EDITCASE, {
      state: {
        caseId: rows?.caseId,
        editMode: true,
        isEditModeThroughViewCase: true,
        route: OSHA_RECORDKEEPING,
        routeText: "Back to OSHA Recordkeeping",
        year: year,
        establishmentId: establishmentId,
      },
    });
  };

  useEffect(() => {
    if (location?.state?.establishmentId || location?.state?.routeProps) {
      setYear(location?.state?.year || location?.state?.routeProps?.year);
      setEstablishmentId(
        location?.state?.establishmentId ||
          location?.state?.routeProps?.establishmentId
      );
    }
  }, [location?.state]);

  useEffect(() => {
    if (establishmentId && year) {
      const defaultData = {
        establishmentId: establishmentId,
        year: year,
      };

      getOshaDetailsData(defaultData);
    }
  }, [establishmentId, year]);
console.log('deploy update');

  return (
    <>
      {checkPrivileges([152, 153, 154, 12]) ? (
        <>
          {(isLoader || pdfLoader) && <Loader />}
          <OshaRecordkeeping
            isLoading={isTableLoader}
            logData={getOshaLog?.data?.oshaLogList}
            count={getOshaLog?.data?.noOfRecords}
            getOshaLogData={getOshaLogData}
            establishmentId={establishmentId}
            year={year}
            setEstablishmentId={setEstablishmentId}
            setYear={setYear}
            years={years}
            establishments={incident?.locationOfRecord}
            establishmentDetail={getEstablishmentDetail?.data?.result}
            editEstablishment={editEstablishment}
            handleViewOnClick={handleViewOnClick}
            handleEditOnClick={handleEditOnClick}
            handleSubmit={handleSubmit}
            handleCaseEditOnClick={handleCaseEditOnClick}
            employeeName={employeeName}
            setEmployeeName={setEmployeeName}
            addOshaDetailHandler={addOshaDetailHandler}
            addGetOSHALog={addGetOSHALog}
            isSubmit={isSubmit}
            setIsSubmit={setIsSubmit}
            otherDetail={getOshaLog?.data}
            getOshaDetails={getOshaDetails?.data?.oshaLogDetails}
            setPdfLoader={setPdfLoader}
            currentYear={currentYear}
          />
          <ToastContainer />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};

export default OshaRecordkeepingContainer;
