import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as userAction from "../../../redux/actions/userAction";
import { useNavigate } from "react-router-dom";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import { ADD_ADMIN_NONPRIVILEGE_USER } from "../../../constant/routeContant";
import { API_STATUS, ORG_ID, PAGE_SIZE } from "../../../constant/constant";
import NonPrivilegeUserList from "../component/users/nonPrivilegeUserList";
import { PRODUCT_ID } from "../../../constant/storm-water/constant";
import { toast, ToastContainer } from "react-toastify";

const NonPrivilegeUserListContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const nonPrivilegeuserListData = useSelector(
    (state) => state.NonPrivilegeuserListData
  );
  const [sendEmail, setSendEmail] = useState(false);

  const NavigateSingleUserMethode = () => {
    navigate(ADD_ADMIN_NONPRIVILEGE_USER);
  };

  const getNonPrivilegeLists = (data) => {
    if (data?.isSendMail) {
      setSendEmail(true);
    } else {
      setSendEmail(false);
    }
    dispatch(userAction.getNonPrivilegeUserList(data));
  };

  const companies = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.companies
  );

  const jobTitles = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.jobTitles
  );

  const deleteNonPrivilegeUserListData = useSelector(
    (state) => state.deleteNonPrivilegeUserListData
  );

  const divisiondata = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.divisons
  );

  const deleteNonPrivilegeLists = (userId) => {
    dispatch(userAction.deleteNonPrivilegeList(userId));
  };

  const getDropdownDataKeyValueData = {
    orgId: ORG_ID(),
    flags: "1,6,12",
    productId: PRODUCT_ID(),
  };
  useEffect(() => {
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);

  useEffect(() => {
    const data = {
      page: 1,
      pageSize: PAGE_SIZE,
      search: "",
      orgId: ORG_ID(),
      titleId: "",
      divisionId: "",
      isSendMail: false,
    };
    getNonPrivilegeLists(data);
  }, []);

  useEffect(() => {
    if (deleteNonPrivilegeUserListData.status === "SUCCESS") {
      const data = {
        page: 1,
        pageSize: PAGE_SIZE,
        search: "",
        titleId: "",
        divisionId: "",
      };
      getNonPrivilegeLists(data);
    }
  }, [deleteNonPrivilegeUserListData]);

  useEffect(() => {
    if (nonPrivilegeuserListData?.status === API_STATUS.SUCCESS && sendEmail) {
      toast("Non privilege user has been sent to registered email ID");
    }
  }, [nonPrivilegeuserListData]);

  return (
    <>
      <ToastContainer />
      <NonPrivilegeUserList
        loading={nonPrivilegeuserListData.isLoading}
        NonPrivilegeuserListData={nonPrivilegeuserListData?.data}
        NavigateSingleUserMethode={NavigateSingleUserMethode}
        getNonPrivilegeLists={getNonPrivilegeLists}
        deleteNonPrivilegeLists={deleteNonPrivilegeLists}
        companies={companies}
        jobTitles={jobTitles}
        divisiondata={divisiondata}
      />
    </>
  );
};

export default NonPrivilegeUserListContainer;
