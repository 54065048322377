import * as React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch } from "react-redux";
import { logOutUser } from "../../redux/actions/loginActions";
import "./navbar.scss";
import {
  ADMIN_PROFILE,
  DASHBOARD,
  LANDING_PAGE,
} from "../../constant/routeContant";
import { useLocation, useNavigate } from "react-router-dom";
import SearchAutoComplete from "../custom/searchAutoComplete/SearchAutoComplete";
import * as clientAction from "../../redux/actions/clientAction";
import { checkPrivileges } from "../../utils/rolesHelper";
import MenuIcon from "@mui/icons-material/Menu";
import { Grid } from "@mui/material";
import { logos } from "../../assets";
import BellIcon from "../../assets/BellIcon.svg";
import _ from "lodash";
import NotificationList from "../../feature/admin/shared/NotificationList";
import * as loginActions from "../../redux/actions/loginActions";
import { messaging } from "../../../src/firebase";
import { getToken, onMessage } from "firebase/messaging";
import Loader from "../common/Loader";
import { SERVICE_TYPE } from "../../constant/constant";
import { DARK, vapidKey } from "../../constant/constant";
import { useThemeContext } from "../../context";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

const pages = [];
const ResponsiveAppBar = ({ handleDrawerToggle, islanding = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchAllClientsReducerData = useSelector(
    (state) => state.searchAllClientsReducerData
  );
  const autoSearchDataLoading = searchAllClientsReducerData.isLoading;
  const handleCloseNavMenu = () => {};
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [clients, setClients] = useState();
  const serviceType = SERVICE_TYPE();
  const { mode, toggleTheme } = useThemeContext();

  const Profile_image = localStorage.getItem("profileImage")
    ? localStorage.getItem("profileImage")
    : "";
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const searchClient = (searchText) => {
    dispatch(clientAction.searchAllClients(searchText));
  };
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const userInfoProduct = userInfo?.product;
  const data = userInfoProduct?.map((val) => val?.key);
  const onChangeClients = (org) => {
    localStorage.setItem("orgId", org.key);
    localStorage.setItem("divisionId", 0);
    localStorage.setItem("orgName", org.value);
    localStorage.setItem("orgLogo", org.logoUrl);
    localStorage.setItem("ProcoreCompanyId", org.ProcoreCompanyId);
    localStorage.setItem("IsProcoreConnected", org.IsProcoreConnected);
    window.location.href = DASHBOARD;
    setClients(org);
  };

  const loginOrgID = localStorage.getItem("loginOrganizationId");
  const loginOrganizationName = localStorage.getItem("loginOrganizationName");
  const SwitchedClientOrgID = localStorage.getItem("orgId");
  const ProcoreId = localStorage.getItem("ProcoreCompanyId");
  const IsProcoreConnected = localStorage.getItem("IsProcoreConnected");

  const onMyOrgChange = () => {
    localStorage.setItem("orgId", loginOrgID);
    localStorage.setItem("orgName", loginOrganizationName);
    localStorage.setItem("companyName", loginOrganizationName);
    localStorage.removeItem("ProcoreCompanyId", ProcoreId);
    localStorage.removeItem("IsProcoreConnected", IsProcoreConnected);
    window.location.href = DASHBOARD;
    setAnchorEl(null);
  };

  const typeOnchange = (event) => {
    const searchText = event.target?.value;
    if (!_.isEmpty(searchText)) {
      searchClient(searchText.trim());
    }
  };

  const getHeaderText = () => {
    const loginOrganizationId = localStorage.getItem("loginOrganizationId");
    const orgId = localStorage.getItem("orgId");
    const headerText =
      loginOrganizationId === orgId
        ? localStorage.getItem("name")
        : localStorage.getItem("orgName");
    return "Welcome, " + headerText;
  };
  const NotifiactionCount = useSelector((state) => state.notifiactionCountData);

  const updateNotifiactionCount = useSelector(
    (state) => state.updateNotifiactionCountData
  );

  const [anchorElNotfication, NotficationsetAnchorEl] = useState(null);
  const [fcmTokens, setFcmToken] = useState("");
  const [notificationCounts, setNotificationCount] = useState(0);

  useEffect(() => {
    setNotificationCount(
      NotifiactionCount?.data?.result > 100
        ? "99+"
        : NotifiactionCount?.data?.result
    );
  }, [NotifiactionCount]);

  const handleCloseOnNOtification = () => {
    NotficationsetAnchorEl(null);
    window.close();
  };
  const opens = Boolean(anchorElNotfication);
  const idd = opens ? "simple-popover" : undefined;
  const userId = localStorage.getItem("userId");
  const NotifiactionListDatas = useSelector(
    (state) => state.NotifiactionListData
  );
  const loginData = useSelector((state) => state.login);
  const NotifiactionLogoutDatas = useSelector(
    (state) => state.NotifiactionLogoutData
  );

  const updateNotifiactionListData = useSelector(
    (state) => state.updateNotifiactionListData
  );

  const getNotifiactionListData = (data) => {
    dispatch(loginActions.getNotifiactionList(data));
  };

  const UpdateNotifiactionListDatas = (data) => {
    dispatch(loginActions.updateNotifiactionList(data));
  };

  const getNotifiactionCount = (data) => {
    dispatch(loginActions.getNotifiactionCount(data));
  };
  const UpdateNotifiactionCount = (data) => {
    dispatch(loginActions.updateNotifiactionCount(data));
  };

  const NotificationOClick = (event) => {
    NotficationsetAnchorEl(event.currentTarget);
    const data = "";
    UpdateNotifiactionCount(data);
    setNotificationCount(0);
  };
  useEffect(() => {
    if (
      updateNotifiactionListData?.isLoading === false &&
      updateNotifiactionListData?.status === "SUCCESS"
    ) {
      const defaultData = {
        page: 1,
        pageSize: 1000,
        status: "",
        userId: userId,
        search: "",
      };
      getNotifiactionListData(defaultData);
    }
  }, [updateNotifiactionListData]);

  useEffect(() => {
    if (
      location?.pathname === "/dashboard" ||
      location?.pathname === "/sw/dashboard"
    ) {
      const defaultData = {
        page: 1,
        pageSize: 1000,
        status: "",
        userId: userId,
        search: "",
      };
      getNotifiactionListData(defaultData);
      getNotifiactionCount();
    }
  }, [location?.pathname]);

  async function requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey:
          "BPeBIj0NP9yrMH1Pc9QvLHVmsJHFNH_BodPYXgOCJYUdCyMoRkaDzQ-wK6mlLg5nDPuT_ngozhFIuqWlM0aJksI",
      });
      setFcmToken(token);
    } else if (permission === "denied") {
      return null;
    }
  }

  const postNotifiactionLogOutData = (data) => {
    dispatch(loginActions.postNotifiactionLogout(data));
  };

  useEffect(() => {
    if (loginData.status === "SUCCESS") {
      requestPermission();
    }
  }, []);

  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log("for=========", payload);
      getNotifiactionCount();
      const defaultData = {
        page: 1,
        pageSize: 1000,
        status: "",
        userId: userId,
        search: "",
      };
      getNotifiactionListData(defaultData);
    });

    return () => {
      unsubscribe();
    };
  }, [userId]);

  useEffect(() => {
    setNotificationCount(
      NotifiactionCount?.data?.result > 100
        ? "99+"
        : NotifiactionCount?.data?.result
    );
  }, [NotifiactionCount]);

  const logoutClickHandler = () => {
    const defaultData = {
      userId: userId,
      fcmToken: fcmTokens,
    };
    postNotifiactionLogOutData(defaultData);
  };

  useEffect(() => {
    if (
      NotifiactionLogoutDatas?.isLogout &&
      NotifiactionLogoutDatas?.isLoading === false
    ) {
      dispatch(logOutUser());
    }
  }, [NotifiactionLogoutDatas]);

  useEffect(() => {
    document.body.className = mode === "light" ? "light-mode" : "dark-mode";
  }, [mode]);

  return (
    <div className="header-over">
      {NotifiactionLogoutDatas.isLoading && <Loader />}
      <AppBar className="topHeader header-box" position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Grid
              container
              className={
                islanding
                  ? "topHeaderBox islanding-top-headerBox"
                  : "topHeaderBox"
              }
            >
              <div item xs={2} sm={2} md={2} className="menuIconMobile">
                <MenuIcon onClick={handleDrawerToggle} />
              </div>
              {islanding && (
                // <div className="logo">
                <Grid item lg={2.5} md={3} xs={2}>
                  {mode === DARK ? (
                    <img
                      src={logos?.logoWhite}
                      width="180px"
                      className="logoResponsive"
                      alt="no logo"
                    />
                  ) : (
                    <img
                      src={logos?.logo}
                      width="180px"
                      className="logoResponsive"
                      alt="no logo"
                    />
                  )}
                </Grid>
                // </div>
              )}

              <Grid
                item
                md={islanding ? 5 : 7}
                xs={8}
                lg={islanding ? 5 : 7}
                className="leftBox WelcomeUserText"
              >
                <h1 className="welcomeUser ">{getHeaderText()}</h1>
              </Grid>
              <Grid
                item
                md={islanding ? 4.5 : 3}
                xs={2}
                lg={islanding ? 4.5 : 5}
                container
                className="rightBox profieImages"
              >
                <Grid>
                  {pages.map((page) => (
                    <Button
                      className="pages-title"
                      key={page}
                      onClick={handleCloseNavMenu}
                      sx={{ my: 2, color: "white", display: "block" }}
                    >
                      {page}
                    </Button>
                  ))}
                </Grid>
                <Grid item md={7} xs={7} sm={7} className="searchAutoMobile">
                  {checkPrivileges([12, 15]) && !islanding && (
                    <SearchAutoComplete
                      name="orgName"
                      placeholderText="Search organization"
                      noOptionsTextMsg="No organization"
                      optionsData={
                        autoSearchDataLoading
                          ? []
                          : searchAllClientsReducerData?.data ?? []
                      }
                      typeOnchange={(event, value) =>
                        typeOnchange(event, value)
                      }
                      isKeyValuePair={true}
                      value={clients}
                      onChange={(event, value) => onChangeClients(value)}
                      isLoading={autoSearchDataLoading}
                    />
                  )}
                </Grid>
                {serviceType === "1" && (
                  <Grid
                    item
                    lg={1.5}
                    md={2}
                    xs={2}
                    sm={2}
                    mt={1}
                    className="NotificationStyles"
                  >
                    {!islanding && (
                      <div className="notification-badge">
                        <img
                          src={BellIcon}
                          alt="Notification Icon"
                          onClick={NotificationOClick}
                        />
                        {notificationCounts > 0 && (
                          <span className="badge">{notificationCounts}</span>
                        )}
                      </div>
                    )}
                    <NotificationList
                      id={idd}
                      open={opens}
                      anchorEl={anchorElNotfication}
                      handleClose={handleCloseOnNOtification}
                      NotifiactionListDatas={NotifiactionListDatas?.data}
                      UpdateNotifiactionListData={UpdateNotifiactionListDatas}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  md={2}
                  xs={2}
                  sm={2}
                  iconRightBox
                  className="ProfileImageIcon"
                >
                  <IconButton
                    sx={{ p: 0 }}
                    onClick={handleClick}
                    className="Profile_Btn"
                  >
                    <Avatar alt="profile image" src={Profile_image} />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    className="profileDropdown"
                  >
                    {loginOrgID === SwitchedClientOrgID ? (
                      ""
                    ) : (
                      <MenuItem onClick={onMyOrgChange}>
                        My Organization
                      </MenuItem>
                    )}

                    {!islanding && (
                      <MenuItem
                        onClick={() => {
                          navigate(ADMIN_PROFILE);
                          setAnchorEl(null);
                        }}
                      >
                        Profile
                      </MenuItem>
                    )}
                    {!islanding && data?.length > 1 && (
                      <MenuItem
                        onClick={() => {
                          navigate(LANDING_PAGE);
                          setAnchorEl(null);
                        }}
                      >
                        Service Type
                      </MenuItem>
                    )}
                    <MenuItem onClick={logoutClickHandler}>Logout</MenuItem>
                  </Menu>
                </Grid>
                <Grid item md={1} xs={1} sm={1}>
                  {
                    <IconButton
                      sx={{ ml: 1 }}
                      onClick={toggleTheme}
                      color="inherit"
                    >
                      {mode === DARK ? (
                        <Brightness7Icon className="iconWhite" />
                      ) : (
                        <Brightness4Icon />
                      )}
                    </IconButton>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};
export default ResponsiveAppBar;
