import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  HBNEXT_ORG_ID,
  INSPECTION_HEADER_RESPONSE_TYPE,
  ORG_ID,
  USER_ID,
} from "../../../../constant/constant";
import { InputField } from "../../../../library/custom/textBox/InputField";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import RadioButton from "../../../../library/custom/radioButton/RadioButton";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import MultiSelectBox from "../../../../library/custom/selectMenu/MultiSelectBox";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import TextArea from "../../../../library/custom/textArea/TextArea";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { FormBuilderImg } from "../../../../assets";
import { Grid } from "@mui/material";
import Button from "../../../../library/custom/button/Button";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import Buttons from "../../../../library/custom/button/Button";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

const initialFields = [
  { id: "input", content: "Textbox", img: FormBuilderImg.InputImg },
  { id: "textarea", content: "Text Area", img: FormBuilderImg.TextAreaImg },
  {
    id: "single-select",
    content: "Single Select",
    img: FormBuilderImg.SingleSelectImg,
  },
  {
    id: "multiple-select",
    content: "Multi Select",
    img: FormBuilderImg.MuiltiSelectImg,
  },
  {
    id: "radiobox",
    content: "Radio Button",
    img: FormBuilderImg.RadioButtonImg,
  },
  { id: "checkbox", content: "Checkbox", img: FormBuilderImg.CheckboxImg },
];

const AddHeaderConfiguration = (props) => {
  const [fields, setFields] = useState(initialFields);
  const [formElements, setFormElements] = useState([]);
  const [customLabels, setCustomLabels] = useState({});
  const [selectedElement, setSelectedElement] = useState(null);
  const [options, setOptions] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [newOptions, setNewOptions] = useState({
    "single-select": "",
    "multiple-select": "",
    "radiobox": "",
    "checkbox": "",
  });
  const seq = props?.newlistInspectionTypeFields;
  const seqCount = seq?.length;
  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === "fields") {
        const newFields = Array.from(fields);
        const [moved] = newFields.splice(source.index, 1);
        newFields.splice(destination.index, 0, moved);
        setFields(newFields);
      } else {
        const newFormElements = Array.from(formElements);
        const [moved] = newFormElements.splice(source.index, 1);
        newFormElements.splice(destination.index, 0, moved);
        setFormElements(newFormElements);
        setSelectedElement(newFormElements[destination.index].id);
      }
    } else {
      if (
        source.droppableId === "fields" &&
        destination.droppableId === "form-builder"
      ) {
        const newFormElements = Array.from(formElements);
        const fieldToAdd = {
          ...fields[source.index],
          id: `${fields[source.index].id}-${Date.now()}`,
        };
        newFormElements.splice(destination.index, 0, fieldToAdd);
        setFormElements(newFormElements);
        setSelectedElement(fieldToAdd.id);
      }
    }
  };

  const removeFormElement = (index) => {
    const newFormElements = [...formElements];
    const removedElementId = newFormElements[index].id;
    newFormElements.splice(index, 1);
    setFormElements(newFormElements);

    if (selectedElement === removedElementId) {
      setSelectedElement(null);
    }

    setValidationErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[removedElementId];
      return newErrors;
    });
  };

  const updateCustomLabel = (id, label) => {
    let errorMessage = "";

    if (label?.trim() === "") {
      errorMessage = "Label is required";
    } else if (label.length > 100) {
      errorMessage = "Label cannot exceed 100 characters";
    }

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [id]: {
        ...prevErrors[id],
        label: errorMessage,
      },
    }));

    setCustomLabels((prevLabels) => ({
      ...prevLabels,
      [id]: {
        ...prevLabels[id],
        label,
      },
    }));
  };

  const updateMandatory = (id, isMandatory) => {
    setCustomLabels((prevLabels) => ({
      ...prevLabels,
      [id]: {
        ...prevLabels[id],
        mandatory: isMandatory,
      },
    }));
  };

  const updateDefault = (id, isDefault) => {
    setCustomLabels((prevLabels) => ({
      ...prevLabels,
      [id]: {
        ...prevLabels[id],
        isDefault: isDefault,
      },
    }));
  };

  const addOption = (id) => {
    const currentOptions = options[id] || [];
    const trimmedNewOption = newOptions[id]?.trim();
  
    if (trimmedNewOption === "") return;
    if (trimmedNewOption?.length > 100) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [id]: {
          ...prevErrors[id],
          optionsMessage: "Option cannot exceed 100 characters.",
        },
      }));
      return;
    }
  
    if (currentOptions?.includes(trimmedNewOption)) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [id]: {
          ...prevErrors[id],
          optionsMessage: "Option already exists. Please add a different option.",
        },
      }));
    } else {
      setOptions((prevOptions) => ({
        ...prevOptions,
        [id]: [...currentOptions, trimmedNewOption],
      }));
      setNewOptions((prevNewOptions) => ({
        ...prevNewOptions,
        [id]: "",
      }));
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [id]: {
          ...prevErrors[id],
          optionsMessage: "",
        },
      }));
    }
  };
  
  const removeOption = (id, optionIndex) => {
    setOptions((prevOptions) => {
      const updatedOptions = [...(prevOptions[id] || [])];
      updatedOptions?.splice(optionIndex, 1);
      return {
        ...prevOptions,
        [id]: updatedOptions,
      };
    });
  };
  
  const updateOption = (id, optionIndex, value) => {
    const trimmedValue = value?.trim();
  
    if (trimmedValue.length > 100) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [id]: {
          ...prevErrors[id],
          optionsMessage: "Option cannot exceed 100 characters.",
        },
      }));
      return;
    }
  
    setOptions((prevOptions) => {
      const updatedOptions = [...(prevOptions[id] || [])];
      updatedOptions[optionIndex] = trimmedValue;
  
      const uniqueOptions = Array.from(
        new Set(updatedOptions.filter((option) => option.trim() !== ""))
      );
  
      return {
        ...prevOptions,
        [id]: uniqueOptions,
      };
    });
  
    setValidationErrors((prevErrors) => {
      const currentOptions = options[id] || [];
      const isDuplicate = currentOptions.some(
        (option, index) => index !== optionIndex && option === trimmedValue
      );
      const errorMessage = isDuplicate
        ? "Option already exists. Please add a different option."
        : "";
  
      return {
        ...prevErrors,
        [id]: {
          ...prevErrors[id],
          optionsMessage: errorMessage,
        },
      };
    });
  };
  

  const duplicateFormElement = (id) => {
    const elementToDuplicate = formElements.find(
      (element) => element.id === id
    );

    if (elementToDuplicate) {
      const duplicatedElement = {
        ...elementToDuplicate,
        id: `${elementToDuplicate.id}-${Date.now()}`,
      };

      const indexToInsert =
        formElements.findIndex((element) => element.id === id) + 1;

      const newFormElements = [...formElements];
      newFormElements.splice(indexToInsert, 0, duplicatedElement);
      setFormElements(newFormElements);
      setSelectedElement(duplicatedElement.id);

      setOptions((prevOptions) => {
        const newOptions = { ...prevOptions };
        newOptions[duplicatedElement.id] = [
          ...(prevOptions[elementToDuplicate.id] || []),
        ];
        return newOptions;
      });
    }
  };

  const validateForm = () => {
    let isValid = true;
    let newErrors = {};

    formElements.forEach((element) => {
      const label = customLabels[element.id]?.label || "";

      if (label.length > 100) {
        isValid = false;
        newErrors[element.id] = {
          ...validationErrors[element.id],
          label: "Label cannot exceed 100 characters",
        };
      } else if (!label?.trim()) {
        isValid = false;
        newErrors[element.id] = {
          ...validationErrors[element.id],
          label: "Label is required",
        };
      } else {
        newErrors[element.id] = {
          ...validationErrors[element.id],
          label: "",
        };
      }
      if (
        element.id.startsWith("single-select") ||
        element.id.startsWith("multiple-select") ||
        element.id.startsWith("radiobox") ||
        element.id.startsWith("checkbox")
      ) {
        const fieldOptions = options[element.id] || [];
        if (fieldOptions.length === 0) {
          isValid = false;
          newErrors[element.id] = {
            ...newErrors[element.id],
            optionsMessage: "At least one option is required",
          };
        } else {
          const hasEmptyOption = fieldOptions.some(
            (option) => option?.trim() === ""
          );
          if (hasEmptyOption) {
            isValid = false;
            newErrors[element.id] = {
              ...newErrors[element.id],
              optionsMessage: "Options cannot be empty",
            };
          } else {
            newErrors[element.id] = {
              ...newErrors[element.id],
              optionsMessage: "",
            };
          }
        }
      }
    });

    setValidationErrors(newErrors);
    return isValid;
  };

  const handleSubmit = () => {
    const isValid = validateForm();
    let errors = {};

    if (isValid) {
      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);
        return;
      }

      const listInspectionTypeFields = formElements?.map((element, index) => ({
        key: 0,
        value: customLabels[element.id]?.label || element.content,
        responseType: getResponseType(element.id),
        optionList: (options[element.id] || [])?.map((option) => ({
          key: 0,
          value: option,
        })),
        isDefault: customLabels[element.id]?.isDefault || false,
        isMandatory: customLabels[element.id]?.mandatory || false,
        isSelected: true,
        sequenceNumber: seqCount + index + 1,
      }));

      const mergeList = [
        ...props?.newlistInspectionTypeFields,
        ...listInspectionTypeFields,
      ];

      const data = {
        questionSetId: props?.questionSet,
        userId: parseInt(USER_ID()),
        listInspectionTypeFields: mergeList,
      };

      props?.updateInspectionFieldsMapData(data);
    } else {
      console.log("Form validation failed");
    }
  };

  const getResponseType = (id) => {
    if (id.startsWith("input"))
      return INSPECTION_HEADER_RESPONSE_TYPE.INPUT_TEXTBOX;
    if (id.startsWith("textarea"))
      return INSPECTION_HEADER_RESPONSE_TYPE.TEXTBOX;
    if (id.startsWith("single-select"))
      return INSPECTION_HEADER_RESPONSE_TYPE.SINGLE_SELECT;
    if (id.startsWith("multiple-select"))
      return INSPECTION_HEADER_RESPONSE_TYPE.MULTI_SELECT;
    if (id.startsWith("radiobox"))
      return INSPECTION_HEADER_RESPONSE_TYPE.RADIOBUTTON;
    if (id.startsWith("checkbox"))
      return INSPECTION_HEADER_RESPONSE_TYPE.CHECKBOX;
    return -1;
  };

  const handleNewOptionChange = (id, e) => {
    setNewOptions((prevState) => ({
      ...prevState,
      [id]: e.target.value,
    }));
  };

  return (
    <>
      <div className="containerBox">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="fields">
            {(provided) => (
              <div
                className="left"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <h2 className="title">Elements</h2>
                {fields?.map((field, index) => (
                  <Draggable
                    key={field.id}
                    draggableId={field.id}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        className="field"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <span>
                          <img src={field.img} alt="" />
                        </span>
                        {field.content}{" "}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <Droppable droppableId="form-builder">
            {(provided) => (
              <div
                className="right"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {formElements?.length === 0 && (
                  <div className="overideFormDropBox">
                    <div className="dropbox">
                      <div className="dragTitle">
                        Drag and drop elements here{" "}
                      </div>
                    </div>
                  </div>
                )}
                {formElements?.map((element, index) => (
                  <Draggable
                    key={element.id}
                    draggableId={element.id}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onClick={() => setSelectedElement(element.id)}
                      >
                        <div className="innerBox">
                          <div className="dragIcon">
                            <DragIndicatorIcon />
                          </div>
                          <div className="formElements">
                            <div>
                              <Grid container mb={2}>
                                <Grid item md={4} xs={12}>
                                  <FormControl fullWidth>
                                    <InputField
                                      type="text"
                                      label={"Enter Field Name:"}
                                      placeholder={"Enter Field Here..."}
                                      value={
                                        customLabels[element.id]?.label || ""
                                      }
                                      onChange={(e) =>
                                        updateCustomLabel(
                                          element.id,
                                          e.target.value
                                        )
                                      }
                                      isRequired={true}
                                    />
                                  </FormControl>
                                  {validationErrors[element.id]?.label && (
                                    <div className="error">
                                      {validationErrors[element.id].label}
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                            {element.id.startsWith("input") && (
                              <>
                                <FormControl fullWidth>
                                  <InputField
                                    isRequired={
                                      customLabels[element.id]?.mandatory
                                    }
                                    type="text"
                                    label={
                                      customLabels[element.id]?.label ||
                                      element.content
                                    }
                                    placeholder={""}
                                    onChange={(e) => e}
                                  />
                                </FormControl>
                                <div
                                  className="removeSection"
                                  onClick={() => removeFormElement(index)}
                                >
                                  <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M2.03484 14L0.714844 12.6L5.99484 7L0.714844 1.4L2.03484 0L7.31484 5.6L12.5948 0L13.9148 1.4L8.63484 7L13.9148 12.6L12.5948 14L7.31484 8.4L2.03484 14Z"
                                      className="headerRemoveIcon"
                                    />
                                  </svg>

                                  <span>Remove Field</span>
                                </div>
                              </>
                            )}
                            {element.id.startsWith("textarea") && (
                              <>
                                <FormLabels
                                  label={
                                    customLabels[element.id]?.label ||
                                    element.content
                                  }
                                  isRequired={
                                    customLabels[element.id]?.mandatory
                                  }
                                />
                                <TextArea
                                  value={""}
                                  placeholder="Enter Here"
                                  onChange={(e) => e}
                                />

                                <div
                                  className="removeSection"
                                  onClick={() => removeFormElement(index)}
                                >
                                  <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M2.03484 14L0.714844 12.6L5.99484 7L0.714844 1.4L2.03484 0L7.31484 5.6L12.5948 0L13.9148 1.4L8.63484 7L13.9148 12.6L12.5948 14L7.31484 8.4L2.03484 14Z"
                                      className="headerRemoveIcon"
                                    />
                                  </svg>
                                  <span>Remove Field</span>
                                </div>
                              </>
                            )}
                            {element.id.startsWith("single-select") && (
                              <>
                                <FormControl fullWidth>
                                  <FormLabels
                                    label={
                                      customLabels[element.id]?.label ||
                                      element.content
                                    }
                                    isRequired={
                                      customLabels[element.id]?.mandatory
                                    }
                                  />
                                  <SelectMenu
                                    placeholder="Please Select"
                                    listData={
                                      options[element.id]?.map(
                                        (option, indexOption) => ({
                                          key: indexOption,
                                          value: option,
                                        })
                                      ) || []
                                    }
                                    value={null}
                                    onchangehandler={(e) => e}
                                  />
                                </FormControl>
                                <div
                                  className="removeSection"
                                  onClick={() => removeFormElement(index)}
                                >
                                  <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M2.03484 14L0.714844 12.6L5.99484 7L0.714844 1.4L2.03484 0L7.31484 5.6L12.5948 0L13.9148 1.4L8.63484 7L13.9148 12.6L12.5948 14L7.31484 8.4L2.03484 14Z"
                                      className="headerRemoveIcon"
                                    />
                                  </svg>
                                  <span>Remove Field</span>
                                </div>
                              </>
                            )}
                            {element.id.startsWith("multiple-select") && (
                              <>
                                <FormControl fullWidth>
                                  <FormLabels
                                    label={
                                      customLabels[element.id]?.label ||
                                      element.content
                                    }
                                    isRequired={
                                      customLabels[element.id]?.mandatory
                                    }
                                  />
                                  <MultiSelectBox
                                    placeholder={"Please Select"}
                                    listData={
                                      options[element.id]?.map(
                                        (option, indexOption) => ({
                                          key: indexOption,
                                          value: option,
                                        })
                                      ) || []
                                    }
                                    onChange={(e) => e}
                                    value={[]}
                                  />
                                </FormControl>
                                <div
                                  className="removeSection"
                                  onClick={() => removeFormElement(index)}
                                >
                                  <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M2.03484 14L0.714844 12.6L5.99484 7L0.714844 1.4L2.03484 0L7.31484 5.6L12.5948 0L13.9148 1.4L8.63484 7L13.9148 12.6L12.5948 14L7.31484 8.4L2.03484 14Z"
                                      className="headerRemoveIcon"
                                    />
                                  </svg>
                                  <span>Remove Field</span>
                                </div>
                              </>
                            )}
                            {element.id.startsWith("radiobox") && (
                              <>
                                <FormControl fullWidth>
                                  <FormLabels
                                    label={
                                      customLabels[element.id]?.label ||
                                      element.content
                                    }
                                    isRequired={
                                      customLabels[element.id]?.mandatory
                                    }
                                  />
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    className="radioGroup"
                                  >
                                    {(options[element.id] || [])?.map(
                                      (option, i) => (
                                        <RadioButton
                                          key={i}
                                          value={`${element.id}-${i}`}
                                          label={option}
                                        />
                                      )
                                    )}
                                  </RadioGroup>
                                </FormControl>
                                <div
                                  className="removeSection"
                                  onClick={() => removeFormElement(index)}
                                >
                                  <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M2.03484 14L0.714844 12.6L5.99484 7L0.714844 1.4L2.03484 0L7.31484 5.6L12.5948 0L13.9148 1.4L8.63484 7L13.9148 12.6L12.5948 14L7.31484 8.4L2.03484 14Z"
                                      className="headerRemoveIcon"
                                    />
                                  </svg>
                                  <span>Remove Field</span>
                                </div>
                              </>
                            )}
                            {element.id.startsWith("checkbox") && (
                              <>
                                <FormControl component="fieldset">
                                  <FormLabels
                                    label={
                                      customLabels[element.id]?.label ||
                                      element.content
                                    }
                                    isRequired={
                                      customLabels[element.id]?.mandatory
                                    }
                                  />
                                  <FormGroup row>
                                    {(options[element.id] || [])?.map(
                                      (option, i) => (
                                        <FormControlLabel
                                          key={i}
                                          control={<Checkbox name={option} />}
                                          label={option}
                                          className="formBuilderCustom"
                                        />
                                      )
                                    )}
                                  </FormGroup>
                                </FormControl>
                                <div
                                  className="removeSection"
                                  onClick={() => removeFormElement(index)}
                                >
                                  <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M2.03484 14L0.714844 12.6L5.99484 7L0.714844 1.4L2.03484 0L7.31484 5.6L12.5948 0L13.9148 1.4L8.63484 7L13.9148 12.6L12.5948 14L7.31484 8.4L2.03484 14Z"
                                      className="headerRemoveIcon"
                                    />
                                  </svg>
                                  <span>Remove Field</span>
                                </div>
                              </>
                            )}

                            <div>
                              {(element.id.startsWith("single-select") ||
                                element.id.startsWith("multiple-select") ||
                                element.id.startsWith("radiobox") ||
                                element.id.startsWith("checkbox")) && (
                                <div className="optionBox">
                                  {(options[element.id] || [])?.map(
                                    (option, optionIndex) => (
                                      <div
                                        key={optionIndex}
                                        className="optionBoxInner"
                                      >
                                        <input
                                          placeholder="Enter Option Here..."
                                          type="text"
                                          className="optionInput"
                                          value={option}
                                          onChange={(e) =>
                                            updateOption(
                                              element.id,
                                              optionIndex,
                                              e.target.value
                                            )
                                          }
                                        />
                                        <div
                                          onClick={() =>
                                            removeOption(
                                              element.id,
                                              optionIndex
                                            )
                                          }
                                          className="optionRemove"
                                        >
                                          <svg
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M2.03484 14L0.714844 12.6L5.99484 7L0.714844 1.4L2.03484 0L7.31484 5.6L12.5948 0L13.9148 1.4L8.63484 7L13.9148 12.6L12.5948 14L7.31484 8.4L2.03484 14Z"
                                              className="optionRemoveIcon"
                                            />
                                          </svg>
                                        </div>
                                      </div>
                                    )
                                  )}

                                  <div className="optionBoxInner">
                                    <input
                                      placeholder="Enter New Option Here..."
                                      type="text"
                                      className="optionInput"
                                      value={newOptions[element.id]}
                                      onChange={(e) =>
                                        handleNewOptionChange(element.id, e)
                                      }
                                    />
                                  </div>

                                  {validationErrors[element.id]
                                    ?.optionsMessage && (
                                    <div className="error">
                                      {
                                        validationErrors[element.id]
                                          .optionsMessage
                                      }
                                    </div>
                                  )}

                                  <Button
                                    varientTextGreen={true}
                                    label={"Add Option"}
                                    onClick={() => addOption(element.id)}
                                  />
                                </div>
                              )}
                              <div className="bottomAction">
                                <FormGroup row className="mandatory">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          customLabels[element.id]?.mandatory ||
                                          false
                                        }
                                        onChange={(e) =>
                                          updateMandatory(
                                            element.id,
                                            e.target.checked
                                          )
                                        }
                                        name="Mandatory"
                                      />
                                    }
                                    label="Mandatory"
                                  />
                                </FormGroup>
                                {checkPrivileges([12]) &&
                                  parseInt(ORG_ID()) ===
                                    parseInt(HBNEXT_ORG_ID) && (
                                    <FormGroup row className="mandatory">
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={
                                              customLabels[element.id]
                                                ?.isDefault || false
                                            }
                                            onChange={(e) =>
                                              updateDefault(
                                                element.id,
                                                e.target.checked
                                              )
                                            }
                                            name="Default"
                                          />
                                        }
                                        label="Default"
                                      />
                                    </FormGroup>
                                  )}

                                <div
                                  className="duplicate"
                                  onClick={() =>
                                    duplicateFormElement(element.id)
                                  }
                                >
                                  <span>
                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g mask="url(#mask0_6358_12014)">
                                        <path
                                          d="M7.5 15C7.0875 15 6.73438 14.8531 6.44062 14.5594C6.14687 14.2656 6 13.9125 6 13.5V3.5C6 3.0875 6.14687 2.73438 6.44062 2.44063C6.73438 2.14688 7.0875 2 7.5 2H15.5C15.9125 2 16.2656 2.14688 16.5594 2.44063C16.8531 2.73438 17 3.0875 17 3.5V13.5C17 13.9125 16.8531 14.2656 16.5594 14.5594C16.2656 14.8531 15.9125 15 15.5 15H7.5ZM4.5 18C4.0875 18 3.73437 17.8531 3.44062 17.5594C3.14687 17.2656 3 16.9125 3 16.5V5H4.5V16.5H14V18H4.5Z"
                                          className="headerDuplicateIcon"
                                        />
                                      </g>
                                    </svg>
                                  </span>
                                  Duplicate Field
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}

                <Grid container mt={6}>
                  <Grid item md={2} xs={12}>
                    <Buttons
                      varientText={true}
                      label="Cancel"
                      onClick={props?.backHandler}
                    />
                  </Grid>
                  {formElements?.length > 0 && (
                    <Grid item md={3} xs={12} ml={2}>
                      <Button
                        varientContained={true}
                        label={"Save"}
                        onClick={handleSubmit}
                      />
                    </Grid>
                  )}
                </Grid>

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
};

export default AddHeaderConfiguration;