import axios from "axios";
import ReactGA from "react-ga4";
import { getGATRackingId } from "../config";
const gaID = getGATRackingId();
ReactGA.initialize(gaID);
const webAppType = 1; // FOR WEB

export const get = async (url, params, isAutoSearch = false) => {
  try {
    if (!isAutoSearch) {
      params.headers = { ...params.headers, AppTypeId: webAppType };
    }
    return await axios.get(url, params);
  } catch (err) {
    if (err?.code && err?.message) {
      return { error: { message: err.message, code: err.code } };
    }
    ReactGA.event({
      category: "API call error",
      action: "GET API CALL Fail" + url,
      label: url,
      value: status,
    });
    const {
      response: { data, status },
    } = err;

    if (status === 401) {
      window.location.href = "/access-denied";
      return;
    }
    if (status === 404) {
      return { error: { message: "Not Found", code: "NotFound" } };
    }
    else {
      return { error: { data, status } };
    }
  }
};

export const post = async (url, data, params) => {
  ReactGA.event({
    category: "API call error",
    action: "Post API CALL Fail" + url,
    label: url,
    // value:status
  });
  try {
      params.headers = { ...params.headers, AppTypeId: webAppType };
    return await axios.post(url, data, params);
  } catch (err) {
    ReactGA.event({
      category: "API call error",
      action: "Post API CALL Fail" + url,
      label: url,
      value: status,
    });
    const {
      response: { data, status },
    } = err;
    if (status === 401) {
      window.location.href = "/access-denied";
    } else if (status === 404) {
      return { error: { message: "Not Found", code: "NotFound" } };
    } else {
      return { error: { data, status } };
    }
  }
};

export const put = async (url, data, params) => {
  try {
    params.headers = { ...params.headers, AppTypeId: webAppType };
    return await axios.put(url, data, params);
  } catch (err) {
    ReactGA.event({
      category: "API call error",
      action: "Put API CALL Fail" + url,
      label: url,
      value: status,
    });
    const {
      response: { data, status },
    } = err;
    if (status === 401) {
      window.location.href = "/access-denied";
    } else if (status === 404) {
      return { error: { message: "Not Found", code: "NotFound" } };
    } else {
      return { error: { data, status } };
    }
  }
};

export const deletes = async (url, params, data) => {
  ReactGA.event({
    category: "API call error",
    action: url,
    label: "API CALL",
  });
  try {
    params.headers = { ...params.headers, AppTypeId: webAppType };
    return await axios.delete(url, params, data);
  } catch (err) {
    ReactGA.event({
      category: "API call error",
      action: "Delete API CALL Fail" + url,
      label: url,
      value: status,
    });
    const {
      response: { data, status },
    } = err;
    if (status === 401) {
      window.location.href = "/access-denied";
    } else if (status === 404) {
      return { error: { message: "Not Found", code: "NotFound" } };
    } else {
      return { error: { data, status } };
    }
  }
};
