import React, { useEffect, useState, useRef } from "react";
import Table from "../../../../library/custom/table/table";
import {
  COLORS,
  GET_ITEM_LOG,
  PAGE_SIZE,
  USER_ID,
} from "../../../../constant/constant";
import { useNavigate } from "react-router-dom";
import {
  CONDUCT_JHA_REPORT,
  COURSESDETAIL,
  LESSONDETAIL,
  REPORTS_ANALYTICS_DASHBOARD_ITEMS,
  REPORTS_ANALYTICS_LMS_DASHBOARD_ITEMS,
  TOOL_BOX_DETAIL,
} from "../../../../constant/routeContant";
import {
  downloadExcel,
  downloadPDF,
  getFormatedDate,
  useInfiniteScroll,
} from "../../../../utils/helper";
import commonImages from "../../../../assets";
import { Grid, Popover } from "@mui/material";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";
import LineChart from "../../../../library/custom/charts/linechart";

const CompletedTraining = (props) => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const pageSize = PAGE_SIZE;

  const [sortColumn, setSortColumn] = useState();
  const [sortDirection, setSortDirection] = useState("asc");
  const tableRef = useInfiniteScroll(() => fetchData(page + 1), [page]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const reportType = useRef();

  const formatDate = (date) => {
    const day = date?.getDate()?.toString()?.padStart(2, "0");
    const month = (date?.getMonth() + 1)?.toString()?.padStart(2, "0");
    const year = date?.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const getDaysDifference = (startDate, endDate) => {
    return Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
  };

  const generateDateRange = (startDate, endDate) => {
    const dates = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      dates?.push(new Date(currentDate));
      currentDate?.setDate(currentDate?.getDate() + 1);
    }
    return dates;
  };

  const aggregateData = (data) => {
    const aggregated = {};
    const startDate = new Date(data[0]?.date);
    const endDate = new Date(data[data.length - 1]?.date);
    const daysDifference = getDaysDifference(startDate, endDate);
    const isDaily = daysDifference <= 60;
    const allDates = generateDateRange(startDate, endDate);
    allDates.forEach((date) => {
      const year = date?.getFullYear();
      const month = (date?.getMonth() + 1)?.toString().padStart(2, "0");
      const day = date?.getDate()?.toString()?.padStart(2, "0");
      const key = isDaily ? `${year}-${month}-${day}` : `${year}-${month}`;
      aggregated[key] = {
        year,
        month,
        day: isDaily ? day : undefined,
        fullDate: isDaily ? formatDate(date) : `${month}-${year}`,
        lessonCount: 0,
        courseCount: 0,
        safetyMeetingCount: 0,
        jhaCount: 0,
      };
    });

    data.forEach((entry) => {
      const date = new Date(entry?.date);
      const year = date?.getFullYear();
      const month = (date?.getMonth() + 1)?.toString()?.padStart(2, "0");
      const day = date?.getDate()?.toString()?.padStart(2, "0");
      const key = isDaily ? `${year}-${month}-${day}` : `${year}-${month}`;

      if (aggregated[key]) {
        aggregated[key].lessonCount += entry.lessonCount || 0;
        aggregated[key].courseCount += entry.courseCount || 0;
        aggregated[key].safetyMeetingCount += entry.safetyMeetingCount || 0;
        aggregated[key].jhaCount += entry.jhaCount || 0;
      }
    });

    return Object.values(aggregated);
  };

  const generateLineChartData = (data) => {
    const aggregatedData = aggregateData(data);

    return {
      labels: aggregatedData.map((entry) => entry.fullDate),
      datasets: [
        {
          label: "Lessons",
          data: aggregatedData.map((entry) => entry.lessonCount || 0),
          borderColor: COLORS.BlueDress,
          backgroundColor: COLORS.BlueDress,
          borderWidth: 2,
          fill: false,
        },
        {
          label: "Courses",
          data: aggregatedData.map((entry) => entry.courseCount || 0),
          borderColor: COLORS.NeonPurple,
          backgroundColor: COLORS.NeonPurple,
          borderWidth: 2,
          fill: false,
        },
        {
          label: "Safety Meetings",
          data: aggregatedData.map((entry) => entry.safetyMeetingCount || 0),
          borderColor: COLORS.BrightAqua,
          backgroundColor: COLORS.BrightAqua,
          borderWidth: 2,
          fill: false,
        },
        {
          label: "Hazard Analysis",
          data: aggregatedData.map((entry) => entry.jhaCount || 0),
          borderColor: COLORS.MediumGreen,
          backgroundColor: COLORS.MediumGreen,
          borderWidth: 2,
          fill: false,
        },
      ],
    };
  };

  const linechartoptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (props?.getLmsDashboardReportDetails) {
      setData((prevData) => [
        ...prevData,
        ...props.getLmsDashboardReportDetails?.result,
      ]);
    }
  }, [props?.getLmsDashboardReportDetails]);

  const fetchMoreData = async (pageNumber) => {
    // const { apiData, reportDetails } = props.drillDetail;
    const data = {
      startDate: getFormatedDate(props.apiData.startDate),
      endDate: getFormatedDate(props.apiData.endDate),
      divisionId:
        props.apiData.divisionId === "AllDivision"
          ? 0
          : props.apiData.divisionId,
      reportDetails: reportType.current
        ? reportType.current
        : props.apiData.reportDetails,
      page: pageNumber,
      pageSize: 100,
    };
    try {
      const response = await props.getMoretDetails(data);
      return response.data || [];
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const fetchData = async (pageNumber) => {
    fetchMoreData(pageNumber);
    setPage(pageNumber);
  };

  const handleOnClickLesson = (id) => {
    props?.dataClear();
    navigate(LESSONDETAIL, {
      state: { lessonId: id, isAdminLesson: false, fromReportAnalytics: true },
    });
  };
  const handleOnCourse = (id) => {
    props?.dataClear();
    navigate(COURSESDETAIL, {
      state: {
        courseId: id,
        userId: USER_ID(),
        isAdminCourse: false,
        fromReportAnalytics: true,
      },
    });
  };
  const handleOnSafety = (id, toolboxTalkId) => {
    // dispatch(toolboxAction.clearToolBoxTalk());
    props?.handleClear();
    navigate(TOOL_BOX_DETAIL, {
      state: {
        fieldId: false,
        toolboxTalkId: toolboxTalkId,
        scheduleId: id,
        tabvalue: 1,
      },
    });
  };
  const handleOnHazard = (id) => {
    props?.handleClear();
    navigate(CONDUCT_JHA_REPORT, {
      state: { id: id, editMode: true },
    });
  };

  const handleSort = (column) => {
    const newDirection =
      sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newDirection);
    setSortColumn(column);
    const sortedData = [...data].sort((a, b) => {
      if (a[column] < b[column]) return newDirection === "asc" ? -1 : 1;
      if (a[column] > b[column]) return newDirection === "asc" ? 1 : -1;
      return 0;
    });
    setData(sortedData);
  };

  useEffect(() => {
    setData([]);
  }, []);

  const columns = [
    {
      id: "id",
      label: `Date Completed`,
      sortable: true,
      isSorted: sortColumn === "id",
      sortDirection,
    },
    {
      id: "name",
      label: `Training Type`,
      sortable: true,
      isSorted: sortColumn === "name",
      sortDirection,
    },
    {
      id: "count",
      label: "Training Name",
      sortable: true,
      isSorted: sortColumn === "count",
      sortDirection,
    },
    {
      id: "count",
      label: "Completed By",
      sortable: true,
      isSorted: sortColumn === "count",
      sortDirection,
    },
    {
      id: "blank1",
      label: "",
    },
  ];

  const rowsLesson = data?.map((lesson) => (
    <tr className="row" key={lesson?.id}>
      <td>{lesson.dateCompleted}</td>
      <td>{lesson.trainingType}</td>
      <td>{lesson.name}</td>
      <td>{lesson.userName}</td>
      <td>
        <ReportRowManipulte
          rows={lesson}
          view
          viewOnClick={() => handleOnClickLesson(lesson.id)}
          viewLabel={"View lesson"}
        />
      </td>
    </tr>
  ));

  const rowsCourse = data?.map((course) => (
    <tr className="row" key={course?.id}>
      <td>{course.dateCompleted}</td>
      <td>{course.trainingType}</td>
      <td>{course.name}</td>
      <td>{course.userName}</td>
      <td>
        <ReportRowManipulte
          rows={course}
          view
          viewOnClick={() => handleOnCourse(course.id)}
          viewLabel={"View Course"}
        />
      </td>
    </tr>
  ));

  const rowsSafety = data?.map((safety) => (
    <tr className="row" key={safety?.id}>
      <td>{safety.dateCompleted}</td>
      <td>{safety.trainingType}</td>
      <td>{safety.name}</td>
      <td>{safety.userName}</td>
      <td>
        <ReportRowManipulte
          rows={safety}
          view
          viewOnClick={() => handleOnSafety(safety.id, safety.toolboxId)}
          viewLabel={"View Safety Report"}
        />
      </td>
    </tr>
  ));

  const rowsHazard = data?.map((hazard) => (
    <tr className="row" key={hazard?.id}>
      <td>{hazard.dateCompleted}</td>
      <td>{hazard.trainingType}</td>
      <td>{hazard.name}</td>
      <td>{hazard.userName}</td>
      <td>
        <ReportRowManipulte
          rows={hazard}
          view
          viewOnClick={() => handleOnHazard(hazard.id)}
          viewLabel={"View"}
        />
      </td>
    </tr>
  ));

  const rowsPdfLesson = data?.map((lesson) => [
    lesson?.id,
    lesson?.name,
    lesson?.count,
  ]);

  return (
    <>
      <div className="overviewIns">
        <Grid className="commonBoxShodow" container spacing={2} mt={3}>
          <Grid item md={9} xs={12}>
            <Grid>
              <LineChart
                labels={
                  generateLineChartData(
                    props?.getLMSDashboardReport?.data
                      ?.completedTrainingComparison || []
                  ).labels
                }
                datasets={
                  generateLineChartData(
                    props?.getLMSDashboardReport?.data
                      ?.completedTrainingComparison || []
                  ).datasets
                }
                options={linechartoptions}
                height="auto"
              />
            </Grid>
          </Grid>
          <Grid item md={3} xs={12}>
            <Grid
              // onClick={() =>
              //   props.getMoretDetails({
              //     startDate: getFormatedDate(props?.apiData.startDate),
              //     endDate: getFormatedDate(props?.apiData.endDate),
              //     divisionId:
              //       props?.apiData.divisionId === "AllDivision"
              //         ? 0
              //         : props?.apiData.divisionId,
              //     reportDetails: 5,
              //     page: 1,
              //     pageSize: 100,
              //   })
              // }
              mb={2}
              className="commonBoxShodow"
            >
              <div className="titletop small">Total Training</div>
              <div className="discription red">
                {props?.getLMSDashboardReport?.data?.totalTrainingCount}
              </div>
            </Grid>
          </Grid>
          <Grid container gap={2} item md={12} xs={12}>
            <Grid
              onClick={() => {
                window.scrollTo(0, 500);
                props.setselectedCard("lesson");
                setData([]);
                setPage(1);
                reportType.current = 2;
                props.getMoretDetails({
                  startDate: getFormatedDate(props?.apiData.startDate),
                  endDate: getFormatedDate(props?.apiData.endDate),
                  divisionId:
                    props?.apiData.divisionId === "AllDivision"
                      ? 0
                      : props?.apiData.divisionId,
                  reportDetails: 2,
                  page: 1,
                  pageSize: 100,
                });
              }}
              mb={2}
              item
              md={2.5}
              className="commonBoxShodow cursor"
            >
              <div className="titletop small">Lessons</div>
              <div className="discription total">
                {props?.getLMSDashboardReport?.data?.totalLessonCount}
              </div>
            </Grid>
            <Grid
              onClick={() => {
                window.scrollTo(0, 500);
                props.setselectedCard("course");
                setData([]);
                setPage(1);
                reportType.current = 8;
                props.getMoretDetails({
                  startDate: getFormatedDate(props?.apiData.startDate),
                  endDate: getFormatedDate(props?.apiData.endDate),
                  divisionId:
                    props?.apiData.divisionId === "AllDivision"
                      ? 0
                      : props?.apiData.divisionId,
                  reportDetails: 3,
                  page: 1,
                  pageSize: 100,
                });
              }}
              mb={2}
              item
              md={2.5}
              className="commonBoxShodow cursor"
            >
              <div className="titletop small">Courses</div>
              <div className="discription purple">
                {props?.getLMSDashboardReport?.data?.totalCourseCount}
              </div>
            </Grid>
            <Grid
              // onClick={() => {
              //   window.scrollTo(0, 500);
              //   props.setselectedCard("safety");
              //   setData([]);
              //   setPage(1);
              //   reportType.current = 4;
              //   props.getMoretDetails({
              //     startDate: getFormatedDate(props?.apiData.startDate),
              //     endDate: getFormatedDate(props?.apiData.endDate),
              //     divisionId:
              //       props?.apiData.divisionId === "AllDivision"
              //         ? 0
              //         : props?.apiData.divisionId,
              //     reportDetails: 4,
              //     page: 1,
              //     pageSize: 100,
              //   });
              // }}
              mb={2}
              item
              md={2.5}
              className="commonBoxShodow"
            >
              <div className="titletop small">Safety Meetings</div>
              <div className="discription blue">
                {props?.getLMSDashboardReport?.data?.totalSafetyMeetingCount}
              </div>
            </Grid>
            <Grid
              onClick={() => {
                window.scrollTo(0, 500);
                props.setselectedCard("hazard");
                setData([]);
                setPage(1);
                reportType.current = 5;
                props.getMoretDetails({
                  startDate: getFormatedDate(props?.apiData.startDate),

                  endDate: getFormatedDate(props?.apiData.endDate),
                  divisionId:
                    props?.apiData.divisionId === "AllDivision"
                      ? 0
                      : props?.apiData.divisionId,

                  reportDetails: 5,
                  page: 1,
                  pageSize: 100,
                });
              }}
              mb={2}
              item
              md={2.5}
              className="commonBoxShodow cursor"
            >
              <div className="titletop small">Hazard Analysis</div>
              <div className="discription green">
                {props?.getLMSDashboardReport?.data?.totalHazardAnalysisCount}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <div className="customTableWrapper">
          <Table
            columns={columns}
            rows={
              props?.selectedCard === "lesson"
                ? rowsLesson
                : props?.selectedCard === "course"
                ? rowsCourse
                : props?.selectedCard === "safety"
                ? rowsSafety
                : props?.selectedCard === "hazard"
                ? rowsHazard
                : props?.apiData.reportDetails
            }
            tableRef={tableRef}
            onSort={handleSort}
          />
        </div>
      </div>
    </>
  );
};

export default CompletedTraining;
