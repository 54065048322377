import React, { useEffect } from "react";
import UserGroupList from "../component/groups/userGroupList";
import { useDispatch, useSelector } from "react-redux";
import * as userAction from "../../../redux/actions/userAction";
import {
  ORG_ID,
  PAGE_SIZE,
} from "../../../constant/constant";
import { PRODUCT_ID } from "../../../constant/storm-water/constant";
import * as adminAction from "../../../redux/actions/adminCommonAction";

const GroupsListContainer = () => {
  const dispatch = useDispatch();

  const GroupListData = useSelector((state) => state.GroupListData);

  const getuserGroupLists = (data) => {
    dispatch(userAction.getListGroupLists(data));
  };

  const deleteGroup = (userId) => {
    dispatch(userAction.deleteUserGroup(userId));
  };

  const divisiondata = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.divisons
  );

  const getDropdownDataKeyValueData = {
    orgId: ORG_ID(),
    flags: "6",
    productId: PRODUCT_ID(),
  };
  useEffect(() => {
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);

  const deleteUserGroupData = useSelector(
    (state) => state?.deleteUserGroupData
  );
  const loading = GroupListData.isLoading || deleteUserGroupData.isLoading;
  useEffect(() => {
    if (deleteUserGroupData?.status === "SUCCESS") {
      const data = {
        page: 1,
        pageSize: PAGE_SIZE,
        search: "",
        divisionId: 0,
        allGroups: true,
      };
      getuserGroupLists(data);
    }
  }, [deleteUserGroupData]);

  useEffect(() => {
    const data = {
      page: 1,
      pageSize: PAGE_SIZE,
      search: "",
      divisionId: 0,
      allGroups: true,
      orgId: ORG_ID(),
    };
    getuserGroupLists(data);
  }, []);

  return (
    <>
      <UserGroupList
        GroupListData={GroupListData?.data?.result?.groupData}
        noOfRecords={GroupListData?.data?.result?.noOfRecords}
        getuserGroupLists={getuserGroupLists}
        loading={loading}
        deleteGroup={deleteGroup}
        divisiondata={divisiondata}
      />
    </>
  );
};
export default GroupsListContainer;
