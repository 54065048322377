import React, { useState, useEffect, useRef } from "react";
import { FormControl, Grid } from "@mui/material";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import { InputField } from "../../../library/custom/textBox/InputField";
import DatePicker from "../../../library/custom/datePicker/DatePicker";
import TImePicker from "../../../library/custom/timePicker/TImePicker";
import RadioGroup from "@mui/material/RadioGroup";
import RadioButton from "../../../library/custom/radioButton/RadioButton";
import SearchAutoComplete from "../../../library/custom/searchAutoComplete/SearchAutoComplete";
import TextArea from "../../../library/custom/textArea/TextArea";
import Button from "../../../library/custom/button/Button";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import "./observationForm.scss";
import CreateAction from "../../../library/custom/createAction/CreateAction";
import { getFormatedDate } from "../../../utils/helper";
import {
  ORG_ID,
  INSPECTION_STATUS,
  TEXT_FIELD_ERROR_MSG,
  USER_ID,
  GET_USER_TYPE,
} from "../../../constant/constant";
import { InvolvedPartyOptionData } from "../../../constant/inspectionConstant";

const ObservationForm = ({
  handleSubmit,
  uploadFile,
  fileUploadData,
  searchOption,
  searchUsers,
  isAutoSearchDataLoading,
  getAssetOnUser,
  assetOnUserList,
  getSupervisorOnAsset,
  supervisorOnAssetList,
  fileError,
  setFileUploadData,
  setFileError,
}) => {
  const observedByName = localStorage.getItem("name");
  const todatDate = new Date();
  const [formValue, setFormValue] = useState({
    insObservationId: 0,
    observedBy: parseInt(USER_ID()),
    observedByName: observedByName,
    observationDate: new Date(),
    observationTime: todatDate.getHours() + ":" + todatDate.getMinutes(),
    location: "",
    jobSiteProjectName: "",
    involvedPartyType: "",
    addInvolvedParty: "",
    supervisor: "",
    observedType: 0,
    observedDetails: "",
    isActionTaken: false,
    status: INSPECTION_STATUS.Open,
    statusText: "",
    comment: "",
    addCorrectingParty: "",
    addCorrectingPartyId: "",
    involvedUserType: "",
    assignCorrectiveMeasures: "",
    escalateToReprimand: false,
    files: [],
  });

  function mappedData() {
    const type = parseInt(formValue.observedType);
    const data = {
      organisationId: ORG_ID(),
      insObservationId: 0,
      observedBy: parseInt(USER_ID()),
      observedByName: formValue.observedByName,
      observationDate: getFormatedDate(formValue.observationDate),
      observationTime: formValue.observationTime,
      location: formValue.location,
      observedEmployee: formValue?.addInvolvedParty.userId ?? null,
      jobSite: parseInt(formValue?.jobSiteProjectName) ?? null,
      observedType: type,
      observedDetails: formValue.observedDetails,
      isActionTaken: formValue.isActionTaken === "true" ? true : false,
      status: type === 1 ? INSPECTION_STATUS.Closed : INSPECTION_STATUS.Open,
      statusText: formValue.statusText,
      comment: formValue.comment,
      correctiveUserId: formValue?.addCorrectingParty ?? null,
      involvedUserType:
        (formValue?.involvedPartyType !== null &&
          formValue?.involvedPartyType) ??
        null,
      assignCorrectiveMeasures: "",
      escalateToReprimand: false,
      files: formValue.files,
      isFileAdded: formValue.files && formValue.files.length > 0,
    };
    let errors = false;

    if (formValue?.observationDate?.length === 0) {
      setIsDateOfObservation(true);
      errors = true;
    }

    if (formValue?.location?.trim() === "") {
      setFormValue({
        ...formValue,
        location: "",
      });
      setIsLocation(true);
      errors = true;
    }
    if (formValue?.addInvolvedParty?.length === 0) {
      setIsInvolvedParty(true);
      errors = true;
    }
    if (
      formValue?.observedType === 0 ||
      !(formValue?.observedType == "1" || formValue?.observedType === "2")
    ) {
      setIsObservedType(true);
      errors = true;
    }
    if (formValue?.jobSiteProjectName?.length === 0) {
      setIsJobsite(true);
      errors = true;
    }

    if (formValue?.addCorrectingParty?.length === 0) {
      setIsCorrectingParty(true);
      errors = true;
    }

    if (formValue.observedType === "2") {
      if (formValue?.comment?.trim() === "") {
        setFormValue({
          ...formValue,
          comment: "",
        });
        setIsComment(true);
        errors = true;
      }
    }
    if (formValue.observedType == 0) {
      setErrorObservationType(true);
    }
    if (formValue.involvedPartyType.length === 0) {
      setErrorEnvPartyType(true);
      errors = true;
    }

    if (errors) {
      return false;
    }
    return data;
  }

  const [isLocation, setIsLocation] = useState(false);
  const [isInvolvedParty, setIsInvolvedParty] = useState(false);
  const [isObservationType, setIsObservedType] = useState(false);
  const [isDateOfObservation, setIsDateOfObservation] = useState(false);
  const [isJobsite, setIsJobsite] = useState(false);
  const [isCorrectingParty, setIsCorrectingParty] = useState(false);
  const [isComment, setIsComment] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [supervisorData, setSupervisorData] = useState([]);
  const [jobSiteOptions, setJobSiteOptions] = useState(null);
  const [errorEnvPartyType, setErrorEnvPartyType] = useState(false);
  const [errorObservationType, setErrorObservationType] = useState(false);
  const handleEmployeeName = (e, value) => {
    setJobSiteOptions("");
    setSupervisorData([]);
    setFormValue({
      ...formValue,
      addInvolvedParty: value,
      addCorrectingParty: "",
      jobSiteProjectName: "",
    });
  };

  useEffect(() => {
    if (
      formValue.addInvolvedParty !== undefined &&
      formValue.addInvolvedParty !== null &&
      formValue.addInvolvedParty !== 0 &&
      formValue.addInvolvedParty !== "" &&
      formValue.addInvolvedParty.userId !== ""
    ) {
      getAssetOnUser(formValue.addInvolvedParty);
    }
  }, [formValue.addInvolvedParty]);

  useEffect(() => {
    if (fileUploadData) {
      setFormValue({
        ...formValue,
        files: [fileUploadData?.files[0]?.id],
      });
      const fileName = fileUploadData?.files[0]?.fileUrl?.split("/");
      const fileNameFind = fileName?.slice(-1);
      setSelectedFileName(fileNameFind);
      setFileError("");
    }
  }, [fileUploadData]);

  const handleUploadFileMethod = (fileUploaded, fileName, data) => {
    setSelectedFileName(fileName);
    uploadFile(fileUploaded);
  };

  const handleChangeCreateActionDesc = (value) => {
    setFormValue({
      ...formValue,
      observedDetails: value,
    });
  };

  function submitData() {
    //Need to write the validation method

    const data = mappedData();
    if (!data) {
    } else {
      handleSubmit(data);
    }
  }

  useEffect(() => {
    setJobSiteOptions(assetOnUserList);
  }, [assetOnUserList]);
  useEffect(() => {
    setSupervisorData(supervisorOnAssetList);
  }, [supervisorOnAssetList]);

  const removeActionImage = () => {
    setFormValue({ ...formValue, files: [] });
    setSelectedFileName("");
    setFileUploadData();
    setFileError("");
  };

  return (
    <>
      <div className="overidePersonal">
        <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left">
          <div className="heading_style">{"Add Observation"}</div>
        </Grid>
        <Grid container item md={9} xs={9} className="addShadowBox">
          <Grid item container mt={2} spacing={2}>
            <Grid item lg={6} xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <InputField
                  fullWidth={true}
                  disabled={true}
                  type="text"
                  label="Observed By"
                  value={observedByName}
                  placeholder="Enter name"
                  onChange={(e) => {
                    setFormValue({
                      ...formValue,
                      observedByName: e.target.value,
                    });
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            item
            className="addWitnessContainerGrid "
            container
            mt={3}
            spacing={2}
          >
            <Grid item lg={6} xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <FormLabels label={"Date of Observation"} isRequired={true} />
                <DatePicker
                  value={formValue.observationDate}
                  onChangeHandler={(e) =>
                    setFormValue({
                      ...formValue,
                      observationDate: e,
                    })
                  }
                  isError={isDateOfObservation}
                  errorMsg={isDateOfObservation ? "Date is Required" : ""}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <FormLabels label={"Time of Observation"} isRequired={true} />
                <TImePicker
                  disabled={false}
                  value={formValue.observationTime}
                  onChangeTimeHandler={(e) =>
                    setFormValue({
                      ...formValue,
                      observationTime: e.target.value,
                    })
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            item
            className="addWitnessContainerGrid "
            container
            mt={3}
            spacing={2}
          >
            <Grid item lg={6} xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <InputField
                  fullWidth={true}
                  type="text"
                  label="Location"
                  value={formValue.location}
                  placeholder="Add Location Here.."
                  isRequired={true}
                  onChange={(e) =>
                    setFormValue({
                      ...formValue,
                      location: e.target.value,
                    })
                  }
                  isError={isLocation}
                  errorMsg={
                    isLocation && !formValue.location
                      ? TEXT_FIELD_ERROR_MSG
                      : ""
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container mt={4} className="radioInline" mb={0}>
            <FormLabels
              label="Involved Party Type"
              labelPlacement="start"
              isRequired={true}
              className="involvedPartyType"
            />
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="radioGroup"
              isRequired={true}
            >
              {InvolvedPartyOptionData &&
                InvolvedPartyOptionData.map((item) => (
                  <RadioButton
                    key={item.key}
                    checked={formValue.involvedPartyType === item.key}
                    value={item.value}
                    label={item.label}
                    name={item.label}
                    onChange={(e) => {
                      setFormValue((prevState) => ({
                        ...prevState,
                        involvedPartyType: item.key,
                        addInvolvedParty: "",
                        jobSiteProjectName: "",
                        addCorrectingParty: "",
                      }));
                      setErrorEnvPartyType(false);
                      setJobSiteOptions("");
                      setSupervisorData([]);
                    }}
                  />
                ))}
              {errorEnvPartyType && (
                <p className="show-error">
                  Please Checked Involved Party Type{" "}
                </p>
              )}
            </RadioGroup>
          </Grid>
          {(formValue.involvedPartyType === 0 ||
            formValue.involvedPartyType === 1) && (
            <>
              <Grid item container mt={3} spacing={2}>
                <Grid item lg={6} xs={12} sm={12} md={6}>
                  <FormControl fullWidth>
                    <FormLabels label="Add Involved Party" isRequired={true} />
                    <SearchAutoComplete
                      optionsData={
                        isAutoSearchDataLoading ? [] : searchOption?.users ?? []
                      }
                      value={formValue?.addInvolvedParty ?? ""}
                      onChange={(event, value) =>
                        handleEmployeeName(event, value)
                      }
                      typeOnchange={(event) =>
                        searchUsers(
                          formValue.involvedPartyType === 1
                            ? 1
                            : GET_USER_TYPE.PREVNONPREVUSER,
                          event.target.value
                        )
                      }
                      isUserDetails={true}
                      isLoading={isAutoSearchDataLoading}
                      isError={isInvolvedParty}
                      errorMsg={
                        isInvolvedParty && !formValue.addInvolvedParty
                          ? TEXT_FIELD_ERROR_MSG
                          : ""
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item lg={6} xs={12} sm={12} md={6}>
                  <FormLabels label="Jobsite/Project" isRequired={true} />
                  <SelectMenu
                    listData={jobSiteOptions ?? []}
                    isRequired={true}
                    value={formValue.jobSiteProjectName}
                    onchangehandler={(e) => {
                      setFormValue({
                        ...formValue,
                        jobSiteProjectName: e.target.value,
                      });
                      getSupervisorOnAsset(e.target.value);
                    }}
                    isError={isJobsite}
                    errorMsg={
                      isJobsite && !formValue.jobSiteProjectName
                        ? TEXT_FIELD_ERROR_MSG
                        : ""
                    }
                  />
                </Grid>
              </Grid>
              <Grid container mt={3} spacing={2}>
                <Grid item lg={6} xs={12} sm={12} md={6}>
                  <FormControl fullWidth>
                    <FormLabels
                      label="Add Correcting Party"
                      isRequired={true}
                    />
                    <SelectMenu
                      disabled={supervisorData.length === 0}
                      listData={supervisorData}
                      value={
                        supervisorData.length > 0
                          ? formValue.addCorrectingParty
                          : ""
                      }
                      onchangehandler={(e) =>
                        setFormValue({
                          ...formValue,
                          addCorrectingParty: e.target.value,
                        })
                      }
                      isError={isCorrectingParty}
                      errorMsg={
                        isCorrectingParty && !formValue.addCorrectingParty
                          ? TEXT_FIELD_ERROR_MSG
                          : ""
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </>
          )}

          <Grid container mt={3} md={12} className="radioInline" mb={0}>
            <FormLabels
              label="Observation Type"
              isRequired={true}
              isError={isObservationType}
              errorMsg={
                isObservationType && !formValue.observedType
                  ? TEXT_FIELD_ERROR_MSG
                  : ""
              }
            />
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="radioGroup"
            >
              <RadioButton
                checked={formValue.observedType === "1"}
                value="1"
                label="Kudos"
                onChange={(e) =>
                  setFormValue(
                    {
                      ...formValue,
                      observedType: e.target.value,
                    },
                    setErrorObservationType(false)
                  )
                }
              ></RadioButton>
              <RadioButton
                checked={formValue.observedType === "2"}
                value="2"
                label="Corrective"
                onChange={(e) =>
                  setFormValue(
                    {
                      ...formValue,
                      observedType: e.target.value,
                    },
                    setErrorObservationType(false)
                  )
                }
              ></RadioButton>
              {errorObservationType && (
                <p className="show-error">Please Checked Observation Type </p>
              )}
            </RadioGroup>
          </Grid>

          <Grid container mt={3}>
            <Grid item lg={12} xs={12} sm={12} md={12}>
              <CreateAction
                singleAction={{
                  id: 1,
                  isFileError: fileError,
                  file: { name: fileError ? "" : selectedFileName },
                }}
                handleChangeActionDescription={handleChangeCreateActionDesc}
                handleUploadFile={handleUploadFileMethod}
                lable="Observation Details"
                value={formValue.observedDetails}
                showAssign={false}
                showCancel={false}
                placeholderText="Add Observation Details Here..."
                isRequired={true}
                removeActionImage={removeActionImage}
                fileError={fileError}
              />
            </Grid>
          </Grid>

          {formValue.observedType === "2" && (
            <>
              <Grid container mt={3} className="radioInline" mb={0}>
                <FormLabels label="Action Taken" />
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  className="radioGroup radioButtonInline"
                >
                  <RadioButton
                    checked={formValue.isActionTaken === "true"}
                    value="true"
                    label="Yes"
                    onChange={(e) =>
                      setFormValue({
                        ...formValue,
                        isActionTaken: e.target.value,
                      })
                    }
                  ></RadioButton>
                  <RadioButton
                    checked={formValue.isActionTaken === "false"}
                    value="false"
                    label="No"
                    onChange={(e) =>
                      setFormValue({
                        ...formValue,
                        isActionTaken: e.target.value,
                      })
                    }
                  ></RadioButton>
                </RadioGroup>
              </Grid>

              <Grid container mt={3}>
                <Grid item lg={12} xs={8} sm={12} md={12}>
                  <FormControl fullWidth>
                    <FormLabels label="Comment" isRequired={true} />
                    <TextArea
                      placeholder={"Add Comment Here.."}
                      value={formValue.comment}
                      onChange={(e) =>
                        setFormValue({
                          ...formValue,
                          comment: e.target.value,
                        })
                      }
                      isError={isComment}
                      errorMsg={
                        isComment && !formValue.comment
                          ? TEXT_FIELD_ERROR_MSG
                          : ""
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </>
          )}
          <Grid container mt={5} alignItems="center">
            <Grid lg={12} xs={12} sm={6} md={12} className="Observationsubmit">
              <Button
                className="obserButton"
                varientContained={true}
                label="Submit"
                onClick={submitData}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ObservationForm;
