import { LOGIN_ORG_ID, ORG_ID } from "../../constant/constant";
import { getApiUrl } from "../../config";
import { get, post } from "../axiosRequest";
import { dateValiadtor } from "../../utils/helper";

export const site = async ({ data }) => {
  const url = `${getApiUrl().adminSWApiUrl}Site/AddSite`;
  console.log("site" + url);
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateSite = async ({ data }) => {
  const url = `${getApiUrl().adminSWApiUrl}Site/UpdateSite`;
  console.log("updateSite" + url);
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getSiteDetsils = async ({ data }) => {
  const url = `${getApiUrl().adminSWApiUrl}Site/GetSiteDetails?siteId=${data}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const siteList = async ({ data }) => {
  const { pageSize, page, search, customerId, endDate, startDate, status } =
    data;

  const url = `${
    getApiUrl().adminSWApiUrl
  }Site/GetSiteList?search=${search}&fromDate=${startDate}&toDate=${endDate}&page=${page}&pageSize=${pageSize}&StatusId=${status}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: customerId,
      },
    });
  } catch (err) {
    return err;
  }
};
export const getSiteSearchList = async (searchText) => {
  const { search, customerId } = searchText?.data;
  const url = `${getApiUrl().searchApiUrl}/v1/getsitelist?orgId=${
    customerId ? customerId : (LOGIN_ORG_ID() && ORG_ID()) == 1 ? 1 : ORG_ID()
  }&search=${search?.trim()?.toLowerCase()}`;

  try {
    return await get(
      url,
      {
        headers: {
          Authorization: localStorage.getItem("id_token"),
        },
      },
      true
    );
  } catch (err) {
    return err;
  }
};

export const getSite = async ({ data }) => {
  const url = `${getApiUrl().adminSWApiUrl}Site/GetSite?siteId=${data}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
