import React from "react";
import FormControl from "@mui/material/FormControl";
import { InputField } from "../../../../../library/custom/textBox/InputField";
import DatePicker from "../../../../../library/custom/datePicker/DatePicker";
import TImePicker from "../../../../../library/custom/timePicker/TImePicker";
import { getCurrentDate, getFormatedDate } from "../../../../../utils/helper";
import FormLabels from "../../../../../library/custom/formLabel/FormLabels";
import Card from "../../../../../library/custom/card/Card";
import vehicleAccident from "../../../../../constant/accidentConstant";
import { Grid } from "@mui/material";
import SelectMenu from "../../../../../library/custom/selectMenu/SelectMenu";
import RadioGroup from "@mui/material/RadioGroup";
import RadioButton from "../../../../../library/custom/radioButton/RadioButton";
import TextEditor from "../../../../../library/custom/textEditor/TextEditor";
import { SOILLIST } from "../../../../../constant/storm-water/constant";
import { NumberField } from "../../../../../library/custom/numberField/numberField";
import Checkbox from "../../../../../library/checkbox/checkbox";
import { handleMinsonChangeRainfall } from "../../../../../utils/helper";
const SwCommonForm = ({
  formValue,
  setFormValue,
  getInTyFeByInTyList,
  isProjectName,
  isCustomerName,
  isProjectNo,
  isPrimaryInspector,
  isDateofInspection,
  isTimeofInspection,
  isWeatherAtTimeofInspection,
  isSoilTypeId,
  isStageOfConstruction,
  isSampleTaken,
  isPrimaryPermitteeName,
  isCurrentApproximateDisturbedAcreage,
  isNPDESTrackingNumber,
  getSwReference,
  listPermitte,
  mapId,
  inspectionHeaderDetails,
  constructionActivityId,
  handleConstructionActivitySetChange,
  isConstructionActivityId,
  projectStatus,
  setProjectStatus,
  alsoSubmitRoutineInspection,
  handleChangeDefault,
  inspectionTypeId,
  characterLimit,
  isRainFallError,
  isDirectIns,
  editMode,
  frequencyValue,
  handleFrequencyChange,
  frequency,
  frequencyInDays,
  dateRangeHandler,
  dateRangeError,
  errorFrequencyInDays,
}) => {
  const date = new Date();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const time = `${hours}:${formattedMinutes}`;

  return (
    <>
      <Grid container className="formController" mt={5}>
        <Grid container item md={8} xs={12} spacing={2}>
          {mapId && (
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <InputField
                  fullWidth={true}
                  disabled={true}
                  type="text"
                  label={"Inspection Name"}
                  value={formValue?.questionSetName}
                />
              </FormControl>
            </Grid>
          )}

          {getInTyFeByInTyList?.map((field) => {
            if (field?.key === 1 && field.isSelected && mapId) {
              return (
                <Grid item md={6} xs={12} key={field.key}>
                  <FormControl fullWidth>
                    <InputField
                      fullWidth={true}
                      disabled={true}
                      type="text"
                      label={"Project Name"}
                      value={formValue?.projectName}
                      onChange={(e) =>
                        setFormValue({
                          ...formValue,
                          projectName: e.target.value,
                        })
                      }
                      isRequired={field.isMandatory}
                    />
                  </FormControl>
                  {isProjectName && !formValue?.projectName && (
                    <div className="error">
                      Please enter this required field.
                    </div>
                  )}
                </Grid>
              );
            } else if (field?.key === 9 && field.isSelected && mapId) {
              return (
                <Grid item md={6} xs={12} key={field.key}>
                  <FormControl fullWidth>
                    <InputField
                      fullWidth={true}
                      disabled={true}
                      type="text"
                      label={"Client Name"}
                      value={formValue?.customerName}
                      onChange={(e) =>
                        setFormValue({
                          ...formValue,
                          customerName: e.target.value,
                        })
                      }
                      isRequired={field.isMandatory}
                    />
                  </FormControl>
                  {isCustomerName && !formValue?.customerName && (
                    <div className="error">
                      Please enter this required field.
                    </div>
                  )}
                </Grid>
              );
            } else if (field?.key === 8 && field.isSelected && mapId) {
              return (
                <Grid item md={6} xs={12} key={field.key}>
                  <FormControl fullWidth>
                    <InputField
                      fullWidth={true}
                      disabled={true}
                      type="text"
                      label={"Report ID"}
                      value={formValue?.reportId}
                      onChange={(e) =>
                        setFormValue({
                          ...formValue,
                          reportId: e.target.value,
                        })
                      }
                      isRequired={field.isMandatory}
                    />
                  </FormControl>
                  {isCustomerName && !formValue?.reportId && (
                    <div className="error">
                      Please enter this required field.
                    </div>
                  )}
                </Grid>
              );
            } else if (field?.key === 12 && field.isSelected && mapId) {
              return (
                <>
                  <Grid item md={6} xs={12} key={field.key}>
                    <FormControl fullWidth>
                      <FormLabels label="Inspection Type" isRequired={true} />
                      <SelectMenu
                        placeholder="Please Select"
                        listData={getSwReference?.insTypeMstList ?? []}
                        value={inspectionTypeId ? inspectionTypeId : null}
                        disabled={true}
                      />
                    </FormControl>

                    {isCustomerName && !formValue?.inspectionType && (
                      <div className="error">
                        Please enter this required field.
                      </div>
                    )}
                    {(editMode
                      ? !isDirectIns && parseInt(inspectionTypeId) === 2
                      : parseInt(inspectionTypeId) === 2) && (
                      <div className="alsoRoutine">
                        <Checkbox
                          checked={alsoSubmitRoutineInspection}
                          onChange={() => handleChangeDefault()}
                          label={"Also a routine Inspection"}
                        />
                      </div>
                    )}
                  </Grid>

                  {((inspectionTypeId === 1 || inspectionTypeId === 3) || alsoSubmitRoutineInspection) && (
                    <Grid lg={6} xs={12} sm={6} md={6} item>
                      <FormControl fullWidth>
                        <FormLabels label="Frequency" />
                        <SelectMenu
                          placeholder="Please Select"
                          listData={frequency ?? []}
                          value={frequencyValue ? frequencyValue : null}
                          onchangehandler={(e) => handleFrequencyChange(e)}
                          // disabled={true}
                          // isError={isFrequency}
                          // errorMsg={isFrequency ? "Frequency is required" : ""}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {parseInt(frequencyValue) === 7 && (
                    <Grid item lg={6} xs={12} sm={12} md={6}>
                      <FormControl fullWidth={true}>
                        <NumberField
                          // disabled={true}
                          isRequired={true}
                          type="text"
                          label={"Date Range"}
                          maxLength="3"
                          value={frequencyInDays}
                          placeholder={"Enter Frequency In Days..."}
                          onChange={(value) => dateRangeHandler(value)}
                        />
                      </FormControl>
                      {dateRangeError && (
                        <p className="error">
                          Number should be between 1 to 365
                        </p>
                      )}
                      {errorFrequencyInDays && (
                        <p className="error">Please add frequencyInDays </p>
                      )}
                    </Grid>
                  )}
                </>
              );
            } else if (field?.key === 2 && field.isSelected) {
              return (
                <Grid item md={6} xs={12} key={field?.key}>
                  <FormControl fullWidth>
                    <InputField
                      fullWidth={true}
                      disabled={true}
                      type="text"
                      label={"Project No."}
                      value={formValue?.projectNo}
                      onChange={(e) =>
                        setFormValue({
                          ...formValue,
                          projectNo: e.target.value,
                        })
                      }
                      isRequired={field.isMandatory}
                    />
                  </FormControl>
                  {isProjectNo && (
                    <div className="error">
                      Please enter this required field.
                    </div>
                  )}
                </Grid>
              );
            } else if (field?.key === 13 && field.isSelected) {
              return (
                <Grid item md={6} xs={12} key={field?.key}>
                  <FormControl fullWidth>
                    <InputField
                      fullWidth={true}
                      disabled={true}
                      type="text"
                      label={"Name of Inspector"}
                      value={formValue?.primaryInspector}
                      onChange={(e) =>
                        setFormValue({
                          ...formValue,
                          primaryInspector: e.target.value,
                        })
                      }
                      isRequired={field.isMandatory}
                    />
                  </FormControl>
                  {isPrimaryInspector && !formValue?.primaryInspector && (
                    <div className="error">
                      Please enter this required field.
                    </div>
                  )}
                </Grid>
              );
            } else if (field?.key === 10 && field.isSelected) {
              return (
                <Grid item md={6} xs={12} key={field?.key}>
                  <FormLabels
                    label={"Date of Inspection"}
                    isRequired={field.isMandatory}
                  />
                  <DatePicker
                    onChangeHandler={(e) =>
                      setFormValue({ ...formValue, dateofInspection: e })
                    }
                    value={
                      formValue?.dateofInspection
                        ? formValue?.dateofInspection
                        : getFormatedDate(date)
                    }
                    maxDateEnable={false}
                    isError={isDateofInspection}
                    errorMsg={
                      isDateofInspection
                        ? "Please enter this required field"
                        : ""
                    }
                  />
                  {/* {isDateofInspection && (
                    <div className="error">
                      Please enter this required field.
                    </div>
                  )} */}
                </Grid>
              );
            } else if (field?.key === 3 && field.isSelected) {
              return (
                <Grid item md={6} xs={12} key={field?.key}>
                  <FormControl fullWidth>
                    <FormLabels
                      label={"Time of Inspection"}
                      isRequired={field.isMandatory}
                    />
                    <TImePicker
                      disabled={false}
                      value={
                        formValue.timeofInspection
                          ? formValue.timeofInspection
                          : time
                      }
                      onChangeTimeHandler={(e) =>
                        setFormValue({
                          ...formValue,
                          timeofInspection: e.target.value,
                        })
                      }
                    />
                  </FormControl>
                  {isTimeofInspection && !formValue.timeofInspection && (
                    <div className="error">
                      Please select this required field.
                    </div>
                  )}
                </Grid>
              );
            } else if (field?.key === 4 && field.isSelected) {
              return (
                <Grid item md={12} xs={12} key={field?.key}>
                  <FormLabels
                    label="Weather at Time of Inspection"
                    isRequired={field.isMandatory}
                  />
                  <Card
                    staticCardData={true}
                    cardData={vehicleAccident}
                    selectedVehicleAccident={
                      formValue.WeatherAtTimeofInspection
                    }
                    cardClickHandler={(val) =>
                      setFormValue({
                        ...formValue,
                        WeatherAtTimeofInspection: val,
                      })
                    }
                  />
                  {isWeatherAtTimeofInspection &&
                    !formValue.WeatherAtTimeofInspection && (
                      <div className="error">
                        Please select this required field.
                      </div>
                    )}
                </Grid>
              );
            } else if (field?.key === 17 && field.isSelected) {
              return (
                <Grid item md={12} xs={12} key={field?.key}>
                  <FormLabels
                    label="Soil Condition"
                    isRequired={field.isMandatory}
                  />
                  <Card
                    staticCardData={true}
                    cardData={SOILLIST}
                    selectedVehicleAccident={formValue.soilTypeId}
                    cardClickHandler={(val) =>
                      setFormValue({
                        ...formValue,
                        soilTypeId: val,
                      })
                    }
                  />
                  {isSoilTypeId && !formValue.soilTypeId && (
                    <div className="error">
                      Please select this required field.
                    </div>
                  )}
                </Grid>
              );
            } else if (field?.key === 14 && field.isSelected) {
              return (
                <Grid item md={6} xs={12} key={field?.key}>
                  <FormControl fullWidth>
                    <FormLabels
                      label="Stage of Construction"
                      isRequired={field.isMandatory}
                    />
                    <SelectMenu
                      placeholder="Please Select"
                      listData={getSwReference?.constructionStageList ?? []}
                      value={
                        formValue?.constructionStageId
                          ? formValue?.constructionStageId
                          : null
                      }
                      onchangehandler={(e) =>
                        setFormValue({
                          ...formValue,
                          constructionStageId: e.target.value,
                        })
                      }
                      isError={isStageOfConstruction}
                      errorMsg={
                        isStageOfConstruction && !formValue?.constructionStageId
                          ? "Please enter this required field."
                          : ""
                      }
                    />
                  </FormControl>
                  {/* {isProjectName && (
                    <div className="error">Please enter this required field.</div>
                  )} */}
                </Grid>
              );
            } else if (field?.key === 6 && field.isSelected) {
              return (
                <Grid item md={12} xs={12} key={field?.key}>
                  <FormLabels
                    label={"Sample Taken"}
                    isRequired={field.isMandatory}
                  />
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    className="radioGroup"
                  >
                    <RadioButton
                      checked={formValue.sampleTaken == "3"}
                      value="3"
                      label="Yes"
                      onChange={(e) =>
                        setFormValue({
                          ...formValue,
                          sampleTaken: e.target.value,
                        })
                      }
                    ></RadioButton>
                    <RadioButton
                      checked={formValue.sampleTaken == "1"}
                      value="1"
                      label="No"
                      onChange={(e) =>
                        setFormValue({
                          ...formValue,
                          sampleTaken: e.target.value,
                        })
                      }
                    ></RadioButton>
                  </RadioGroup>
                  {isSampleTaken && !formValue.sampleTaken && (
                    <div className="error">
                      Please select this required field.
                    </div>
                  )}
                </Grid>
              );
            } else if (field?.key === 15 && field.isSelected) {
              return (
                <Grid item md={12} xs={12} key={field?.key}>
                  <>
                    <div className="listPermitteContainer">
                      <FormLabels label={"Primary Permittee Name:"} />
                      <ol className="listCommonForm">
                        {listPermitte?.length > 0
                          ? listPermitte?.map((x, index) => {
                              return (
                                <li key={index}>{x.primaryPermitteName}</li>
                              );
                            })
                          : "N/A"}
                      </ol>
                    </div>
                  </>
                </Grid>
              );
            } else if (field?.key === 5 && field.isSelected) {
              return (
                <Grid item md={6} xs={12} key={field?.key}>
                  <FormControl fullWidth>
                    <InputField
                      fullWidth={true}
                      // disabled={true}
                      type="text"
                      label={"Rainfall Amount (inches)"}
                      value={formValue?.rainfall}
                      onChange={(event, value) =>
                        handleMinsonChangeRainfall(
                          event,
                          setFormValue,
                          formValue
                        )
                      }
                      isRequired={field.isMandatory}
                    />
                  </FormControl>
                  {isRainFallError && !formValue?.rainfall && (
                    <div className="error">
                      Please enter this required field.
                    </div>
                  )}
                </Grid>
              );
            } else if (field?.key === 16 && field.isSelected) {
              return (
                <Grid item md={6} xs={12} key={field?.key}>
                  <FormControl fullWidth className="approximate">
                    <InputField
                      fullWidth={true}
                      disabled={false}
                      type="text"
                      label={"Current Approximate Disturbed Acreage"}
                      value={formValue?.disturbedAcreage}
                      onChange={(e) =>
                        setFormValue({
                          ...formValue,
                          disturbedAcreage: e.target.value,
                        })
                      }
                      isRequired={field.isMandatory}
                    />
                  </FormControl>
                  {isCurrentApproximateDisturbedAcreage &&
                    !formValue?.disturbedAcreage && (
                      <div className="error">
                        Please enter this required field.
                      </div>
                    )}
                </Grid>
              );
            } else if (field?.key === 7 && field.isSelected) {
              return (
                <Grid item md={12} xs={12} key={field?.key}>
                  <>
                    <div className="listPermitteContainer listPermiteSpace">
                      <FormLabels label={"Permit Number:"} />
                      <ol className="listCommonForm">
                        {listPermitte?.length > 0
                          ? listPermitte?.map((x, index) => {
                              return (
                                <li key={index}>{x.permitteOrNPDESNumber}</li>
                              );
                            })
                          : "N/A"}
                      </ol>
                    </div>
                  </>
                </Grid>
              );
            }
          })}

          {getInTyFeByInTyList?.map((field) => {
            if (field?.key === 19 && field.isSelected) {
              return (
                <Grid item md={6} xs={12} key={field?.key}>
                  <FormControl fullWidth>
                    <InputField
                      fullWidth={true}
                      disabled={true}
                      type="text"
                      label={"Site Name"}
                      value={
                        formValue?.isStandalone === false
                          ? inspectionHeaderDetails?.siteName
                          : "N/A"
                      }
                      isRequired={field.isMandatory}
                    />
                  </FormControl>
                </Grid>
              );
            } else if (field?.key === 18 && field.isSelected) {
              return (
                <Grid item md={6} xs={12} key={field?.key}>
                  <FormControl fullWidth>
                    <FormLabels
                      label="Construction Activity"
                      isRequired={field.isMandatory}
                    />
                    <SelectMenu
                      placeholder="Please Select"
                      listData={getSwReference?.constructionActivity ?? []}
                      value={
                        constructionActivityId ? constructionActivityId : null
                      }
                      onchangehandler={(e) =>
                        handleConstructionActivitySetChange(e)
                      }
                      isError={isConstructionActivityId}
                      errorMsg={
                        isConstructionActivityId
                          ? "Construction Activity is required"
                          : ""
                      }
                    />
                  </FormControl>
                </Grid>
              );
            } else if (field?.key === 20 && field.isSelected) {
              return (
                <Grid item md={6} xs={12} key={field?.key}>
                  <FormControl fullWidth>
                    <InputField
                      fullWidth={true}
                      disabled={true}
                      type="text"
                      label={"Division"}
                      value={inspectionHeaderDetails?.divisionName}
                      isRequired={field.isMandatory}
                    />
                  </FormControl>
                </Grid>
              );
            } else if (field?.key === 21 && field.isSelected) {
              return (
                <Grid item md={6} xs={12} key={field?.key}>
                  <FormControl fullWidth>
                    <InputField
                      fullWidth={true}
                      disabled={true}
                      type="text"
                      label={"Inspector Phone Number"}
                      value={inspectionHeaderDetails?.phoneNumber}
                      isRequired={field.isMandatory}
                    />
                  </FormControl>
                </Grid>
              );
            } else if (field?.key === 22 && field.isSelected) {
              return (
                <Grid item md={6} xs={12} key={field?.key}>
                  <FormControl fullWidth>
                    <InputField
                      fullWidth={true}
                      disabled={true}
                      type="text"
                      label={"Company Preforming Inspection"}
                      value={
                        inspectionHeaderDetails?.companyPerformingInspection
                      }
                      isRequired={field.isMandatory}
                    />
                  </FormControl>
                </Grid>
              );
            } else if (field?.key === 23 && field.isSelected) {
              return (
                <Grid item md={6} xs={12} key={field?.key}>
                  <FormControl fullWidth>
                    <FormLabels
                      label="Project Status"
                      isRequired={field.isMandatory}
                    />
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      className="radioGroup"
                    >
                      <RadioButton
                        checked={projectStatus === "1"}
                        value="1"
                        label="Active"
                        onChange={(e) => setProjectStatus(e.target.value)}
                      ></RadioButton>
                      <RadioButton
                        checked={projectStatus === "2"}
                        value="2"
                        label="Inactive"
                        onChange={(e) => setProjectStatus(e.target.value)}
                      ></RadioButton>
                    </RadioGroup>
                  </FormControl>
                </Grid>
              );
            } else if (field?.key === 24 && field.isSelected) {
              return (
                <Grid item md={6} xs={12} key={field?.key}>
                  <FormControl fullWidth>
                    <InputField
                      fullWidth={true}
                      disabled={true}
                      type="text"
                      label={"State"}
                      value={inspectionHeaderDetails?.stateName}
                      isRequired={field.isMandatory}
                    />
                  </FormControl>
                </Grid>
              );
            } else if (field?.key === 25 && field.isSelected) {
              return (
                <Grid item md={6} xs={12} key={field?.key}>
                  <FormControl fullWidth>
                    <InputField
                      fullWidth={true}
                      disabled={true}
                      type="text"
                      label={"Address"}
                      value={inspectionHeaderDetails?.address}
                      isRequired={field.isMandatory}
                    />
                  </FormControl>
                </Grid>
              );
            }
          })}

          <Grid item md={12} xs={12}>
            <FormLabels label="General Comments" />

            <TextEditor
              value={formValue?.generalComments}
              placeholder={"Enter Here..."}
              onChange={(e) =>
                setFormValue({ ...formValue, generalComments: e })
              }
            />
            {!characterLimit && formValue?.generalComments?.length > 2000 && (
              <p className="error">Character limit exceeded.</p>
            )}
            {characterLimit && (
              <p className="error">Character limit exceeded 2000.</p>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SwCommonForm;
