import { getApiUrl } from "../config";
import { get, post } from "./axiosRequest";
import { ORG_ID, PAGE_SIZE, USER_ID } from "../constant/constant";

export const postReprimandService = async ({ payload }) => {
  const url = `${getApiUrl().incpectionApiUrl}Inspection/AddReprimands`;
  try {
    return await post(url, payload, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
export const updateReprimandService = async ({ payload }) => {
  const url = `${getApiUrl().incpectionApiUrl}Inspection/updatereprimands`;
  try {
    return await post(url, payload, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getReprimandListService = async ({ payload }) => {
  const { page, searchText, fromdate, todate, empTypes, status, isSendMail } =
    payload;

  const url = `${
    getApiUrl().incpectionApiUrl
  }Inspection/GetReprimandsList?organisationId=${ORG_ID()}&page=${page}&fromdate=${fromdate}&status=${status}&empType=${
    empTypes ? empTypes : ""
  }&searchText=${searchText}&todate=${todate}&pageSize=${PAGE_SIZE}&isSendMail=${isSendMail}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getReprimandDetailsService = async ({ payload }) => {
  const url = `${
    getApiUrl().incpectionApiUrl
  }Inspection/GetReprimandDetail?reprimandsId=${payload}`;
  console.log("GetReprimandDetail", url);
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
