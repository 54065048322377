import * as lessonsAction from "../actions/lmsAction";
import { lmsType } from "../constants/lmsType";
import {
  getLessonsList,
  createLesson,
  getLessonDetails,
  updateLessonList,
  deleteLesson,
  publishLessons,
  assignLessons,
  getLessonAssignedUserList,
  completeLesson,
  sendcourseAndLessonAlertMail,
  getCompletedlessons,
  unAssignLesson,
  unEnrollLesson,
  searchLessons,
  getMappedGlobalLessonList,
  specifyLessonToUserViewClient,
  specifyLessonToUserViewLesson,
} from "../../services/lessonsService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

export function* getLessonsListSaga(params) {
  try {
    const { data, status, error } = yield call(getLessonsList, params);

    if (!data && status !== 200) throw apiError(error);
    const lessonsPage = lmsType.LESSONS_PAGE;
    yield put(
      lessonsAction.getLessonsListSuccess({
        lessonsPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Lesson List Failed" };
    yield put(lessonsAction.getLessonsListFailure(data));
  }
}
export function* createLessonSaga(param) {
  const payload = param.data;

  try {
    const { data, status, error } = yield call(createLesson, payload);
    if (!data && status !== 200) throw apiError(error);
    const casePage = lmsType.CREATE_PAGE;
    yield put(
      lessonsAction.createLessonSuccess({
        casePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create Case Failed" };
    yield put(lessonsAction.createLessonFailure(data));
  }
}

// get details
export function* getLessonDetailsSaga(payload) {
  try {
    const { data, status, error } = yield call(getLessonDetails, payload);

    let islessonDtaidata = data?.lessons;

    if (!data && status !== 200) throw apiError(error);
    yield put({
      type: lmsType.GET_LESSON_DETAILS_SUCCESS,
      payload: islessonDtaidata,
    });
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Reprimand List Failed" };
    yield put({
      type: lmsType.GET_LESSON_DETAILS_FAILED,
      error: data,
    });
  }
}
export function* updatelessonsSaga(param) {
  const payload = param.payload;
  try {
    const { data, status, error } = yield call(updateLessonList, payload);

    if (!data && status !== 200) throw apiError(error);
    const updatePage = lmsType.UPDATE_PAGE;
    yield put(
      lessonsAction.updateLessonsSuccess({
        updatePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "update Case Failed" };
    yield put(lessonsAction.updateLessonsFailure(data));
  }
}

function* deleteLessonSaga(payload) {
  try {
    const { data, status } = yield call(deleteLesson, payload);
    const lessonPage = lmsType.LESSONS_PAGE;

    yield put(
      lessonsAction.deleteLessonSuccess({
        lessonPage,
        data,
        status,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Course List Failed" };
    yield put(lessonsAction.deleteLessonFailure(data));
  }
}

export function* publishLessonSaga(param) {
  const payload = param.data;
  try {
    const { data, status, error } = yield call(publishLessons, payload);
    if (!data && status !== 200) throw apiError(error);
    const publishLesson = lmsType.publishLesson;
    yield put(
      lessonsAction.publishLessonSuccess({
        publishLesson,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Publish Lesson Failed" };
    yield put(lessonsAction.publishLessonFailure(data));
  }
}

export function* assignLessonSaga(param) {
  const payload = param.data;
  try {
    const { data, status, error } = yield call(assignLessons, payload);
    if (!data && status !== 200) throw apiError(error);
    const assignLesson = lmsType.ASSIGN_LESSON_PAGE_POST;
    yield put(
      lessonsAction.assignLessonSuccess({
        assignLesson,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Assign Lesson Failed" };
    yield put(lessonsAction.assignLessonFailure(data));
  }
}

export function* getAssignLessonUserListSaga(params) {
  try {
    const { data, status, error } = yield call(
      getLessonAssignedUserList,
      params
    );

    const dataresult = data?.result;
    if (!data && status !== 200) throw apiError(error);
    const assignPage = lmsType.ASSIGN_LESSON_PAGE;

    yield put(
      lessonsAction.getAssignedLessonListSuccess({
        assignPage,
        dataresult,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Assign List Failed" };
    yield put(lessonsAction.getAssignedLessonListFailure(data));
  }
}
export function* completeLessonSaga(param) {
  const payload = param.data;

  try {
    const { data, status, error } = yield call(completeLesson, payload);
    if (!data && status !== 200) throw apiError(error);
    const casePage = lmsType.COMPLETE_PAGE;

    yield put(
      lessonsAction.completeLessonSuccess({
        casePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Complete Lesson Failed" };
    yield put(lessonsAction.completeLessonFailure(data));
  }
}

export function* getAssignLessonAlertMailSaga(params) {
  try {
    const { data, status, error } = yield call(
      sendcourseAndLessonAlertMail,
      params
    );

    const dataresult = data?.result;
    if (!data && status !== 200) throw apiError(error);
    const assignPage = lmsType.ASSIGN_LESSON_ALERT_PAGE;

    yield put(
      lessonsAction.getAlertmailAssigncourseLessonSuccess({
        assignPage,
        dataresult,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Assign List Failed" };
    yield put(lessonsAction.getAlertmailAssigncourseLessonFailure(data));
  }
}
export function* getCompletedLessonStatuscheckSaga(params) {
  try {
    const { data, status, error } = yield call(getCompletedlessons, params);

    const dataresult = data?.result;
    if (!data && status !== 200) throw apiError(error);
    const assignPage = lmsType.LESSONS_COMPLETED_STATUS_PAGE;

    yield put(
      lessonsAction.getCompletedLessonStatuscheckSuccess({
        assignPage,
        dataresult,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get completed lesson status List Failed" };
    yield put(lessonsAction.getCompletedLessonStatuscheckFailure(data));
  }
}
// unassign lesson
export function* unAssignLessonSaga(param) {
  const payload = param.data;

  try {
    const { data, status, error } = yield call(unAssignLesson, payload);
    if (!data && status !== 200) throw apiError(error);
    const unAssignLessonPage = lmsType.CREATE_PAGE_LESSONUNASSIGN;
    yield put(
      lessonsAction.unAssignLessonSuccess({
        unAssignLessonPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create UnAssignLesson Failed" };
    yield put(lessonsAction.unAssignLessonFailure(data));
  }
}
// unenroll
export function* unEnrollLessonSaga(param) {
  const payload = param.data;

  try {
    const { data, status, error } = yield call(unEnrollLesson, payload);
    if (!data && status !== 200) throw apiError(error);
    const unEnrollLessonPage = lmsType.CREATE_PAGE_LESSONUNENROLL;
    yield put(
      lessonsAction.unEnrollLessonSuccess({
        unEnrollLessonPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create UnEnrollLesson Failed" };
    yield put(lessonsAction.unEnrollLessonFailure(data));
  }
}

export function* searchLessonsSaga({ searchText }) {
  console.debug("searchUsersSaga");
  try {
    const {
      data: result,
      status,
      error,
    } = yield call(searchLessons, searchText);
    if (!result && status !== 200) throw apiError(error);
    const data = {
      responseMessages: {
        responseCode: "HBNG001",
      },
      users: result,
    };
    const searchLessonPage = lmsType.CREATE_PAGE_SEARCH_LESSONS;
    yield put(
      lessonsAction.searchLessonsSuccess({
        searchLessonPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Search Lessons Failed" };
    yield put(lessonsAction.searchLessonsFailure(data));
  }
}

export function* getmappingLessonWithClientSaga(params) {
  try {
    const { data, status, error } = yield call(
      getMappedGlobalLessonList,
      params
    );
    console.log("himanshu saga", data);
    //  const dataresult = data;
    if (!data && status !== 200) throw apiError(error);
    const assignPage = lmsType.CREATE_PAGE_MAPPED_GLOBAL_LESSON;

    yield put(
      lessonsAction.getMappedGlobalLessonListSuccess({
        assignPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Mapped lesson status List Failed" };
    yield put(lessonsAction.getMappedGlobalLessonListFailure(data));
  }
}
export function* postspecifyLessonToUserViewClientSaga(param) {
  const payload = param.data;
  const clientId = param.clientId;

  try {
    const { data, status, error } = yield call(
      specifyLessonToUserViewClient,
      payload,
      clientId
    );
    if (!data && status !== 200) throw apiError(error);
    const casePage = lmsType.CREATE_PAGE_POST_SPECIFY_LESSON_TOUSER_VIEW_CLIENT;
    yield put(
      lessonsAction.specifyLessonToUserViewClientSuccess({
        casePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create LessonToUserViewClient Failed" };
    yield put(lessonsAction.specifyLessonToUserViewClientFailure(data));
  }
}
export function* postspecifyLessonToUserViewLessonSaga(param) {
  const payload = param.data;
  const lessonId = param.lessonId;

  try {
    const { data, status, error } = yield call(
      specifyLessonToUserViewLesson,
      payload,
      lessonId
    );
    if (!data && status !== 200) throw apiError(error);
    const casePage = lmsType.CREATE_PAGE_POST_SPECIFY_LESSON_TOUSER_VIEW_LESSON;
    yield put(
      lessonsAction.specifyLessonToUserViewLessonSuccess({
        casePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create LessonToUserViewLesson Failed" };
    yield put(lessonsAction.specifyLessonToUserViewLessonFailure(data));
  }
}

const myLessonsSaga = [
  takeLatest(lmsType.GET_LESSONS_LIST_PENDING, getLessonsListSaga),
  takeLatest(lmsType.POST_LESSON_PENDING, createLessonSaga),
  takeLatest(lmsType.GET_LESSON_DETAILS_PENDING, getLessonDetailsSaga),
  takeLatest(lmsType.UPDATE_LESSON_PENDING, updatelessonsSaga),
  takeLatest(lmsType.DELETE_LESSON_PENDING, deleteLessonSaga),
  takeLatest(lmsType.POST_PUBLISH_LESSON_PENDING, publishLessonSaga),
  takeLatest(lmsType.POST_ASSIGN_LESSON_PENDING, assignLessonSaga),
  takeLatest(
    lmsType.GET_LESSON_ASSIGN_LIST_PENDING,
    getAssignLessonUserListSaga
  ),
  takeLatest(lmsType.POST_COMPLETE_LESSON_PENDING, completeLessonSaga),
  takeLatest(
    lmsType.GET_LESSON_ASSIGN_ALERT_LIST_PENDING,
    getAssignLessonAlertMailSaga
  ),
  takeLatest(
    lmsType.GET_LESSONS_COMPLETED_STATUS_PENDING,
    getCompletedLessonStatuscheckSaga
  ),
  takeLatest(lmsType.POST_LESSON_UNASSIGN_PENDING, unAssignLessonSaga),
  takeLatest(lmsType.POST_LESSON_UNENROLL_PENDING, unEnrollLessonSaga),
  takeLatest(lmsType.GET_SEARCH_LESSONS, searchLessonsSaga),
  takeLatest(
    lmsType.MAPPED_GLOBAL_LESSON_PENDING,
    getmappingLessonWithClientSaga
  ),
  takeLatest(
    lmsType.POST_SPECIFY_LESSON_TOUSER_VIEW_CLIENT_PENDING,
    postspecifyLessonToUserViewClientSaga
  ),
  takeLatest(
    lmsType.POST_SPECIFY_LESSON_TOUSER_VIEW_LESSON_PENDING,
    postspecifyLessonToUserViewLessonSaga
  ),
];

export default myLessonsSaga;
